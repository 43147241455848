import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';


@Component({
  selector: 'app-detail-vendor',
  templateUrl: './detail-vendor.component.html',
  styleUrls: ['./detail-vendor.component.scss']
})
export class DetailVendorComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  vendor;

  constructor(
    private locationA: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService,
    private reportService: ReportService
  ) { }

  ngOnInit() {

    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadVendorDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationA.back();
  }

  loadVendorDetail = async () => {
    this.vendor = await this.ioService.post('/location/getVendor', {
      locationId: this.documentId
    });
  }

  saveVendorDetail = async () => {
    this.vendor.saving = true;

    await this.ioService.post('/location/updateVendor', this.vendor);

    this.reportService.loadReport(null);
    this.close();
  }

  deleteVendor = async () => {
    this.vendor.saving = true;
    let deleteResponse;
    try {
      deleteResponse = await this.ioService.post('/location/deleteVendor', {
        locationId: this.vendor._id
      });
    } catch(e) {
      console.log('Delete Error: ', e.error);
      alert(e.error.msg);
      this.vendor.saving = false;
    }

    if (deleteResponse) {
      this.reportService.loadReport(null);
      this.close();
    }

  };

  viewProducts() {
    this.reportService.loadFilteredReport('vendor-products', [
      {
        name: 'Vendor',
        path: 'locations',
        type: 'remoteId',
        value: this.vendor.name,
      }
    ]);
  }

}
