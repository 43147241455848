<div class="detail" [class.visible]="group" [class.shoved]="group && conversationActive">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Permission Group</h2>
  </header>
  <section>
    <div *ngIf="!group" class="loading">Loading . . .</div>

    <div *ngIf="group" class="group">
      <div class="field">
        <label>Group Name</label>
        <input type="text" [(ngModel)]="group.name" />
      </div>

      <div class="field">
        <label>Description</label>
        <textarea class="note-input" [(ngModel)]="group.description"></textarea>
      </div>

      <ng-container *ngFor="let optionGroup of optionFields; let i = index">
        <div *ngIf="!optionGroup.opposingIdKey || !group[optionGroup.opposingIdKey] || group[optionGroup.opposingIdKey].length == 0" class="field">
          <app-dropdown 
              [(optionGroup)]="optionFields[i]"
              (searchChanged)="search(optionGroup, $event)">
          </app-dropdown>
        </div>        
      </ng-container>

      <div class="buttons">
        <button class="button red" (click)="deleteGroup()">Delete Group</button>
        <button class="button green" (click)="saveGroup()">Save Group</button>
      </div>
    </div>
  </section>
</div>
