import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogButtonStyle, DialogConfig, DialogOption } from './dialog-options';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  buttonStyle = DialogButtonStyle;

  @Output() optionSelected = new EventEmitter<DialogOption>();

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
               public dialogRef: MatDialogRef<DialogComponent> ) { }

  ngOnInit(): void {
  }

  selectOption(option: DialogOption) {
    if ( this.dialogRef ) {
      this.dialogRef.close(option);
    }
    this.optionSelected.emit(option);
  }

  dismiss() {
    const dismissOption = this.data.options.find((o) => o.style === DialogButtonStyle.Cancel);
    if ( dismissOption ) {
      this.selectOption(dismissOption);
    } else {
      this.closeDialogWithCancel();
    }
  }

  private closeDialogWithCancel() {
    if ( this.dialogRef ) {
      this.dialogRef.close( new DialogOption('Cancel (Default)', false, DialogButtonStyle.Cancel));
    }
  }
}
