import * as moment from "moment";

export class DatePickerConfig {
   public singleDate: boolean;
   public includeTime: boolean;
   public label: string;
   public ranges: any;
   public autoApply: boolean;
   public closeOnAutoApply: boolean;
   public opens: string;
   public showRanges: boolean;

   constructor() {
    this.singleDate = false;
    this.includeTime = true;
    this.label = null;
    this.ranges = null;
    this.autoApply = false;
    this.closeOnAutoApply = true;
    this.opens = 'right';
    this.showRanges = true;

    this.ranges = {
        'All Time': [
          moment().subtract(15, 'years'),
          moment().endOf('month')
        ],
        Today: [
          moment().startOf('day'),
          moment().endOf('day')
        ],
        Yesterday: [
          moment().subtract(1, 'days').startOf('day'),
          moment().subtract(1, 'days').endOf('day')
        ],
        // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'This Month': [
          moment().startOf('month'),
          moment().endOf('month')
        ]
      };
   }
}
export class DateRange {
    startDate: moment.Moment;
    endDate: moment.Moment;

    constructor( start: moment.Moment, end: moment.Moment ) {
        this.startDate = start;
        this.endDate = end;
    }
}
export class DateRangeSettings {
    dateConfig: DatePickerConfig;
    dateRange: DateRange;

    constructor() {
        this.dateRange = new DateRange( null, null );
        this.dateConfig = new DatePickerConfig();
    }
}
