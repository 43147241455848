<div class="detail" [class.visible]="conversations" [class.shoved]="conversations && conversationActive">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Conversations</h2>
  </header>
  <section>
    <div class="conversations">
      <div *ngFor="let conversation of conversations" class="conversation" [class.active]="conversationActive == conversation._id" [class.unread]="!conversation.lastRead && conversation.lastDirection == 'incoming'" (click)="loadConversation(conversation._id)">
        <div class="time">{{conversation.lastTime | rf: 'dateTime'}}</div>
        <div class="customer">{{conversation.customer.firstName}} {{conversation.customer.lastName}}</div>
        <div class="body">{{conversation.lastBody}}</div>
      </div>
    </div>
  </section>
</div>
