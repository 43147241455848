import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DiscountGroup } from 'src/app/modules/shared/interfaces/discounts/discount-group';
import { DiscountGroupItem } from 'src/app/modules/shared/interfaces/discounts/discount-group-item';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from 'src/app/modules/shared/interfaces/option-interfaces';
import { IoService } from 'src/app/services/io/io.service';
import { SaleItemComponent } from '../sale-item/sale-item.component';

@Component({
  selector: 'app-discount-item-list',
  templateUrl: './discount-item-list.component.html',
  styleUrls: ['./discount-item-list.component.scss']
})
export class DiscountItemListComponent implements OnInit {

  @ViewChildren(SaleItemComponent) childItems!: QueryList<SaleItemComponent>;

  @Input() discountGroup: DiscountGroup;
  @Output() discountGroupChange = new EventEmitter<DiscountGroup>();
  @Output() itemDeleted = new EventEmitter<DiscountGroupItem>();
  @Output() itemAdded = new EventEmitter<DiscountGroupItem>();

  activeItems: Array<DiscountGroupItem>;
  itemDropdown: DropdownOptionGroup = {
    key: 'item',
    name: ' ',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.None,
  };

  constructor( private ioService: IoService ) { }

  ngOnInit(): void {
    this.activeItems = this.discountGroup.items.filter((i) => i.filterKey === 'accountId' && i.active);
  }

  public refreshPrices() {
    for ( let i = 0; i < this.activeItems.length; i++ ) {
      this.childItems.find((o, idx) => i === idx)?.calculateSalePrice();
    }
  }

  deleteItem( item: DiscountGroupItem ) {
    item.active = false;
    this.activeItems.splice(this.activeItems.indexOf(item), 1);
    this.discountGroupChange.emit(this.discountGroup);
  }

  async newItemSelected() {
    if ( this.itemDropdown.selection?.length > 0 ) {
      const newItem = new DiscountGroupItem();
      newItem.active = true;
      newItem.accountId = this.itemDropdown.selection[0]._id;
      newItem.name = this.itemDropdown.selection[0].name;
      newItem.saleType = this.discountGroup.saleType;
      newItem.filterKey = 'accountId';
      const searchParameters = {
        detail: 'detail',
        accountId: newItem.accountId,
      };
      const item = (await this.ioService.post('/account/getAccount', searchParameters) as any);
      newItem.retailPrice = item.price;
      newItem.cost = item.cost ?? (item.calculatedCost ? item.calculatedCost.default : null);
      newItem.matchingDiscounts = (await this.ioService.post('/discount/getQualifiedDiscounts', {
        accountId: item._id,
        groupId: item.groupId,
        categoryId: item.categoryId,
        startDate: this.discountGroup.startDate,
        endDate: this.discountGroup.endDate,
      }) as any[]);
      this.itemDropdown.selection = new Array<DropdownOption>();
      this.discountGroup.items.push( newItem );
      this.activeItems.push( newItem );
      this.discountGroupChange.emit(this.discountGroup);
    }
  }

  async searchItems(searchText: string) {
    if ( !searchText || searchText.length <= 2 ) { return; }
    // Search values based on selected key
    const searchParameters: any = {
      search: searchText,
      idField: 'accountId',
      limit: 10,
      omit: this.activeItems.map( s => s.accountId )
    };
    searchParameters.type = 'inventory';
    searchParameters.subtype = 'retail';
    this.itemDropdown.options = (await this.ioService.post('/discount/searchOptions', searchParameters) as any[])
                                .map( o => Object.assign(new DropdownOption(o._id, o.name), o));
  }
}
