import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';
import { ReportService } from '../../../services/report/report.service'

@Component({
  selector: 'app-detail-customer',
  templateUrl: './detail-customer.component.html',
  styleUrls: ['./detail-customer.component.scss']
})
export class DetailCustomerComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  user;

  private permissionsLoaded: Subscription;
  private detailDocumentSet: Subscription;

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
    private ioService: IoService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.init();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }

  }
  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadDetail(type, id) {
    console.log('loadDetail');
    this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
  }

  init = async () => {
    console.log('detail-user init: ');

    this.user = null;

    // Get General User Data
    this.user = await this.ioService.post('/customer/getCustomer', {
      customerId: this.documentId
    });

    this.user.activeLocation = null;

    // See if we can manage this user
    let authenticatedUser = this.authorizationService.getUser();
    this.user.managableLocations = []

    if (this.user.locationId) {
      for (let locationId of this.user.locationId) {
        if (this.authorizationService.checkPermission('manageCustomers', locationId)) {
          this.user.canManage = true;
          break;
        }
      }
    }

    // Get User Authorizations
    if (this.user.canManage) {
      for (let location of this.locationService.getLocations()) {
        if (location._id) {
          if (this.user.locationId.indexOf(location._id) != -1) {
            location.active = true;
          }
          this.user.managableLocations.push(location);
        }
      }
    }

    // Sort managable locations
    this.user.managableLocations.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.active > b.active) ? 1 : -1) : -1 );


    console.log(this.user);
  }

  saveChanges() {
    if (!this.user.saving) {
      this.user.saving = true;
      this.ioService.post('/customer/updateCustomer', {
        user: this.user,
        // locationId: this.locationService.getActiveLocationId()
      }).then((saveResponse: any) => {
        // this.init();
        this.reportService.loadReport(0);
        this.close();
      }).catch((error: any) => {
        this.user.error = error.error.msg;
        this.user.saving = false;
      });
    }
  }

  toggleBoolean(toggleTarget) {
    this.user[toggleTarget] = !this.user[toggleTarget];
  }


}
