import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { LocationService } from '../../../services/location/location.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { DropdownOption, DropdownOptionGroup, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';

@Component({
  selector: 'app-detail-permission-group',
  templateUrl: './detail-permission-group.component.html',
  styleUrls: ['./detail-permission-group.component.scss']
})
export class DetailPermissionGroupComponent implements OnInit {

  // Start Legacy
  conversationActive;
  conversationActiveUpdated: Subscription;
  // End Legacy

  documentId$: Observable<any>;
  documentId;

  showBackButton;
  saving;

  group;

  optionFields: Array<DropdownOptionGroup> = [
    {
      key: 'permissionId',
      name: 'Permissions',
      selection: new Array<DropdownOption>(),
      options: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    },
    {
      key: 'reportId',
      name: 'Reports',
      selection: new Array<DropdownOption>(),
      options: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private location: Location,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadGroupDetail();
      }
    });
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadGroupDetail = async () => {
    this.group = null;
    const group: any = await this.ioService.post('/group/getPermissionGroup', {
      groupId: this.documentId
    });

    this.optionFields.forEach( g => {
      g.selection = new Array<DropdownOption>();
      g.options = null;
      group[g.key].forEach(p => {
        try {
          g.selection.push( new DropdownOption(group.options[p]._id, group.options[p].name));
        } catch(e) {
          console.log("Error with option: ", group.options[p]);
          console.log("p: ", p)
        }
      });
    });

    this.group = group;
  }

  saveGroup = async () => {
    if (!this.saving) {
      this.saving = true;
      this.updateDropdownKeys();
      const updateGroup = JSON.parse(JSON.stringify(this.group));
      await this.ioService.post('/group/updatePermissionGroup', {
        group: updateGroup
      });

      this.saving = false;
      this.reportService.loadReport(null);
      this.close();
    }
  }

  updateDropdownKeys() {
    for ( const field of this.optionFields ) {
      if ( field.selectMode === DropdownSelectionMode.One ) {
        this.group[field.key] = field.selection[0]?._id;
      } else {
        this.group[field.key] = field.selection.map((s) => s._id);
      }
    }
  }

  deleteGroup = async () => {
    this.group.active = false;
    this.saveGroup();
  }

  search = async (optionGroup: DropdownOptionGroup, searchText: string) => {
    this.group.searchResults = null;

    const searchParameters: any = {
      groupId: this.group._id,
      idField: optionGroup.key,
      search: searchText,
      limit: 10,
      omit: optionGroup.selection.map((s) => s._id)
    };

    optionGroup.options = (await this.ioService.post('/group/searchOptions', searchParameters) as any[])
                          .map( o => Object.assign( new DropdownOption( o._id, o.name ), o) );

    return;
  }
}
