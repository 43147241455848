<div class="detail" [class.visible]="location">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Location</h2>
  </header>
  <section>
    <div *ngIf="!location" class="loading">Loading . . .</div>
    <div *ngIf="location">
      <div>

        <div class="input-set input-set-name">
          <div>
            <label for="name">Name</label>
            <input type="text" id="name" [(ngModel)]="location.name" />
          </div>
          <div>
            <label for="name">Name Extended</label>
            <input type="text" id="nameExtended" [(ngModel)]="location.nameExtended" />
          </div>
        </div>

        <div class="input-set input-set-aliases">
          <div>
            <label for="key">Location Key</label>
            <input type="text" id="key" [(ngModel)]="location.key" />
          </div>
        </div>

        <div class="input-set input-set-address">
          <div>
            <label for="streetAddress">Address</label>
            <input type="text" id="streetAddress" [(ngModel)]="location.address.streetAddress" />
          </div>
          <div>
            <label for="streetAddressExt">Address 2</label>
            <input type="text" id="streetAddressExt" [(ngModel)]="location.address.streetAddressExt" />
          </div>
          <div>
            <label for="addressLocality">City</label>
            <input type="text" id="addressLocality" [(ngModel)]="location.address.addressLocality" />
          </div>
          <div>
            <label for="addressRegion">State</label>
            <select [(ngModel)]="location.address.addressRegion">
              <option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
            </select>
          </div>
          <div>
            <label for="postalCode">Zip</label>
            <input type="text" id="postalCode" [(ngModel)]="location.address.postalCode" mask="00000" />
          </div>
          <div>
            <label for="timeZone">Time Zone</label>
            <select [(ngModel)]="location.timeZone">
              <option *ngFor="let timeZone of timeZoneOptions" [value]="timeZone.value">{{timeZone.name}}</option>
            </select>
          </div>
        </div>

        <div class="input-set input-set-contact">
          <div>
            <label for="website">Website</label>
            <input type="text" id="website" [(ngModel)]="location.website" />
          </div>
          <div>
            <label for="email">Email</label>
            <input type="email" id="email" [(ngModel)]="location.email" />
          </div>
          <div>
            <label for="sequence">Sequence</label>
            <input type="number" id="sequence" [(ngModel)]="location.sequence" />
          </div>
          <div>
            <label for="telephone">Phone</label>
            <input type="tel" id="telephone" [(ngModel)]="location.telephone" mask="0 (000) 000-0000" />
          </div>
          <div>
            <label for="smsPhone">SMS #</label>
            <input type="tel" id="smsPhone" [(ngModel)]="location.smsPhone" mask="0 (000) 000-0000" />
          </div>
          <div>
            <label for="smsOnCall">SMS On Call #</label>
            <input type="tel" id="smsOnCall" [(ngModel)]="location.smsOnCall" mask="0 (000) 000-0000" />
          </div>
        </div>

        <ng-container>
          <div style="margin-top: 2vh">
            <app-dropdown [(optionGroup)]="groupDropdown"
              title="Default Item Group"
              [(selection)]="location.defaultGroup"
              (searchChanged)="searchGroups(true, $event)">
            </app-dropdown>
          </div>
        </ng-container>

        <div class="buttons">
          <div *ngIf="!saving" class="button-full green" (click)="saveChanges()">Save Location Details</div>
        </div>


        <div class="buttons">
          <div class="button" (click)="addAllItemsToLocation()">Add All Inventory to this Location</div>
          <!-- <label>Import Inventory</label>
          <input #inventory class="invisible" type="file" name="inventory" (change)="onInventoryFileChange($event)" />
          <div *ngIf="!inventory.importing" class="button" (click)="importInventory()">Import Inventory .CSV</div> -->
        </div>


      </div>

    </div>
  </section>

</div>
