<div *ngIf="communication && !communication.sendStart" class="communication-body">
  <div class="close" (click)="close()"></div>
  <h2>New Communication</h2>
  <div>
    <label>Subject</label>
    <input type="text" [(ngModel)]="communication.name" />
  </div>
  <div>
    <label>How do you want to communicate?</label>
    <div class="radio">
      <input type="radio" name="type" value="text" [(ngModel)]="communication.type" />
      <span>Text</span>
    </div>
    <div class="radio">
      <input type="radio" name="type" value="email" [(ngModel)]="communication.type" />
      <span>Email</span>
    </div>
  </div>

  <div *ngIf="communication.type == 'text'">
    <label>Text Content</label>
    <div class="hint"><span>Tip:</span> Be sure to let your customers know who you are!</div>
    <textarea [(ngModel)]="communication.content" (keyup)="contentChanged()"></textarea>
    <div class="sub">{{charRemaining}} Characters Remaining</div>
  </div>

  <div *ngIf="communication.type == 'email'">
    <div>
      <label>Which email template would you like to use?</label>
      <div *ngFor="let template of templates" class="radio stacked">
        <input type="radio" name="templateId" [value]="template._id" [(ngModel)]="communication.templateId" (click)="templateSet(template)" />
        <span>{{template.name}}</span>
      </div>
    </div>

    <div *ngIf="activeTemplate">

      <div *ngFor="let field of activeTemplate.fields">

        <!-- STRING -->
        <div *ngIf="field.type == 'string' && field.maxLength && field.maxLength < 60">
          <label>{{field.name}}</label>
          <input type="text" [(ngModel)]="communication.template[field.path]" (keyup)="fieldContentChanged(field)" [maxlength]="field.maxLength" />
          <div *ngIf="field.maxLength" class="sub">{{field.charRemaining | number}} Character<span *ngIf="field.charRemaining != 1">s</span> Remaining</div>
        </div>

        <div *ngIf="field.type == 'string' && (!field.maxLength || field.maxLength >= 60)">
          <label>{{field.name}}</label>
          <textarea [(ngModel)]="communication.template[field.path]" (keyup)="fieldContentChanged(field)"></textarea>
          <div *ngIf="field.maxLength" class="sub">{{field.charRemaining | number}} Character<span *ngIf="field.charRemaining != 1">s</span> Remaining</div>
        </div>

        <!-- ARRAY -->
        <div *ngIf="field.type == 'array'">
          <label>{{field.name}}</label>

          <div *ngIf="communication.template[field.path] || field.minLength || field.maxLength" class="min-max">
            (
            <span *ngIf="field.minLength" class="min">{{field.minLength}} / </span>

            <span *ngIf="communication.template[field.path]" class="now">{{communication.template[field.path].length}}</span>
            <span *ngIf="!communication.template[field.path]" class="now">0</span>

            <span *ngIf="field.maxLength" class="max"> / {{field.maxLength}}</span>
            )
          </div>

          <div *ngIf="field.childType == 'image' && field.options">
            <div *ngFor="let instance of communication.template[field.path]; let i = index" class="instance image" (click)="removeInstance(field.path, i)">
              <img src="{{instance.url}}" alt="{{instance.name}}" />
            </div>
          </div>

          <div *ngIf="communication.template[field.path] && (field.childType != 'image')" class="instances">
            <div *ngFor="let instance of communication.template[field.path]; let i = index" class="instance">
              <ng-container *ngFor="let childField of field.fields">

                <!-- STRING -->
                <div *ngIf="childField.type == 'string'" class="subfield">
                  <label>{{childField.name}}</label>
                  <input type="text" [(ngModel)]="communication.template[field.path][i][childField.path]" [maxlength]="childField.maxLength" />
                </div>

                <!-- URL -->
                <div *ngIf="childField.type == 'url'" class="subfield">
                  <label>{{childField.name}}</label>
                  <input type="text" [(ngModel)]="communication.template[field.path][i][childField.path]" [maxlength]="childField.maxLength" />
                </div>

                <!-- DATETIME -->
                <div *ngIf="childField.type == 'dateTime'" class="subfield">
                  <label>{{childField.name}}</label>
                  <input type="text"
                    ngxDaterangepickerMd
                    [timePicker]="true"
                    [singleDatePicker]="true"
                    [(ngModel)]="communication.template[field.path][i][childField.path]"
                    (datesUpdated)="fieldDatesUpdated($event, field.path, i, childField.path)"
                    class="form-control"  />
                  <!-- <input type="text" [(ngModel)]="communication.template[field.path][i][childField.path]" /> -->
                </div>


              </ng-container>
              <div class="instance-remove" (click)="removeInstance(field.path, i)">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 736v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm128 724v-948h-896v948q0 22 7 40.5t14.5 27 10.5 8.5h832q3 0 10.5-8.5t14.5-27 7-40.5zm-672-1076h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>
              </div>
            </div>
          </div>
          <div *ngIf="!communication.template[field.path] || communication.template[field.path].length < field.maxLength" class="btn-add" (click)="addChildInstance(field)"></div>
          <div *ngIf="field.options && field.options.length > 0 && field.optionsVisible" class="options">
            <ng-container *ngIf="field.childType == 'image'">
              <div *ngFor="let option of field.options" class="option img-option" (click)="addInstanceFromOption(field, option);">
                <img src="{{option.url}}" alt="{{option.name}}" />
              </div>
            </ng-container>
          </div>
        </div>

      </div>


    </div>

  </div>

  <div>
    <label>Define Audience</label>

    <div class="narrow">
      <input type="text" class="search" placeholder="Search" [(ngModel)]="search" (keyup)="searchUpdated()" />
      <div *ngFor="let activeFilter of activeFilters; let filterIndex = index" class="filter">

        <div class="remove" (click)="removeFilter(filterIndex)"></div>

        <select [(ngModel)]="activeFilter.key" (change)="setFilterType(filterIndex, activeFilter.key)">
          <option value="">-- Select Filter Type --</option>
          <option *ngFor="let filterKey of objectKeys(filters)" value="{{filterKey}}">{{filters[filterKey].name}}</option>
        </select>

        <!-- Inventory -->
        <span *ngIf="activeFilter.type == 'inventory'">
          <select [(ngModel)]="activeFilter.value" (change)="searchFilterCustomers()">
            <option *ngFor="let option of inventoryOptions" value="{{option._id}}">{{option.name | rf: 'decodeEntities'}}</option>
          </select>
        </span>

        <!-- Date -->
        <span *ngIf="activeFilter.type == 'date'">
          <input class="date-range-select" type="text"
            ngxDaterangepickerMd
            [(ngModel)]="activeFilter.value"
            (datesUpdated)="datesUpdated()"
            [autoApply]="true"
            />
        </span>

        <!-- Currency -->
        <span *ngIf="activeFilter.type == 'currency'">
          <select [(ngModel)]="activeFilter.modifier" (change)="searchFilterCustomers()">
            <option value="atLeast">at least</option>
            <option value="equals">equals</option>
            <option value="lessThan">lessThan</option>
          </select>
          $
          <input type="number" [(ngModel)]="activeFilter.value" (keyup)="searchUpdated()" />
        </span>

        <!-- Event -->
        <span *ngIf="activeFilter.type == 'event'">
          <select [(ngModel)]="activeFilter.value" (change)="searchFilterCustomers()">
            <option *ngFor="let option of eventOptions" value="{{option._id}}">{{option.start | rf: 'dateTimeShort'}} {{option.name | rf: 'decodeEntities'}}</option>
          </select>
        </span>


      </div>

      <div class="add-filter" (click)="addFilter()">Add Filter</div>
    </div>

    <div class="selections">
      <section class="results">
        <header>
          <div *ngIf="results && results.length > 0" class="add-all" (click)="addAll()">Add All</div>
          <div *ngIf="results && results.length > 0" class="remove-all" (click)="removeAll()">Remove All</div>
          Results
          <span *ngIf="results && !resultsMsg">({{results.length | rf: 'number'}})</span>
        </header>
        <div *ngIf="results" class="scrollable">
          <div *ngFor="let customer of results" class="customer" (click)="selectCustomer(customer)">
            <div class="info" (click)="showCustomerDetail($event, customer._id)"></div>
            <div *ngIf="customer.lastName" class="name">{{customer.lastName}}, {{customer.firstName}}</div>
            <div *ngIf="communication.type == 'email'" class="email">{{customer.email}}</div>
            <div *ngIf="communication.type == 'text'" class="mobilephone">{{customer.mobilephone | rf: 'phone'}}</div>
          </div>
        </div>
        <div *ngIf="!results && !resultsMsg" class="loading"></div>
        <div *ngIf="resultsMsg" class="results-msg">{{resultsMsg}}</div>
      </section>

      <section class="selected">
        <header>
          <div *ngIf="communication.customers && communication.customers.length > 0" class="clear" (click)="clearCustomers()">Clear</div>
          Selected Customers
          <span>({{communication.customers.length | rf: 'number'}})</span>
        </header>
        <div class="scrollable">
          <div *ngFor="let customer of communication.customers; let i = index" class="customer" (click)="deselectCustomer(i)">
            <div class="info" (click)="showCustomerDetail($event, customer._id)"></div>
            <div *ngIf="customer.lastName" class="name">{{customer.lastName}}, {{customer.firstName}}</div>
            <div *ngIf="communication.type == 'email'" class="email">{{customer.email}}</div>
            <div *ngIf="communication.type == 'text'" class="mobilephone">{{customer.mobilephone | rf: 'phone'}}</div>
          </div>
        </div>
      </section>
    </div>

  </div>





  <div>
    <label>Date & Time</label>
    <div class="radio">
      <input type="radio" name="sendTime" value="1" [(ngModel)]="sendNow" />
      <span>Send Immediately</span>
    </div>
    <div class="radio">
      <input type="radio" name="sendTime" value="0" [(ngModel)]="sendNow" />
      <span>Schedule Delivery</span>
    </div>
  </div>
  <div *ngIf="sendNow == 0" class="send-date-time">
    <span>Send on</span>
    <input type="text"
      ngxDaterangepickerMd
      [(ngModel)]="sendDate"
      [singleDatePicker]="true"
      [isInvalidDate]="isInvalidDate"
      [autoApply]="true"/>
    <span>at</span>
    <select [(ngModel)]="sendTime">
      <option *ngFor="let timeOption of sendTimes" value="{{timeOption}}">{{timeOption}}</option>
    </select>
  </div>
  <div *ngIf="sendNow == 0">
    <label>Active</label>
    <div class="radio">
      <input type="radio" name="active" value="1" [(ngModel)]="active" />
      <span>Active</span>
    </div>
    <div class="radio">
      <input type="radio" name="active" value="0" [(ngModel)]="active" />
      <span>Paused</span>
    </div>
  </div>


  <ng-container *ngIf="communication.type == 'email' && communication.html">
    <div class="preview">
      <div [innerHTML]="communication.html | rf: 'safeHtml'"></div>
    </div>
  </ng-container>


  <div class="buttons">

    <button *ngIf="sendNow == 1" class="button green send" [class.active]="saving" (click)="sendClick()">Send Now</button>
    <button class="button green save" [class.active]="saving" (click)="saveClick()">Save</button>
    <button *ngIf="!saving && communication.type == 'email' && communication.templateId" class="button preview-btn" (click)="previewEmail()">Preview</button>
    <!-- <button *ngIf="communication.timeAdded != communication.timeUpdated" class="button grey revert" (click)="loadCommunication()">Revert</button> -->

    <button class="button red delete" (click)="delete()">Delete</button>
  </div>

</div>




<div *ngIf="communication && communication.sendStart" class="communication-body">
  <div class="close" (click)="close()"></div>
  <div *ngIf="communication.type == 'text'" class="com-type">
    <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M896 384q-204 0-381.5 69.5t-282 187.5-104.5 255q0 112 71.5 213.5t201.5 175.5l87 50-27 96q-24 91-70 172 152-63 275-171l43-38 57 6q69 8 130 8 204 0 381.5-69.5t282-187.5 104.5-255-104.5-255-282-187.5-381.5-69.5zm896 512q0 174-120 321.5t-326 233-450 85.5q-70 0-145-8-198 175-460 242-49 14-114 22h-5q-15 0-27-10.5t-16-27.5v-1q-3-4-.5-12t2-10 4.5-9.5l6-9 7-8.5 8-9q7-8 31-34.5t34.5-38 31-39.5 32.5-51 27-59 26-76q-157-89-247.5-220t-90.5-281q0-174 120-321.5t326-233 450-85.5 450 85.5 326 233 120 321.5z"/></svg>
  </div>
  <div *ngIf="communication.type == 'email'" class="com-type">
    <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1664 1504v-768q-32 36-69 66-268 206-426 338-51 43-83 67t-86.5 48.5-102.5 24.5h-2q-48 0-102.5-24.5t-86.5-48.5-83-67q-158-132-426-338-37-30-69-66v768q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm0-1051v-24.5l-.5-13-3-12.5-5.5-9-9-7.5-14-2.5h-1472q-13 0-22.5 9.5t-9.5 22.5q0 168 147 284 193 152 401 317 6 5 35 29.5t46 37.5 44.5 31.5 50.5 27.5 43 9h2q20 0 43-9t50.5-27.5 44.5-31.5 46-37.5 35-29.5q208-165 401-317 54-43 100.5-115.5t46.5-131.5zm128-37v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"/></svg>
  </div>
  <h2>{{communication.name}}</h2>
  <div class="com-date">
    {{communication.sendStart | rf: 'dateTime'}}
  </div>





  <ng-container *ngIf="communication.type == 'text'">
    <div class="com-content">
      {{communication.content}}
    </div>
  </ng-container>



  <ng-container *ngIf="communication.type == 'email'">
    <div class="preview">
      <div [innerHTML]="communication.html | rf: 'safeHtml'"></div>
    </div>
  </ng-container>




  <div>
    <section>
      <header>
        Sent To
      </header>
      <div class="scrollable">
        <div *ngFor="let customer of communication.customers" class="customer">
          <div class="info" (click)="showCustomerDetail($event, customer._id)"></div>
          <div class="name">
            {{customer.lastName}},
            {{customer.firstName}}
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- <div>
    <button>New Message to Group</button>
  </div> -->
</div>
<app-conversation *ngIf="conversationActive"></app-conversation>
<router-outlet></router-outlet>
