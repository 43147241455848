<div class="detail" [class.visible]="tax">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Tax</h2>
  </header>
  <section>
    <div *ngIf="!tax" class="loading">Loading . . .</div>
    <div *ngIf="tax">

      <div class="input-set input-set-general">
        <div>
          <label>Tax Name</label>
          <input type="text" [(ngModel)]="tax.name" />
        </div>
        <div>
          <label>Description</label>
          <textarea class="note-input" [(ngModel)]="tax.description"></textarea>
        </div>
        <div>
          <label>Percentage</label>
          <input type="text" class="percentage" [(ngModel)]="tax.percentage" />
        </div>

        <div>
          <label>Rounding</label>
          <select [(ngModel)]="tax.rounding">
            <option *ngFor="let option of roundingOptions" [value]="option.value">{{option.name}}</option>
          </select>
        </div>

        <div class="line-field">
          <div class="label-large">Default</div>
          <app-toggle (valueChanged)="toggleBoolean('default')" [checked]="tax.default"></app-toggle>
        </div>

        <div>
          <label>Basis</label>
          <select [(ngModel)]="tax.basis">
            <option *ngFor="let option of basisOptions" [value]="option.value">{{option.name}}</option>
          </select>
        </div>

        <div *ngIf="locations.length > 1">
          <label>Locations</label>
          <div *ngFor="let location of locations" class="checkbox">
            <input type="checkbox" [(ngModel)]="location.taxActive" [ngModelOptions]="{standalone: true}" name="taxActive" />
            {{location.name}}
          </div>
        </div>

        <div *ngIf="false">
          <label>Included</label>
          <select [(ngModel)]="tax.type">
            <option value="included">Include in Item Price</option>
            <option value="added">Add to Item Price</option>
          </select>
        </div>
        <!-- <div>
          <label>Locations</label>
          <angular2-multiselect [data]="keywords.available" [(ngModel)]="keywords.selected"
            [settings]="keywords.settings"
            (onAddFilterNewItem)="keywords.add($event)"></angular2-multiselect>
        </div> -->
      </div>

      <div class="buttons">
        <button class="button green" (click)="saveTax()">Save Tax</button>
      </div>

    </div>
  </section>
</div>
