<div class="list-wrapper">
    <header>List</header>
    <div class="list-settings">
        List Settings
    </div>
    <div class="list-items">
        Items
    </div>
    <div class="buttons">
        <div class="button green">Save List</div>
    </div>
</div>
