<div class="detail" [class.visible]="true">
  <header>
    <div class="close" (click)="close()"></div>
    <h2 (click)="showCustomer()">{{conversation.customer.firstName}} {{conversation.customer.lastName}}</h2>
  </header>
  <section>
    <div class="messages" #messages>
      <div *ngFor="let message of conversation.messages" class="message {{message.direction}}">
        <div class="body">{{message.body}}</div>
        <div *ngIf="message.errorCode" class="error">{{message.errorMessage}}</div>
        <div class="time">{{message.fromName}} at {{message.time | rf: 'dateTime'}} {{message.locationName}}</div>
      </div>
    </div>
    <!-- <div *ngIf="location._id" class="new-message"> -->
    <div class="new-message">
      <textarea #message name="message" [(ngModel)]="messageBody" (keydown)="messageKeydown($event)"></textarea>
      <div class="send-container" (click)="send()">
        <div class="send">Send</div>
      </div>
    </div>
  </section>
</div>
