import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { ClientService } from '../client/client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authenticatedId;
  private authenticationStateChanged = new Subject<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private clientService: ClientService
  ) {
    console.log('Authentication Service constructor()');
    if (Math.floor(Date.now() / 1000) < parseInt(localStorage.getItem('tokenEndOfLife'))) {
      this.authenticatedId = localStorage.getItem('authenticatedId');
      this.authenticationStateChange(this.authenticatedId);
    }
  }

  isSignedIn() {
    if (Math.floor(Date.now() / 1000) < parseInt(localStorage.getItem('tokenEndOfLife'))) {
      return true;
    } else {
      this.signout();
      return false;
    }
  }

  // Send login credentials to the server to get a token
  signin(email: string, password: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.post<any>(this.clientService.getServerPath() + 'authentication/signIn', {
        rfId: this.clientService.getRfId(),
        email,
        password
      }, {}).toPromise()
        .then(signinResponse => {
          if (signinResponse.token) {
            this.authenticatedId = signinResponse.userId;
            localStorage.setItem('token', signinResponse.token);
            localStorage.setItem('tokenEndOfLife', Math.floor(Date.now() / 1000) + signinResponse.tokenLifespan);
            localStorage.setItem('authenticatedId', this.authenticatedId);
            this.authenticationStateChange(this.authenticatedId);
            resolve();
          } else {
            console.error('login succeeded but did not return a token');
            reject();
          }
        })
        .catch(err => {
          // console.error('login failed');
          reject(err);
        });
    });
  }

  signout() {
    localStorage.removeItem('tokenEndOfLife');
    localStorage.removeItem('token');
    localStorage.removeItem('authenticatedId');
    this.authenticatedId = null;
    this.authenticationStateChange(this.authenticatedId);
    this.router.navigate(['/sign-in']);
  }

  setPassword(password: string, key: string) {
    console.log('setPassword: ', password);
    return new Promise<void>((resolve, reject) => {
      this.http.post<any>(this.clientService.getServerPath() + 'authentication/setPassword', {
        password,
        key,
        rfId: this.clientService.getRfId(),
      }, {}).toPromise()
        .then(resetResponse => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  requestPasswordReset(userKey: string) {
    console.log('requestPasswordReset: ', userKey);
    return new Promise<void>((resolve, reject) => {
      this.http.post<any>(this.clientService.getServerPath() + 'authentication/requestPasswordReset', {
        userKey,
        rfId: this.clientService.getRfId(),
      }, {}).toPromise()
        .then(resetResponse => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  authenticationStateChange(authenticatedId) {
    this.authenticationStateChanged.next(authenticatedId);
  }

  authenticationStateChangedObservable(): Observable<any> {
    return this.authenticationStateChanged.asObservable();
  }

}
