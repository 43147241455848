<div *ngIf="!canEdit" class="detail" [class.visible]="discountGroup">
    <header>
        <div class="close" (click)="close()"></div>
        <h2>Sale Campaign</h2>
    </header>
    <div class="field">
        <label>{{discountGroup.name}}</label>
    </div>
    <div class="sale-items">
        <div *ngFor="let item of discountGroup.items">
            <div class="name">{{item.name}}</div>
            <div class="spacer"></div>
            <div class="sale-price">${{item.saleValue}}</div>
        </div>
    </div>
    <div class="buttons">
        <div class="spacer"></div>
        <button class="button" (click)="printTags()">Print Tags</button>
    </div>
    <div class="status-text">{{statusText}}</div>
</div>

<div *ngIf="canEdit" class="detail" [class.visible]="discountGroup">
    <header>
        <div class="close" (click)="close()"></div>
        <h2>Sale Campaign</h2>
    </header>
    <div class="field">
        <label>Sale Name</label>
        <input [(ngModel)]="discountGroup.name" />
    </div>
    <div class="flex-row">
        <div class="field flex-row">
            <label>Start Date</label>
            <app-toggle (valueChanged)="toggleStartDate()" [checked]="discountGroup.startDate"></app-toggle>
            <app-filter-date-range *ngIf="discountGroup?.startDate" class="w-100" [skewed]="true" [rangeConfig]="startDateSettings" (rangeChanged)="startDateChanged($event)"></app-filter-date-range>
        </div>
        <div class="field flex-row">
            <label>End Date</label>
            <app-toggle (valueChanged)="toggleEndDate()" [checked]="discountGroup.endDate"></app-toggle>
            <app-filter-date-range *ngIf="discountGroup?.endDate" class="w-100" [skewed]="true" [rangeConfig]="endDateSettings" (rangeChanged)="endDateChanged($event)"></app-filter-date-range>
        </div>
        <div class="field flex-row">
            <label>Discount Type</label>
            <select [(ngModel)]="discountGroup.saleType" (ngModelChange)="saleTypeChanged()">
                <option *ngFor="let stype of saleTypes" [value]="typeEnum[stype]">{{stype}}</option>
            </select>
        </div>
    </div>
    <app-tabs>
        <app-tabpage title="Items">
            <app-discount-item-list [(discountGroup)]="discountGroup"></app-discount-item-list>
        </app-tabpage>
        <app-tabpage title="Locations">
            <app-dropdown [(optionGroup)]="locationDropdown"
                title="Available at Locations"
                placeholder="(Choose Location)"
                [disableSearch]="true"
                searchLocation="above"
                (searchChanged)="searchLocations($event)">
            </app-dropdown>
        </app-tabpage>
        <app-tabpage title="Categories">
            <app-discount-group-category [(discountGroup)]="discountGroup" groupSubtype="category"></app-discount-group-category>
        </app-tabpage>
        <app-tabpage title="Groups">
            <app-discount-group-category [(discountGroup)]="discountGroup" groupSubtype="group"></app-discount-group-category>
        </app-tabpage>
    </app-tabs>
    <div class="buttons upper-shadow">
        <button class="button red" (click)="delete()">Delete</button>
        <button class="button" (click)="printTags()">Print Tags</button>
        <button class="button green" (click)="save()" [disabled]="!canSave()">Save</button>
    </div>
    <div class="status-text">{{statusText}}</div>
</div>