import { DatePickerConfig } from '../../../report/filter-date-range/date-config-interface';
import { DiscountFilter } from './discount-filter';

export class DiscountQualification {
    unit: string;
    filters: Array<DiscountFilter>;

    constructor() {
        this.filters = new Array<DiscountFilter>();
    }
}

export class DiscountItemQualifier extends DiscountQualification {
    threshold: number;
    allowPartial: boolean;
}

export class DiscountItemQualified extends DiscountQualification {
    value: number;
}
