import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-detail-account',
  templateUrl: './detail-account.component.html',
  styleUrls: ['./detail-account.component.scss']
})
export class DetailAccountComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    showBackButton;

    account;

    constructor(
      private location: Location,
      private route: ActivatedRoute,
      private router: Router,
      private historyService: HistoryService,
      private ioService: IoService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadAccountDetail();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    back() {
      this.location.back();
    }

    loadDetail(type, id) {
      this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
    }

    loadAccountDetail() {
      console.log('loadAccountDetail: ');
      this.account = null;
      this.ioService.post('/account/getDetails', {
        accountId: this.documentId
      }).then((accountResponse: any) => {
        console.log('accountResponse: ', accountResponse);
        this.account = accountResponse.account;
      });
    }



}
