<div class="settings-body" *ngIf="marketing">
  <div class="close" (click)="close()"></div>
  <h2>Communications Settings</h2>


  <div>
    <label>Birthday Message Active</label>
    <div class="checkbox">
      <input type="checkbox" [(ngModel)]="marketing.birthday.active" [ngModelOptions]="{standalone: true}" name="birthdayActive" />
      Birthday Message Active
    </div>
  </div>
  <div *ngIf="marketing.birthday.active">
    <label>Birthday Message Content</label>
    <textarea [(ngModel)]="marketing.birthday.content" (keyup)="contentChanged(marketing.birthday)"></textarea>
    <div class="sub">{{marketing.birthday.charRemaining}} Characters Remaining</div>
  </div>
  <div>
    <label>Birthday Message Send Time</label>
    <select [(ngModel)]="marketing.birthday.sendTime">
      <option *ngFor="let timeOption of sendTimes" value="{{timeOption}}">{{timeOption}}</option>
    </select>
  </div>




<!--
  Anniversary Message Active
  Anniversary Message Count
  Anniversary Send Time -->

</div>
