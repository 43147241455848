import { AfterContentInit, Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { TabpageComponent } from './tabpage/tabpage.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {

  @ContentChildren(TabpageComponent) tabs: QueryList<TabpageComponent>;

  constructor() { }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((t) => t.active);
    if (activeTabs.length === 0) {
      this.selectTabComponent(this.tabs.first);
    }
  }

  selectTab( tabIndex: number ) {
    this.selectTabComponent( this.tabs.toArray()[0] );
  }

  selectTabComponent( tab: TabpageComponent ) {
    this.tabs.toArray().forEach((t) => t.active = false);
    tab.active = true;
  }
}
