<div class="detail" [class.visible]="group">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Group</h2>
  </header>
  <section>
    <div *ngIf="!group" class="loading">Loading . . .</div>
    <div *ngIf="group">

      <div class="input-set input-set-general">

        <div>
          <label>{{group.label}} Name</label>
          <input type="text" [(ngModel)]="group.name" />
        </div>

        <!-- <div>
          <label>Key</label>
          <input type="text" [(ngModel)]="group.key" />
        </div> -->

        <div>
          <label>Color</label>
          <div class="color-block" [cpOutputFormat]="'rgba'" [(colorPicker)]="group.background" [style.background]="group.background" (colorPickerChange)="colorChanged($event, group)"></div>
        </div>

        <ng-container *ngIf="group.type == 'tax'">
          <div>
            <label>Percentage (%)</label>
            <input type="text" [(ngModel)]="group.percentage" />
          </div>
          <div>
            <label>Basis</label>
            <select [(ngModel)]="group.basis">
              <option value="item">Item</option>
              <option value="invoice">Invoice</option>
            </select>
          </div>
          <div>
            <label>Rounding</label>
            <select [(ngModel)]="group.rounding">
              <option value="roundHalfUp">Round Half Up</option>
              <option value="roundUp">Round Up</option>
            </select>
          </div>
        </ng-container>

        <div>
          <label>Display Order</label>
          <input type="number" [(ngModel)]="group.displayOrder" />
        </div>

        <div class="checkbox">
          <input type="checkbox" [(ngModel)]="group.ise" [ngModelOptions]="{standalone: true}" name="ise" />
          Visible in ISE
        </div>

        <div>
          <label>Description</label>
          <textarea class="note-input" [(ngModel)]="group.description"></textarea>
        </div>

        <app-dropdown [(optionGroup)]="locationDropdown"
            title="Retail Locations"
            placeholder="(Choose Location)"
            [disableSearch]="true"
            (searchChanged)="searchLocations($event)">
        </app-dropdown>

        <div class="errors">
          <div *ngFor="let error of errors" class="error">{{error}}</div>
        </div>

      </div>
      <div class="buttons">
        <button class="button green" (click)="saveGroup()">Save Group</button>
      </div>

    </div>
  </section>
</div>
