<div class="detail" [class.visible]="action">
    <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="showBackButton" class="back" (click)="back()"></div>
      <h2>Menu Action</h2>
    </header>
    <section>
      <div *ngIf="!action" class="loading">Loading . . .</div>
      <div *ngIf="action">
  
        <div class="input-set input-set-general">  
          <div>
            <label>Action</label>
            <input type="text" [(ngModel)]="action.name" />
          </div>    
          <div class="checkbox">
            <input type="checkbox" [(ngModel)]="action.active" [ngModelOptions]="{standalone: true}" name="active" />
            Active
          </div>
          <div>
            <label>Key</label>
            <input type="text" [(ngModel)]="action.key" />
          </div>    
          <div>
            <label>Min</label>
            <input type="number" [(ngModel)]="action.min" />
          </div>  
          <div>
            <label>Max</label>
            <input type="number" [(ngModel)]="action.max" />
          </div>
          <div>
            <label>Display Order</label>
            <input type="number" [(ngModel)]="action.displayOrder" />
          </div>
          <div>
            <label>Transaction Type</label>
            <input type="text" [(ngModel)]="action.transactionType" />
          </div>
          <div class="checkbox">
            <input type="checkbox" [(ngModel)]="action.noteRequired" [ngModelOptions]="{standalone: true}" name="noteRequired" />
            Note Required
          </div>
          <div>
            <label>Required Permission</label>
            <input type="text" [(ngModel)]="action.requiredPermission" />
          </div>
          <app-dropdown [(optionGroup)]="groupDropdown"
            placeholder="(Select Group)"
            [disableSearch]="false"
            (searchChanged)="searchGroups($event)">
          </app-dropdown>
          <div class="errors">
            <div *ngFor="let error of errors" class="error">{{error}}</div>
          </div>  
        </div>
        <div class="buttons">
          <button class="button green" (click)="saveAction()">Save Action</button>
        </div>  
      </div>
    </section>
  </div>
  