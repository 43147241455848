import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-set-client',
  templateUrl: './set-client.component.html',
  styleUrls: ['./set-client.component.scss']
})
export class SetClientComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    let key = this.route.snapshot.queryParamMap.get('key');

    if (key) {
      this.getConfig(key);
    }

  }

  getConfig = async (key) => {
    console.log('found Key: ', key);

    // Get Server URL from Stoker
    let stokerConfig = await this.http.post<any>('https://stoker.rftapi.com/ise/getClientConfig', {
      key,
    }, {}).toPromise();

    console.log('stokerConfig: ', stokerConfig);

    // Get Client Public Config from cloudAPI Server Designated from Stoker
  }

}
