<div class="detail" [class.visible]="terminal" [class.shoved]="terminal && conversationActive">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Terminal</h2>
  </header>
  <section>
    <div *ngIf="!terminal" class="loading">Loading . . .</div>
    <div *ngIf="terminal">

      <div class="context">
        <div class="row">
          <div class="label">Terminal ID:</div>
          <div class="value">{{terminal._id}}</div>
        </div>

        <ng-container *ngIf="terminal.type == 'ipad'">
          <div class="row">
            <div class="label">Type:</div>
            <div class="value">{{terminal.deviceInfo?.model ?? 'Unknown'}}</div>
          </div>

          <div class="row">
            <label>Name</label>
            <input type="text" [(ngModel)]="terminal.name" />
          </div>

          <ng-container *ngIf="creditTerminals">
            <div class="field">
              <label>Credit Card Terminal</label>
              <select [(ngModel)]="terminal.creditTerminalId">
                <option [value]="null">-None-</option>
                <option *ngFor="let creditTerminal of creditTerminals" [value]="creditTerminal._id">{{creditTerminal.sn}}</option>
              </select>
            </div>
          </ng-container>

          <ng-container>
            <div class="field">
              <app-dropdown 
                [(optionGroup)]="groupDropdown"
                (searchChanged)="searchGroups(true, $event)">
              </app-dropdown>
            </div>
          </ng-container>

          <ng-container *ngIf="drawerCount">
            <div class="row">
              <div class="label">Drawer Count:</div>
              <div class="value">${{drawerCount.balance}}</div>
            </div>
          </ng-container>

          <div class="controls">
            <button class="button" (click)="saveTerminal()">Save</button>
            <button class="button" (click)="deleteTerminal()">Delete</button>
          </div>
        </ng-container>

        <ng-container *ngIf="terminal.type == 'credit'">
          <div class="row">
            <div class="label">Type:</div>
            <div class="value">Credit Card Terminal</div>
          </div>

          <div class="input-set input-set-general">
            <div>
              <label>Name</label>
              <input type="text" [(ngModel)]="terminal.name" />
            </div>
            <div>
              <label>Serial Number</label>
              <input type="text" [(ngModel)]="terminal.sn" />
            </div>
            <div>
              <label>API Key</label>
              <input type="text" [(ngModel)]="terminal.apiKey" />
            </div>
            <div class="line-field">
              <div class="label-large">Auto Batch</div>
              <app-toggle (valueChanged)="toggleBoolean('autoBatch')" [checked]="terminal.autoBatch"></app-toggle>
            </div>
            <div *ngIf="terminal.autoBatch" class="line-field">
              <div class="label-large">Batch Time</div>
              <select [(ngModel)]="terminal.batchTime">
                <option *ngFor="let timeOption of batchTimeOptions" [value]="timeOption">{{timeOption | rf: 'shortTime'}}</option>
              </select>
            </div>
          </div>

          <div class="controls">
            <button class="button" (click)="saveTerminal()">Save</button>
          </div>

          <div class="controls">
            <button *ngIf="!terminal.batchClosing" class="button" (click)="closeBatch()">Close Batch</button>
          </div>
          <div *ngIf="terminal.batchResult">
            {{terminal.batchResult | json}}
          </div>

          <div *ngIf="terminal.validating">Validating Declined Transactions in Active Batch . . .</div>
          <div *ngIf="!terminal.validating && !terminal.batchClosing" class="controls">
            <button class="button" (click)="validateBatch()">Validate Batch</button>
          </div>
          <ng-container *ngIf="terminal.problemTransactions">
            <div *ngIf="terminal.problemTransactions.length > 0">
              <div *ngFor="let problemTrans of terminal.problemTransactions" class="problem-transaction" (click)="loadDetail('transaction', problemTrans._id)">
                {{problemTrans._id}}:
                {{problemTrans.attemptedAmount | rf: 'currency'}}
              </div>
            </div>
            <div *ngIf="terminal.problemTransactions.length == 0">No Unmatched Transactions Found!</div>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </section>
</div>
