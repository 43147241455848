<section class="gate">
  <div class="tinter">
    <div class="gate-container">
        <div class="gate-content">
            <div class="gate-label">Welcome,</div>
            <div *ngIf="clientConfig && clientConfig.logoUrl" class="gate-logo">
                <img src="{{clientConfig.logoUrl}}" alt="{{clientConfig.name}}" draggable="false" />
            </div>
            <h1 class="gate-title">Set Password</h1>
            <div>
                <div class="input">
                    <label for="password" class="hidden">Password</label>
                    <input type="password" placeholder="Password" (keyup)="updatePassword($event)" required>
                </div>
                <div class="input">
                    <label for="passwordi" class="hidden">Repeat Password</label>
                    <input type="password" placeholder="Repeat Password" (keyup)="updatePasswordi($event)" required>
                </div>
                <div class="error">{{error}}</div>
                <div class="form-actions right">
                    <a [routerLink]="['/sign-in']">Sign In</a>
                    <button class="button" [class.disabled]="!validInput || processing" (click)="resetPassword()">Set Password</button>
                </div>
            </div>
        </div>
    </div>

    <div class="powered-by">
        powered by
        <img src="assets/global/logo-red-fire.svg" alt="Red Fire Technology" draggable="false">
    </div>
  </div>
</section>
