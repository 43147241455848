import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountGroupItem, DiscountItemSaleType } from 'src/app/modules/shared/interfaces/discounts/discount-group-item';

@Component({
  selector: 'app-sale-item',
  templateUrl: './sale-item.component.html',
  styleUrls: ['./sale-item.component.scss']
})
export class SaleItemComponent implements OnInit {
  SaleType = DiscountItemSaleType;
  itemSalePrice: number;

  @Input() item: DiscountGroupItem;
  @Output() itemChange = new EventEmitter<DiscountGroupItem>();
  @Input() basicView = false;

  constructor() {}

  ngOnInit(): void {
    if ( this.item && !this.item.saleType ) {
      this.item.saleType = DiscountItemSaleType.SalePrice;
    }
    this.calculateSalePrice();
  }

  calculateSalePrice() {
    if ( !this.item?.saleType || !this.item.saleValue ) {
      this.itemSalePrice = null;
      return;
    }
    switch ( this.item.saleType ) {
      case DiscountItemSaleType.SalePrice:
        this.itemSalePrice = this.item.saleValue * 100;
        break;
      case DiscountItemSaleType.DollarsOff:
        this.itemSalePrice = this.item.retailPrice - (this.item.saleValue * 100);
        break;
      case DiscountItemSaleType.PercentOff:
        this.itemSalePrice = this.item.retailPrice - (this.item.retailPrice * (this.item.saleValue / 100));
        break;
      default:
        console.log('Unknown sale type', this.item.saleType);
    }
    this.itemChange.emit(this.item);
  }

  public setSaleType(type: string) {
    if ( this.item.saleType !== type ) {
      this.item.saleValue = null;
      this.item.saleType = type;
      this.calculateSalePrice();
    }
  }
}
