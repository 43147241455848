import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { TemplateComponent } from './template.component';

@NgModule({
  declarations: [TemplateComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ]
})
export class TemplateModule { }
