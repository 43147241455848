import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { IoService } from '../../../services/io/io.service';
import { MessageService } from '../../../services/message/message.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

  communicationId;
  communication;
  errors = {};

  sendNow = "1";

  saving;
  deleting;

  active;

  sendDate;
  sendTime;
  sendTimes = [
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM'
  ];

  charLimit = 160;
  charRemaining = 0;

  filters = {
    // recentPurchase: {
    //   key: 'recentPurchase',
    //   name: 'Latest purchase was on/between',
    //   type: 'date', // before, after, between
    //   value: {
    //     startDate: moment().subtract(30, 'days'),
    //     endDate: moment()
    //   }
    // },
    // firstVisit: {
    //   key: 'firstPurchase',
    //   name: 'First purchase was on/between',
    //   type: 'date'
    // },
    completedPurchase: {
      key: 'completedPurchase',
      name: 'Completed a purchase on/between',
      type: 'date',
      value: {
        // startDate: moment().subtract(30, 'days').startOf('day'),
        startDate: moment().startOf('year'),
        endDate: moment().endOf('day')
      }
    },
    notCompletedPurchase: {
      key: 'notCompletedPurchase',
      name: 'Did not complete a purchase on/between',
      type: 'date',
      value: {
        // startDate: moment().subtract(30, 'days').startOf('day'),
        startDate: moment().startOf('year'),
        endDate: moment().endOf('day')
      }
    },
    purchasedItem: {
      key: 'purchasedItem',
      name: 'Has purchased item',
      type: 'inventory' // itemId
    },
    notPurchasedItem: {
      key: 'notPurchasedItem',
      name: 'Has NOT purchased item',
      type: 'inventory'
    },
    attendedEvent: {
      key: 'attendedEvent',
      name: 'Attended the event',
      type: 'event' // eventId
    },
    lastMessaged: {
      key: 'lastMessaged',
      name: 'Last messaged',
      type: 'date'
    },
    purchaseTotal: {
      key: 'purchaseTotal',
      name: 'Total purchases',
      type: 'currency' // greater than, less than, between
    },
    // loyaltyBalance: {
    //   key: 'loyaltyBalance',
    //   name: 'Loyalty point balance',
    //   type: 'number' // greater than, less than, between
    // },
    // loyaltyTotal: {
    //   key: 'loyaltyTotal',
    //   name: 'Loyalty points all time',
    //   type: 'number'
    // }
  };

  selectFilterType = '';

  results;
  resultsMsg;

  selectedCustomerIds = [];

  search = '';
  activeFilters = [];

  inventoryOptions = [];
  eventOptions = [];




  searchTimeout;
  objectKeys = Object.keys;

  conversationActive;
  conversationActiveUpdated: Subscription;

  templates = [];
  activeTemplate;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private messageService: MessageService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.communicationId = this.route.snapshot.paramMap.get('documentId');
    this.loadCommunication();

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });
  }

  ngOnDestroy() {
    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
  }

  loadTemplates() {
    this.ioService.post('/communication/getTemplates', {
      locationId: this.communication.locationId,
      type: 'email'
    }).then((templatesResponse: any) => {
      console.log('templatesResponse: ', templatesResponse);
      this.templates = templatesResponse.templates;

      if (this.communication.templateId) {
        for (let template of this.templates) {
          if (template._id == this.communication.templateId) {
            this.templateSet(template);
          }
        }
      }


    });
  }

  templateSet(template) {
    this.activeTemplate = template;
    console.log('ACTIVE TEMPLATE: ', this.activeTemplate);
    this.initializeFields();
  }

  initializeFields() {
    console.log('initializeFields');
    if (this.communication.template) {
      // let fields = Object.keys(this.activeTemplate.fields);
      for (let field of this.activeTemplate.fields) {
        console.log('INITIALIZEFIELD: ', field);
        this.fieldContentChanged(field);
      }
    }

  }

  loadTemplate() {

  }

  loadCommunication() {
    this.ioService.post('/communication/get', {
      communicationId: this.communicationId
    }).then((communicationResponse: any) => {
      console.log('communicationResponse: ', communicationResponse);


      console.log('Initialize dateTime inputs');
      if (communicationResponse.communication.template) {
        let fields = Object.keys(communicationResponse.communication.template);
        for (let field of fields) {
          if (Array.isArray(communicationResponse.communication.template[field])) {
            for (let instance of communicationResponse.communication.template[field]) {
              let keys = Object.keys(instance);
              for (let key of keys) {
                console.log('Key: ');
                console.log(instance[key]);
                if (instance[key] && typeof instance[key] == 'string' && instance[key].substr(0, 2) == '20' && instance[key].substr(-5) == '.000Z') {
                  console.log('It\'s a date!');
                  // instance[key] = moment(instance[key]);
                  instance[key] = {
                    startDate: moment(instance[key]),
                    endDate: moment(instance[key])
                  }
                }
              }
            }
          }
        }
      }


      if (communicationResponse.communication.content == '') {
        let comLocation = this.locationService.getLocation(communicationResponse.communication.locationId);
        if (comLocation && comLocation.name) {
          communicationResponse.communication.content = 'Board & Brush - '+comLocation.name+': ';
        }
      }


      this.communication = communicationResponse.communication;



      if (this.communication.sendStart) {
        // This is a sent communication


      } else {
        // This is an unsent communication
        if (this.communication.active) {
          this.active = '1';
        } else {
          this.active = '0';
        }

        if (!this.communication.template) {
          this.communication.template = {};
        } else {
          console.log('template values: ', this.communication.template);
        }
        this.loadTemplates();


        if (this.communication.scheduledTime) {
          this.sendNow = '0';

          this.sendDate = {
            startDate: moment(this.communication.scheduledTime),
            endDate: moment(this.communication.scheduledTime)
          };
          this.sendTime = moment(this.communication.scheduledTime).format('h:mm A');
          console.log('sendTime: ', this.sendTime);

        } else {
          this.sendNow = '1';
        }
        this.selectedCustomerIds = this.communication.customers.map(customer => customer._id);
        // console.log('selectedCustomerIds: ', this.selectedCustomerIds);

        this.charRemaining = this.charLimit - this.communication.content.length;

        this.searchFilterCustomers();
      }
    });
  }

  setFilterType(filterIndex, filterKey) {
    let filterConfig = Object.assign({}, this.filters[filterKey])
    this.activeFilters[filterIndex] = filterConfig;

    if (filterConfig.type == 'inventory' && this.inventoryOptions.length == 0) {
      this.loadInventoryOptions();
    }

    if (filterConfig.type == 'event' && this.eventOptions.length == 0) {
      this.loadEventOptions();
    }

    this.searchFilterCustomers();
  }

  datesUpdated() {
    setTimeout(() => {
      this.searchFilterCustomers();
    }, 0);
  }

  searchFilterCustomers() {
    console.log('filters: ', this.activeFilters);
    this.resultsMsg = null;
    this.results = null;

    let completedFilters = [];
    for (let activeFilter of this.activeFilters) {
      if (activeFilter.value) {
        completedFilters.push(activeFilter);
      }
    }

    if (completedFilters.length > 0 || this.search != '') {
      this.ioService.post('/communication/searchFilterCustomers', {
        locationId: this.communication.locationId,
        communicationType: this.communication.type,
        search: this.search,
        filters: completedFilters
      }).then((customersResponse: any) => {
        console.log('customersResponse: ', customersResponse);
        this.results = customersResponse.results;
        this.resultsMsg = customersResponse.msg;
      });
    } else {
      this.resultsMsg = 'Please filter or search to see results.';
    }
  }

  showCustomerDetail(event: Event, customerId) {
    event.stopPropagation();
    console.log(customerId);

    this.router.navigate(['marketing', 'communication', this.communicationId, 'customer', customerId]);
  }

  previewEmail() {
    this.save().then(() => {
      this.ioService.post('/communication/getEmailPreview', {
        communicationId: this.communication._id
      }).then((previewResponse: any) => {
        console.log('previewResponse: ', previewResponse);
        this.saving = false;

        this.communication.html = previewResponse.emailBody;

      }).catch((previewError: any) => {
        console.log(previewError);
        alert(previewError.error.msg);
      });
    });
  }

  saveClick() {
    this.save().then(communicationId => {
      this.router.navigate(['marketing', 'dashboard']);
    });
  }

  sendClick() {
    this.save().then(communicationId => {

      this.send();
      setTimeout(() => {
        this.router.navigate(['marketing', 'dashboard']);
      }, 1000);

    });
  }

  save() {
    return new Promise((resolve, reject) => {
      if (!this.saving) {
        this.saving = true;
        // this.errors = {};

        if (this.active == '1') {
          this.communication.active = true;
        } else {
          this.communication.active = false;
        }

        if (this.sendNow == '0') {
          console.log(this.sendDate.startDate);
          console.log(this.sendTime);
          let timeOnly = moment(this.sendTime, 'h:mm A');
          let scheduledTime = this.sendDate.startDate;
          scheduledTime.set({
            hour: timeOnly.get('hour'),
            minute: timeOnly.get('minute')
          });
          console.log('scheduledTime: ', scheduledTime.format('MM/DD/YYYY h:mm A'));
          if (scheduledTime.toDate() > new Date()) {
            // This is in the future
            this.communication.scheduledTime = scheduledTime.toDate();
          } else {
            // This is in the past
            this.errors['scheduledTime'] = 'Date/Time has already passed';
            console.error('Date/Time has already passed');
          }
          console.log(scheduledTime.toDate());
        } else {
          this.communication.scheduledTime = null;
        }



        // if (this.errors == {}) {

          let communicationSaveValue = Object.assign({}, this.communication);
          communicationSaveValue.customers = null;
          communicationSaveValue.customerIds = this.selectedCustomerIds;




          console.log('Initialize dateTime inputs');
          if (communicationSaveValue.template) {
            let fields = Object.keys(communicationSaveValue.template);
            for (let field of fields) {
              if (Array.isArray(communicationSaveValue.template[field])) {
                for (let instance of communicationSaveValue.template[field]) {
                  let keys = Object.keys(instance);
                  for (let key of keys) {
                    console.log('Key: ');
                    console.log(instance[key]);
                    if (instance[key].endDate) {
                      instance[key] = instance[key].endDate;
                    }
                  }
                }
              }
            }
          }



          // let errors = this.validateCommunication();



          this.ioService.post('/communication/save', {
            communication: communicationSaveValue
          }).then((saveResponse: any) => {
            console.log('saveResponse: ', saveResponse);
            this.saving = false;
            this.communication.timeUpdated = new Date();

            resolve(communicationSaveValue._id);

          });
        // } else {
        //   // Show Errors
        //   console.log(this.errors);
        // }

      }
    });
  }

  send() {
    console.log('SEND: ');
    this.saving = true;
    this.ioService.post('/communication/sendCommunication', {
      communicationId: this.communication._id
    }).then((sendResponse: any) => {
      console.log('sendResponse: ', sendResponse);
      this.saving = false;
      this.communication.timeUpdated = new Date();

      // this.router.navigate(['marketing', 'dashboard']);
    }).catch((sendError: any) => {
      console.log(sendError);
      alert(sendError.error.msg);
    });
  }

  delete() {
    if (!this.deleting) {
      this.deleting = true;
      this.ioService.post('/communication/delete', {
        communicationId: this.communication._id
      }).then((deleteResponse: any) => {
        console.log('deleteResponse: ', deleteResponse);
        this.router.navigate(['marketing', 'dashboard']);
        // this.deleting = false;
      });
    }
  }

  isInvalidDate(date) {
    if (date.isSame(new Date(), "day")) {
      return false;
    } else {
      return date < new Date();
    }
  }

  loadInventoryOptions() {
    this.ioService.post('/communication/getInventoryOptions', {
      locationId: this.communication.locationId,
    }).then((inventoryResponse: any) => {
      console.log('inventoryResponse: ', inventoryResponse);
      this.inventoryOptions = inventoryResponse.inventoryOptions;
    });
  }

  loadEventOptions() {
    this.ioService.post('/communication/getEventOptions', {
      locationId: this.communication.locationId,
    }).then((eventResponse: any) => {
      console.log('eventResponse: ', eventResponse);
      this.eventOptions = eventResponse.eventOptions;
    });
  }

  contentChanged() {
    console.log('contentChanged');
    if (this.communication.type == 'text') {
      if (this.communication.content.length > this.charLimit) {
        this.communication.content = this.communication.content.substr(0, this.charLimit);
      } else {
        this.charRemaining = this.charLimit - this.communication.content.length;
      }
    }

  }

  fieldContentChanged(field) {
    console.log('fieldContentChanged: ',field);
    if (field.maxLength) {
      if (this.communication.template[field.path]) {
        field.charRemaining = field.maxLength - this.communication.template[field.path].length;
        if (field.charRemaining < 0) {
          field.charRemaining = 0;
          this.communication.template[field.path] = this.communication.template[field.path].substr(0, field.maxLength);
        }
      } else {
        field.charRemaining = field.maxLength;
      }

    }
  }

  addFilter() {
    this.activeFilters.push({
      key: ''
    });
  }

  removeFilter(filterIndex) {
    this.activeFilters.splice(filterIndex, 1);
    this.searchFilterCustomers();
  }

  searchUpdated() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchFilterCustomers();
    }, 500);
    this.resultsMsg = null;
    this.results = null;
  }

  addAll() {
    for (let customer of this.results) {
      this.selectCustomer(customer);
    }
  }

  removeAll() {
    console.log('removeAll');
    for (let customer of this.results) {
      let existingIndex = this.selectedCustomerIds.indexOf(customer._id);
      if (existingIndex != -1) {
        this.deselectCustomer(existingIndex);
      }
    }
  }

  selectCustomer(customer) {
    if (this.selectedCustomerIds.indexOf(customer._id) == -1) {
      this.selectedCustomerIds.push(customer._id);
      this.communication.customers.push(customer);
    }
  }

  deselectCustomer(customerIndex) {
    this.selectedCustomerIds.splice(customerIndex, 1);
    this.communication.customers.splice(customerIndex, 1);

  }

  clearCustomers() {
    this.selectedCustomerIds = [];
    this.communication.customers = [];
  }




  // toggleDiscount(active) {
  //   this.communication.discount.active = active;
  // }
  //
  // discountRangeSet() {
  //
  // }
  //
  // addEventId() {
  //   this.communication.eventIds.push(null);
  // }

  close() {
    this.router.navigate(['marketing', 'dashboard']);
  }


  addChildInstance(field) {
    console.log('field: ', field);

    if (field.options) {
      // Show list of pre-defined options
      field.optionsVisible = !field.optionsVisible;

    } else {
      // Add Freeform Child Element
      if (!this.communication.template[field.path]) {
        this.communication.template[field.path] = [];
      }

      this.communication.template[field.path].push({});
    }

    console.log(this.communication.template);
  }

  addInstanceFromOption(field, option) {
    if (!this.communication.template[field.path]) {
      this.communication.template[field.path] = [];
    }

    this.communication.template[field.path].push(option);

    field.optionsVisible = false;
  }

  removeInstance(fieldPath, i) {
    this.communication.template[fieldPath].splice(i, 1);
  }

  fieldDatesUpdated(range, fieldPath, i, childFieldPath) {
    console.log('fieldDatesUpdated -----');
    if (range && (range.startDate || range.endDate)) {
      console.log('------ updatedInner');
      this.communication.template[fieldPath][i][childFieldPath] = range.endDate;
    } else {
      console.log('range: ', range);
      console.log(this.communication.template[fieldPath][i][childFieldPath]);

      // this.fieldDatesUpdated()

    }
  }

  validateField(field) {
    let errors = [];

    switch (field.type) {
      case 'string':
        if (field.minLength) {

        }
        break;

      case 'array':
        // Validate that the qty of instances is in bounds

        // Validate child field instances


        break;

      case 'url':

        break;

      case 'dateTime':

        break;

      case 'image':

        break;
    }

    return errors;
  }

  validateCommunication() {
    let errors = [];

    if (this.communication.template) {
      for (let field of this.activeTemplate.fields) {
        console.log('Validate Field: ', field);

        let fieldErrors = this.validateField(field);
        console.log('Field Errors: ', fieldErrors);


      }
    } else {
      if (this.communication.type == 'email') {
        errors.push('Please select a template.');
      }
    }

    return errors;
  }


}
