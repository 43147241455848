<div class="detail" [class.visible]="user">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Employee</h2>
  </header>
  <section>
    <div *ngIf="!user" class="loading">Loading . . .</div>
    <div *ngIf="user">

      <div class="input-set input-set-general">
        <div>
          <label>First Name</label>
          <input type="text" [(ngModel)]="user.firstName" />
        </div>
        <div>
          <label>Last Name</label>
          <input type="text" [(ngModel)]="user.lastName" />
        </div>
        <div>
          <label>Email</label>
          <input type="email" id="email" [(ngModel)]="user.email" />
        </div>
        <div>
          <label>Phone</label>
          <input type="tel" id="telephone" [(ngModel)]="user.telephone" mask="(000) 000-0000" />
        </div>
      </div>
      <div class="buttons">
        <button *ngIf="user.telephone && user.telephone.length == 10 && !user.pinSent" class="button" (click)="sendPin()">Send PIN to Employee</button>
        <div *ngIf="user.pinSent" class="pin-sent">PIN Sent to User</div>
      </div>
      <div class="buttons">
        <button class="button red" (click)="deleteUser()">Delete Employee</button>
        <button class="button green" (click)="saveUser()">Save</button>
      </div>

      <h2>Locations</h2>
      <div *ngIf="manageUsersLocations" class="active-locations">
        <ng-container *ngFor="let manageUsersLocation of manageUsersLocations">
          <div *ngIf="user.locationId.indexOf(manageUsersLocation._id) != -1" class="location" (click)="deauthorizeLocation(manageUsersLocation._id)">{{manageUsersLocation.name}}</div>
        </ng-container>
      </div>
      <div *ngIf="manageUsersLocations" class="inactive-locations">
        <ng-container *ngFor="let manageUsersLocation of manageUsersLocations">
          <div *ngIf="user.locationId.indexOf(manageUsersLocation._id) == -1" class="location" (click)="authorizeLocation(manageUsersLocation._id)">{{manageUsersLocation.name}}</div>
        </ng-container>
      </div>

    </div>
  </section>
</div>
