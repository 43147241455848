import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import { PurchaseComponent } from './purchase.component';

@NgModule({
  declarations: [PurchaseComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxMaskModule.forRoot()
  ]
})
export class PurchaseModule { }
