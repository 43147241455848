import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabpage',
  templateUrl: './tabpage.component.html',
  styleUrls: ['./tabpage.component.scss']
})
export class TabpageComponent implements OnInit {

  @Input() title: string;
  @Input() active: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
