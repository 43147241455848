<div class="settings-body" *ngIf="location">
  <div class="close" (click)="close()"></div>
  <h2>First Purchase Anniversary Message Settings</h2>

  <div class="settings-panel">
    <div *ngIf="!location.marketing.anniversary.active" class="toggle off" (click)="toggleActive(true);"></div>
    <div *ngIf="location.marketing.anniversary.active" class="toggle on" (click)="toggleActive(false);"></div>
    <label>Send Anniversary Messages</label>
  </div>
  <div *ngIf="location.marketing.anniversary.active">
    <label>Anniversary Message</label>
    <textarea [(ngModel)]="location.marketing.anniversary.content" (keyup)="contentChanged()"></textarea>
    <div class="sub">{{charLimit - location.marketing.anniversary.content.length}} Characters Remaining</div>
  </div>
  <div *ngIf="location.marketing.anniversary.active">
    <label>Anniversary Message Send Time</label>
    <select [(ngModel)]="location.marketing.anniversary.sendTime">
      <option *ngFor="let timeOption of sendTimes" value="{{timeOption}}">{{timeOption}}</option>
    </select>
  </div>

  <div class="buttons">
    <div class="button green" [class.grey]="saving" (click)="save()">Save</div>
  </div>
</div>
