<div class="detail" [class.visible]="user">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <ng-container *ngIf="user">
      <h2 *ngIf="user.name">{{user.name}}</h2>
      <h2 *ngIf="!user.name">New User</h2>
    </ng-container>
  </header>
  <section>
    <div *ngIf="user" class="user">

      <div class="new-user-form">
        <div class="field">
          <label>First Name</label>
          <input [disabled]="!user?.canManage" type="text" [(ngModel)]="user.firstName" />
        </div>
        <div class="field">
          <label>Last Name</label>
          <input [disabled]="!user?.canManage" type="text" [(ngModel)]="user.lastName" />
        </div>
        <div class="field">
          <label>Email</label>
          <input [disabled]="!user?.canManage" type="email" id="email" [(ngModel)]="user.email" />
        </div>
        <div class="field">
          <label>Phone</label>
          <input [disabled]="!user?.canManage" type="tel" id="telephone" [(ngModel)]="user.telephone" mask="(000) 000-0000" />
        </div>
        <div class="field">
          <label>Last Login/Pin-in</label>
          <div>{{user.timeAuthenticated ? (user.timeAuthenticated | rf : 'dateTime') : '-Never-'}}</div>
        </div>
        <div *ngIf="user.passwordResetPending" class="field">
          <label style="color: blue">Password Reset Pending</label>
          <div>{{user.passwordResetPending | rf : 'dateTime'}}</div>
        </div>
        <div class="error">{{user.error}}</div>
        <div class="success">{{user.updated}}</div>
      </div>

      <div *ngIf="user.active && user.canManage">
        <button *ngIf="!user.passwordResetResponse" class="button-full" (click)="sendPasswordReset()" [disabled]="resetRecentlySent">Send Password Reset</button>
        <div *ngIf="resetRecentlySent">Please wait a minute before sending another reset email.</div>
        <div *ngIf="user.passwordResetResponse" class="pin">Email Sent</div>
      </div>
      <div *ngIf="user.active && user.canManage">
        <div class="button-full" (click)="sendPin()">Set PIN</div>
      </div>

      <div *ngIf="user.pinResponse" class="pin">
        {{user.pinResponse.pin}}
      </div>

      <div *ngIf="user.active && activeUserIsDeveloper">
        <div class="button-full" (click)="setApiKey()">Set API Key</div>
      </div>

      <div *ngIf="user.active" class="locations">
        <select [(ngModel)]="user.activeLocation" (change)="locationSelected()">
          <option [ngValue]="null">- Select Location -</option>
          <option *ngFor="let location of user.managableLocations" [ngValue]="location" [class.active]="location.active">{{location.name}}</option>
        </select>
      </div>

      <div class="permissions">
        <div *ngIf="user.permissionGroups && user.permissionGroups.length > 0" class="options-header">Permission Groups</div>
        <div *ngFor="let permissionGroup of user.permissionGroups" class="permission-group" [class.updating]="permissionGroup.updating" (click)="grantPermissionGroup(permissionGroup)">{{permissionGroup.name}}</div>

        <div *ngIf="user.potentialPermissions && user.potentialPermissions.length > 0" class="options-header">Permissions</div>
        <div *ngFor="let permission of user.potentialPermissions" class="permission" [class.active]="permission.active" [class.updating]="permission.updating" (click)="togglePermission(permission)">{{permission.name}}</div>
      </div>
    </div>
    <div class="buttons">
      <button [disabled]="user?.saving" class="button-full green w-100" (click)="saveChanges()">Save</button>
      <button [disabled]="user?.saving" class="button-full red w-100" (click)="disableUser()">Disable User</button>
    </div>
  </section>
</div>
