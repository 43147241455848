<div class="settings-body" *ngIf="location">
  <div class="close" (click)="close()"></div>
  <h2>{{location.name}} Notification & Autoresponder</h2>

  <div class="autoresponder">
    <div *ngIf="!location.autoresponder.active" class="toggle off" (click)="toggleAutoresponder(true);"></div>
    <div *ngIf="location.autoresponder.active" class="toggle on" (click)="toggleAutoresponder(false);"></div>
    <label>Autoresponder</label>
  </div>
  <div *ngIf="location.autoresponder.active">
    <label>Autoresponder Message</label>
    <textarea [(ngModel)]="location.autoresponder.content" (keyup)="contentChanged()"></textarea>
    <div class="sub">{{charLimit - location.autoresponder.content.length}} Characters Remaining</div>
  </div>

  <div>
    <label for="smsOnCall">Unread Message Notification Phone</label>
    <input type="tel" id="smsOnCall" class="telephone" [(ngModel)]="location.smsOnCall" mask="(000) 000-0000" />
  </div>

  <div class="buttons">
    <div class="button green" [class.grey]="saving" (click)="save()">Save</div>
  </div>

</div>
