import { DiscountFilter } from './discount-filter';

export class DiscountPurchaseQualifier {
    timeStart: string;
    timeEnd: string;
    filters: Array<DiscountFilter>;

    constructor() {
        this.filters = new Array<DiscountFilter>();
    }
}
