<div class="sticky-header">
    <app-dropdown [(optionGroup)]="itemDropdown"
        (optionGroupChange)="newItemSelected()"
        (searchChanged)="searchItems($event)"
        placeholder="Add Item">
    </app-dropdown>
    <div class="item-header">
        <div>Name</div>
        <div>Retail Price</div>
        <div>Value</div>
        <div>Sale Price</div>
        <div>Margin</div>
    </div>
</div>
<div class="item-container">
    <div class="item-div" *ngFor="let item of activeItems; let idx = index">
        <div class="inner">
            <div class="close small-close-button" (click)="deleteItem(item)"></div>
            <app-sale-item [showHeader]="idx === 0" [(item)]="activeItems[idx]" style="flex-grow: 1"></app-sale-item>
        </div>
        <div *ngIf="item.matchingDiscounts?.length > 0" class="item-discount-warning">Discount Exists: {{item.matchingDiscounts[0].name}}</div>
    </div>
</div>