<app-header class="no-print"></app-header>

<div *ngIf="purchase" class="inventory">

    <cdk-virtual-scroll-viewport *ngIf="purchase" itemSize="50" minBufferPx="500" maxBufferPx="500" class="inventory-table no-print" id="inventory-table">
        <ng-container *cdkVirtualFor="let item of purchase.items; let itemIndex = index;">
            <div class="row" [class.counted]="item.counted" [class.high]="item.qty > item.expectedQty" [class.low]="item.qty < item.expectedQty" id="item-{{itemIndex}}" (click)="setActiveItem(itemIndex)">
                <div class="name">{{item.name}}</div>
                <div class="count" *ngIf="item.counted">{{item.qty | rf:'number'}} of {{item.expectedQty | rf:'number'}}</div>
                <div class="count" *ngIf="!item.counted">{{item.qty | rf:'number'}}</div>
            </div>
        </ng-container>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
    </cdk-virtual-scroll-viewport>

</div>

<div *ngIf="activeItem" class="active-item">
    <div class="window"></div>
    <div class="body">
        <!-- {{activeItem | json}} -->
        <div class="count">
            <div class="actual">
                <div class="label">Actual</div>
                <div class="value">{{activeItem.qty | rf:'number'}}</div>
            </div>
            <div class="expected">
                <div class="label">Expected</div>
                <div class="value">{{activeItem.expectedQty | rf:'number'}}</div>
            </div>
        </div>

        <div class="num-pad">
            <!-- <div class="active-value">
                <span *ngIf="activePurchase.activeItem.existingCount">{{activePurchase.activeItem.existingCount | rf:'number'}} + </span>
                {{activePurchase.activeItem.activeValue | rf:'number'}}
            </div>
            <div class="count-label">COUNT</div>
            <div *ngIf="!item.variantLinks" class="discrepancy" [class.increase]="activePurchase.activeItem.discrepancy > 0" [class.decrease]="activePurchase.activeItem.discrepancy < 0">
                <span *ngIf="activePurchase.activeItem.discrepancy > 0">+</span>{{activePurchase.activeItem.discrepancy | rf:'number'}}
            </div> -->
            <div class="ripple-btn" (click)="countBtnPress(1)">1</div>
            <div class="ripple-btn" (click)="countBtnPress(2)">2</div>
            <div class="ripple-btn" (click)="countBtnPress(3)">3</div>
            <div class="ripple-btn" (click)="countBtnPress(4)">4</div>
            <div class="ripple-btn" (click)="countBtnPress(5)">5</div>
            <div class="ripple-btn" (click)="countBtnPress(6)">6</div>
            <div class="ripple-btn" (click)="countBtnPress(7)">7</div>
            <div class="ripple-btn" (click)="countBtnPress(8)">8</div>
            <div class="ripple-btn" (click)="countBtnPress(9)">9</div>
            <div class="ripple-btn" (click)="countBtnPress('c')">C</div>
            <div class="ripple-btn" (click)="countBtnPress(0)">0</div>
            <div class="ripple-btn" (click)="countBtnPress('d')">&lt;</div>
            <!-- <div class="reset ripple-btn" (click)="discardCount()">Discard Count</div>
            <div class="done ripple-btn" (click)="saveCount()">Save Count</div> -->
        </div>

    </div>
    <div class="buttons">
        <div class="red" (click)="cancel()">Cancel</div>
        <!-- <div class="yellow">Change Count</div> -->
        <div class="green" (click)="save()">Save</div>
    </div>
</div>

<div *ngIf="false">
    <div>
        Random Input:
        <input type="text" />
    </div>
    <div>COUNT</div>
    <div>{{lastKey}}</div>
    <div>***************</div>
    <div>{{keyboardBuffer}}</div>
    <div>***************</div>
    <div>{{barcode}}</div>
    <div>***************</div>
    <div>Barcode Count: {{barcodes.length}}</div>
    <div>***************</div>
    <div *ngFor="let barcode of barcodes">{{barcode}}</div>
</div>

