import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IoService } from '../io/io.service';

@Injectable({
  providedIn: 'root'
})
export class ListBuilderService {

  private listTypes = {
    priceChange: {
      name: "Price Change",
      collection: "Account"
    },
    printList: {
      name: "Print List",
    }
  };

  private listBuilderActive: Boolean = false;
  private activeList: any;
  private listBuilderActiveChanged = new Subject<any>();
  private statusChanged = new Subject<any>();
  private itemAdded = new Subject<any>();

  constructor(
    private ioService: IoService,
  ) { }

  newList() {
    this.activeList = {
      name: "New List",
      type: null,
      itemIds: [],
      items: {}
    };
  }

  setListBuilderActive(isActive) {
    if (isActive != this.listBuilderActive) {
      this.listBuilderActive = isActive;
      this.listBuilderActiveChanged.next(this.listBuilderActive);
    }
  }

  loadLists(listTypeKeys) {
    console.log('list builder service load lists');


    this.setListBuilderActive(true);


  }

  loadList(listId) {

  }

  getList() {
    return this.activeList;
  }

  addItem(documentId) {
    console.log('Added Document to list-builder service: ', documentId);

    if (!this.activeList) {
      this.newList();
    }

    this.activeList.itemIds.push(documentId);
    this.itemAdded.next(documentId);
    console.log(this.activeList);
  }

  listBuilderActiveChangedObservable(): Observable<any> {
    return this.listBuilderActiveChanged.asObservable();
  }

  statusChangedObservable(): Observable<any> {
    return this.statusChanged.asObservable();
  }

  itemAddedObservable(): Observable<any> {
    return this.itemAdded.asObservable();
  }
}
