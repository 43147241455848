import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { ScrollDispatcher } from '@angular/cdk/scrolling';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DragulaService } from 'ng2-dragula';

import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';
import { ListBuilderService } from '../../../services/list-builder/list-builder.service';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { FilterDateRangeComponent } from '../filter-date-range/filter-date-range.component';
import { DateRangeSettings } from '../filter-date-range/date-config-interface';

// import { ListBuilderComponent } from '../list-builder/list-builder.component';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.scss']
})
export class ReportDataComponent implements OnInit, OnDestroy {

  @ViewChild(FilterDateRangeComponent) dateRangePicker: FilterDateRangeComponent;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  reportReportKey$: Observable<any>;
  reportKey;

  activeReportConfig;
  dateRangeSettings: DateRangeSettings = new DateRangeSettings();

  tableWidth = 0;
  template;
  availableFields;
  activeFields;
  totals;
  valueFilters;

  fieldAddOpen;
  fieldsSort: Subscription;

  canAdd = false;
  adding = false;
  canImport = false;

  templateOptions;

  // On active report set, see if we can add based on the active location

  reportSelected: Subscription;
  reportLoaded: Subscription;
  recordsUpdated: Subscription;
  activeLocationSet: Subscription;
  // sortSet: Subscription;
  permissionsLoaded: Subscription;

  valueFiltersUpdated: Subscription;

  tsl = 0;

  records: any[];

  activeDocumentId;

  modalRef;

  documentTypes = {
    location: {
      addRoute: 'location/addLocation'
    },
    permission: {
      addRoute: 'authorization/addPermission'
    },
    ledger: {
      addRoute: 'ledger/addLedger',
      viewRoute: 'reports/ledgers/ledger'
    },
    template: {
      addRoute: 'template/addTemplate',
      viewRoute: 'templates'
    },
    adjustment: {
      addRoute: 'transfer/updateTransfer',
      viewRoute: 'transfers'
    },
    inventory: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'inventory',
        unitOfMeasure: 'EA'
      },
      viewRoute: 'reports/inventory/inventory'
    },
    inventoryCountable: {
      viewRoute: 'reports/inventory-balance-custom/inventory'
    },
    retailProduct: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'inventory',
        subtype: 'retail',
        unitOfMeasure: 'EA'
      },
      viewRoute: 'reports/retail-products/inventory'
    },
    vendorProduct: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'inventory',
        subtype: 'vendor',
        unitOfMeasure: 'EA'
      },
      viewRoute: 'reports/vendor-products/inventory'
    },
    // 'vendor-product': {
    //   addRoute: 'inventory/addVendorProduct',
    //   viewRoute: 'reports/vendor-products/vendor-product'
    // },
    component: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'inventory',
        subtype: 'component',
        unitOfMeasure: 'EA'
      },
      viewRoute: 'reports/components/inventory'
    },
    selector: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'inventory',
        subtype: 'selector',
        unitOfMeasure: 'EA'
      },
      viewRoute: 'reports/selectors/inventory'
    },
    iseItem: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'inventory',
        subtype: 'retail',
        unitOfMeasure: 'EA'
      },
      viewRoute: 'reports/ise-items/inventory'
    },

    // product: {
    //   addRoute: 'account/addAccount',
    //   addData: {
    //     type: 'inventory',
    //     subtype: 'product',
    //     unitOfMeasure: 'EA'
    //   },
    //   viewRoute: 'reports/products/inventory'
    // },
    // warehouseProduct: {
    //   addRoute: 'account/addAccount',
    //   addData: {
    //     type: 'inventory',
    //     subtype: 'product',
    //     unitOfMeasure: 'EA'
    //   },
    //   viewRoute: 'reports/warehouse-products/inventory'
    // },
    // collection: {
    //   addRoute: 'account/addAccount',
    //   addData: {
    //     type: 'inventory',
    //     subtype: 'collection',
    //     unitOfMeasure: 'EA'
    //   },
    //   viewRoute: 'reports/collections/inventory'
    // },
    tax: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'tax'
      },
      viewRoute: 'reports/taxes/tax'
    },
    discount: {
      addRoute: 'account/addAccount',
      addData: {
        type: 'discount'
      },
      viewRoute: 'reports/discounts/discount'
    },
    permissionGroup: {
      addRoute: 'group/addPermissionGroup',
      addData: {
        type: 'permission',
        key: true
      },
      viewRoute: 'reports/permission-groups/permissionGroup'
    },
    purchase: {
      addRoute: 'purchase/addPurchase',
    },
    transaction: {},
    user: {
      addRoute: 'user/addUser',
      addData: {
        type: 'user'
      },
      viewRoute: 'reports/users/user'
    },
    customer: {
      addRoute: 'customer/addCustomer',
      viewRoute: 'reports/customers/customer'
    },
    report: {
      viewRoute: 'reports/reports/report'
    },
    ipadTerminal: {
      // addRoute: 'terminal/addTerminal',
      // addData: {
      //   type: 'ipad'
      // },
      viewRoute: 'reports/ipads/terminal'
    },
    creditTerminal: {
      addRoute: 'terminal/addTerminal',
      addData: {
        type: 'credit'
      },
      viewRoute: 'reports/credit-terminals/terminal'
    }
  };

  listBuilderActiveChanged: Subscription;
  listBuilderActive: Boolean = false;

  @ViewChild('importrecords') importrecordsEl: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
    private tableScroll: ScrollDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private ioService: IoService,
    private listBuilderService: ListBuilderService,
  ) { }

  ngOnInit() {
    this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(loaded => {
      this.checkReportAuthorizations();
    });

    this.reportSelected = this.reportService.reportSelectedObservable().subscribe(reportConfig => {

      // console.log('REPORT CONFIG: ', reportConfig);
      if (this.activeReportConfig && reportConfig.key != this.activeReportConfig.key) {
        this.listBuilderService.setListBuilderActive(false);
      }

      LoadingComponent.showLoading();
      this.activeReportConfig = reportConfig;
    });

    this.listBuilderActiveChanged = this.listBuilderService.listBuilderActiveChangedObservable().subscribe(isActive => {
      this.listBuilderActive = isActive;
    });

    this.activeReportConfig = this.reportService.getActiveReportConfig();
    this.dateRangeSettings.dateRange = this.reportService.getDateRange();

    this.reportLoaded = this.reportService.reportLoadedObservable().subscribe(reportMeta => {
      this.activeFields = reportMeta.activeFields;

      this.tableWidth = 0;
      for (const field of this.activeFields) {
        this.tableWidth += field.width;
      }
      // if (this.listBuilderActive) {
        this.tableWidth += 50;
      // }

      this.template = reportMeta.template;
      this.totals = reportMeta.totals;
      this.templateOptions = reportMeta.templateOptions;

      // Conditionally close the active list builder
      if (this.listBuilderActive) {

      }

      this.viewport.scrollToIndex(0);

      this.setAvailableFields();

      this.checkReportAuthorizations();
    });

    this.recordsUpdated = this.reportService.recordsUpdatedObservable().subscribe( (newRecords) => {
      this.records = newRecords;
    });

    this.valueFilters = null;
    if (this.valueFiltersUpdated) {
      this.valueFiltersUpdated.unsubscribe();
    }
    this.valueFiltersUpdated = this.reportService.valueFiltersUpdatedObservable().subscribe(valueFilters => {
      this.valueFilters = valueFilters;
      console.log('VALUE FILTERS UPDATED!');
      console.log(this.valueFilters);
    });

    this.tableScroll.scrolled().subscribe(x => {
      if (x) {
        const tableScrollLeft = x.getElementRef().nativeElement.scrollLeft;
        if (this.tsl != tableScrollLeft) {
          this.tsl = x.getElementRef().nativeElement.scrollLeft;
          this.changeDetectorRef.detectChanges();
        }
      }
    });

    this.reportReportKey$ = this.route.paramMap.pipe(
      switchMap(params =>
        of(params.get('key'))
      )
    );

    this.reportReportKey$.subscribe(key => {
      if (key) {
        this.setReportByKey(key);
      }
    });

    this.fieldsSort = this.dragulaService.drop('fields').subscribe(({ name, el, target, source, sibling }) => {
      this.reportService.setFieldsSequence(this.activeFields);
    });

    // Listen to routes to get active documentId
    this.setActiveDocumentByPath(this.router.url);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.setActiveDocumentByPath(event.url);
    });
  }

  dateRangeUpdated( range ) {
    this.reportService.setDateRange(range);
  }

  ngOnDestroy() {
    this.permissionsLoaded.unsubscribe();
    this.fieldsSort.unsubscribe();
    this.valueFiltersUpdated.unsubscribe();
    this.recordsUpdated.unsubscribe();
    this.listBuilderActiveChanged.unsubscribe();
  }

  setActiveDocumentByPath(path) {
    const pathParts = path.split('/');
    if (pathParts.length == 5) {
      this.activeDocumentId = pathParts[4];
    } else {
      this.activeDocumentId = null;
    }
  }

  setReportByKey(key) {
    this.reportKey = key;
    this.reportService.setReportByKey(key);
  }

  filterLink(link) {
    this.router.navigate(['reports', this.activeReportConfig.key, this.activeReportConfig.itemType, link]);
  }

  checkReportAuthorizations() {
console.log('CHECK REPORT AUTHORIZATIONS:');    
    if (this.activeReportConfig) {
console.log('activeReportConfig is defined: ', this.activeReportConfig);

      this.canAdd = false;


      // 2020-10-09-2020
      this.activeReportConfig.canUpdate = this.authorizationService.checkPermission('Report.manage', 'general');


      if (this.activeReportConfig.addPermission) {
console.log('addPermission is defined: *'+this.activeReportConfig.addPermission+'*');
        if (this.activeReportConfig.locationPath && this.activeReportConfig.locationPath != 'any' && this.activeReportConfig.locationPath != '_id') {
          // This permission is location specific
console.log('CHECK CANADD: ', this.locationService.getActiveLocation());

          if (this.locationService.getActiveLocation()._id) {
console.log('an active location is selected');            
            // A single location is selected
            if (this.authorizationService.checkPermission(this.activeReportConfig.addPermission, this.locationService.getActiveLocation()._id)) {
              // console.log('Authorized');
              this.canAdd = true;
            }
          } else {
            // No location is selected
console.log('no location is selected');
            // console.log(this.locationService.getLocations());
            for (const location of this.locationService.getLocations()) {
              if (location._id) {

                if (this.authorizationService.checkPermission(this.activeReportConfig.addPermission, location._id)) {
                  // console.log('Authorized');
                  this.canAdd = true;
                }

              }
            }

          }

        } else {
          // This permission is NOT location specific
console.log('PERMISSION IS NOT LOCATION SPECIFIC: ', this.activeReportConfig.addPermission);
console.log('reportLocationPath: ', this.activeReportConfig.locationPath);
          if (this.authorizationService.checkPermission(this.activeReportConfig.addPermission, 'general')) {
            this.canAdd = true;
          }
        }
      } else {
        // console.log('No addPermission for the active report');
      }
    }


    if (this.activeReportConfig && this.activeReportConfig.importRoute) {
      this.canImport = this.authorizationService.checkPermission('Developer', 'general');
    }

  }

  getDocumentConfig() {
    let documentConfig: any = {};

    // Legacy Configuration
    if (this.activeReportConfig.itemType && this.documentTypes[this.activeReportConfig.itemType]) {
      documentConfig = this.documentTypes[this.activeReportConfig.itemType];
    }

    for (const configParam of [
      'itemType',
      'addPermission',
      'addRoute',
      'addData',
      'viewRoute',
    ]) {
      if (this.activeReportConfig[configParam] !== undefined) {
        console.log('found: ' + configParam);
        console.log(this.activeReportConfig[configParam]);
        documentConfig[configParam] = this.activeReportConfig[configParam];
      }
    }

    console.log('documentConfig: ', documentConfig);
    return documentConfig;
  }

  add() {
    this.reportService.setAdding(true);

    const documentConfig = this.getDocumentConfig();

    let addData = {
      locationId: null
    };
    if (documentConfig.addData) {
      addData = documentConfig.addData;
    }
    addData.locationId = this.locationService.getActiveLocation()._id;

    this.ioService.post(documentConfig.addRoute, addData).then((addResponse: any) => {
      console.log('addResponse: ', addResponse);
      this.setDetailDocumentId(addResponse._id);
      this.reportService.loadReport(0);
    });

  }

  setDetailDocumentId(recordId) {
    const documentConfig = this.getDocumentConfig();

    if (documentConfig.viewRoute) {
      this.router.navigate([documentConfig.viewRoute, recordId]);
    } else if (this.activeReportConfig.itemType) {
      this.router.navigate(['reports', this.activeReportConfig.key, this.activeReportConfig.itemType, recordId]);
    }
  }

  setAvailableFields() {
    this.availableFields = [];
    for (const potentialField of this.template.fields) {
      if (this.activeFields.indexOf(potentialField) == -1) {
        this.availableFields.push(potentialField);
      }
    }
  }

  toggleFieldAdd() {
    this.fieldAddOpen = !this.fieldAddOpen;
  }

  addField(field) {
    this.reportService.addField(field);
  }

  removeField(event: Event, field) {
    event.stopPropagation();
    const activeIndex = this.activeFields.indexOf(field);
    if (activeIndex > -1) {
      this.activeFields.splice(activeIndex, 1);
    }
    this.reportService.removeField(field);
  }

  downloadReport() {
    this.reportService.download();
  }

  printReport(printTemplate) {
    this.reportService.printReport(printTemplate.key);
  }

  setSearch(event: any) {
    this.reportService.setSearch(event.target.value);
  }

  setSort(field) {
    this.reportService.setSort(field);
  }

  reportScrolled(topRecordIndex) {
    // Don't bother loading chunks on scroll for custom reports
    if (!this.activeReportConfig || !this.activeReportConfig.type || this.activeReportConfig.type != 'custom') {
      this.reportService.loadChunk(topRecordIndex);
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  getValueByPath(o, path) {
    if (o == null) {
      return;
    } else {
      path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      path = path.replace(/^\./, '');           // strip a leading dot
      const a = path.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
          let k = a[i];
          if (k in o) {
              o = o[k];
          } else {
              return;
          }
      }
      return o;
    }
  }

  refreshReport() {
    LoadingComponent.showLoading();
    this.reportService.refresh();
  }

  updateReport = async () => {
    const updateResult = await this.ioService.post('/report/updateFromRemote', {
      reportId: this.activeReportConfig._id
    });
  }

  onRightClick(record, field) {
    console.log('right click');
    console.log('record: ', record);
    console.log('field: ', field);

    if ([
      'string',
      'id',
      'remoteId',
      'currency',
      'currencyInverse',
      'currencyDetail',
    ].indexOf(field.type) != -1) {

      this.reportService.filterByFieldValue(field, this.getValueByPath(record, field.path));
    } else {
      console.log('No value filter configured for type: ' + field.type);
    }

    return false;
  }

  removeValueFilterIndex(valueFilterIndex) {
    console.log('valueFilterIndex: ', valueFilterIndex);
    this.reportService.removeValueFilter(valueFilterIndex);
  }

  onImportFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ioService.upload(this.activeReportConfig.importRoute, {
        // locationId: this.documentId
      }, file).subscribe(event => {
        console.log(event);

      });
    }
  }

  importRecords() {
    console.log('import:', this.activeReportConfig.importRoute);
    const el: HTMLElement = this.importrecordsEl.nativeElement;
    el.click();
  }

  loadLists(listTypeKeys) {
    console.log('loadLists: ');
    this.listBuilderService.loadLists(listTypeKeys);
  }

  addToList(event: Event, documentId) {
    event.stopPropagation();

    console.log(documentId);
    this.listBuilderService.addItem(documentId);

  }
}
