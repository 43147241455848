import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { DropdownOption, DropdownOptionGroup, DropdownSelectionMode } from '../../interfaces/option-interfaces';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, AfterViewInit {

  @Input()
  optionGroup: DropdownOptionGroup;

  @Output()
  optionGroupChange = new EventEmitter<DropdownOptionGroup>();

  get selectMode(): string {
    return Object.keys(DropdownSelectionMode).find(key => DropdownSelectionMode[key] === this.optionGroup.selectMode);
  }

  @Input() placeholder: string;
  @Input() noSelectionText = 'None';
  @Input() disableSearch = false;
  @Input() enableAddNew = false;
  @Input() searchLocation = 'below';

  @Output() inputBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() newSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef;

  public selectionMode = DropdownSelectionMode;

  searchText = '';
  showList = false;
  selectedIndex = -1;
  cursorInList = false;

  constructor( ) { }

  ngAfterViewInit() {
    fromEvent( this.input.nativeElement, 'keyup' )
    .pipe(
        filter(Boolean),
        debounceTime(400),
        distinctUntilChanged(),
        tap( (e: KeyboardEvent) => {
          if (!this.isSpecial(e.key)) {
            this.search();
          }
        })
    )
    .subscribe();
  }

  ngOnInit(): void {
  }

  async search() {
    this.selectedIndex = -1;
    this.optionGroup.options = null;
    this.showList = true;
    this.searchChanged.emit( this.searchText );
  }

  blur() {
    if ( this.cursorInList ) {
      return;
    }
    this.showList = false;
    this.searchText = null;
    this.inputBlur.emit();
  }

  addAllOptions() {
    for ( const option of this.optionGroup.options ) {
      this.searchOptionSelected(option, false);
    }
    this.optionGroup.options = null;
    this.optionGroupChange.emit( this.optionGroup );
  }

  searchOptionSelected( searchOption, resetOptions = true ) {
    this.showList = false;

    switch ( this.optionGroup.selectMode  ) {
      case DropdownSelectionMode.One:
        if ( this.optionGroup.selection.length > 0 ) {
          this.removeSelection( this.optionGroup.selection[0]);
        }
        break;
    }
    this.optionGroup.selection.push( Object.assign(new DropdownOption( searchOption._id, searchOption.name ), searchOption) );
    if ( resetOptions ) {
      this.optionGroup.options = null;
      this.optionGroupChange.emit( this.optionGroup );
    }
  }

  removeSelection(selection) {
    this.optionGroup.selection.splice( this.optionGroup.selection.indexOf(selection), 1 );
    this.optionGroupChange.emit( this.optionGroup );
  }

  addNew() {
    this.newSelected.emit( this.searchText );
  }

  selectItem( index: number ) {
    this.selectedIndex = index;
  }

  submitSelected() {
    if (!this.optionGroup.options || this.selectedIndex < 0 || this.selectedIndex >= this.optionGroup.options.length ) {
      return;
    }
    this.searchOptionSelected(this.optionGroup.options[this.selectedIndex]);
    this.selectedIndex = -1;
  }

  isSpecial(key: string ) {
    switch ( key.toLowerCase() ) {
      case 'arrowup':
      case 'arrowdown':
      case 'arrowleft':
      case 'arrowright':
      // case 'enter':
      case 'escape':
        return true;
      default: return false;
    }
  }

  keyDown(key) {
    switch ( key.key ) {
      case 'ArrowUp':
        this.upPressed();
        break;
      case 'ArrowDown':
        this.downPressed();
        break;
      case 'Enter':
        this.submitSelected();
        break;
      case 'Escape':
        this.blur();
        break;
      default: break;
    }
  }

  downPressed() {
    if ( this.optionGroup.options && this.optionGroup.options.length > 0 &&
         this.selectedIndex + 1 < this.optionGroup.options.length ) {
      this.selectedIndex++;
    }
  }

  upPressed() {
    if ( this.optionGroup.options && this.optionGroup.options.length > 0 && this.selectedIndex - 1 >= 0 ) {
      this.selectedIndex--;
    }
  }

  mouseInList() {
    this.cursorInList = true;
  }

  mouseOutOfList() {
    this.cursorInList = false;
  }
}
