import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IoService } from '../../services/io/io.service';
import { ReportService } from '../../services/report/report.service';
import { LocationService } from '../../services/location/location.service';
import { switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { Location } from '@angular/common';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';



@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryComponent implements OnInit {

  purchaseId$: Observable<any>;
  purchaseId;

  activePurchase = {
    _id: null,
    showManualSearch: false,
    hideCountedItems: false,
    showVariantsOnly: false,
    allowAddNew: false,
    activeItemIndex: null,
    countComplete: false,
    printing: false,
    activeItem: null,
    searchText: null,
    searchTimeout: null,
    type: null,
    locationId: null,
    customerId: null,
    lastRetrieved: null,
    barcodes: {},
    variants: {},
    items: [],
  };

  sampleData = [];

  @ViewChild('searchinput', { static: false }) searchInput: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    private locationR: Location,
    private reportService: ReportService,
    private tableScroll: ScrollDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.purchaseId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('purchaseId'))));
    this.purchaseId$.subscribe(purchaseId => {
      if (purchaseId) {
        console.log('purchaseId is defined: ', purchaseId);
        this.purchaseId = purchaseId;
        this.loadPurchase();
      } else {
        console.log('purchaseId is not defined.');

      }
    });
  }

  loadPurchase = async () => {
    console.log('loadPurchase: ', this.purchaseId);
    let purchaseResponse: any = await this.ioService.post('/transfer/getTransfer', {
      purchaseId: this.purchaseId
    });

    // Move all the variants to the bottom so they don't mess up our indexes. This is a temporary solution as virtual scroll is struggling with ngIfs inside
    let originalIndex = 0;
    let items = [];
    let itemVariants = [];
    for (let item of purchaseResponse.items) {
      item.originalIndex = originalIndex;
      if (item.childAccountId) {
        itemVariants.push(item);
      } else {
        items.push(item);
      }
      originalIndex++;
    }
    purchaseResponse.items = items.concat(itemVariants);

    // Build index of barcodes
    let barcodes = {};
    let variants = {};
    let itemIndex = 0;
    for (let item of purchaseResponse.items) {
      item.index = itemIndex;

      if (item.barcode) {
        if (Array.isArray(item.barcode)) {
          for (let barcode of item.barcode) {
            barcodes[barcode] = itemIndex;
          }
        } else {
          barcodes[item.barcode] = itemIndex;
        }
      }

      if (item.childAccountId) {
        if (!variants[item.childAccountId]) {
          variants[item.childAccountId] = [];
        }
        variants[item.childAccountId].push({
          index: item.index,
          childQty: item.childQty,
          adjustedQty: item.adjustedQty,
        });
      }

      itemIndex++;
    }

    for (let item of purchaseResponse.items) {
      if (variants[item.accountId]) {
        if (!item.variantLinks) {
          let variantLinks = variants[item.accountId];

          variantLinks.sort((a, b) => (a.childQty < b.childQty) ? 1 : -1);

          variantLinks.push({
            index: item.index,
            childQty: 1,
            adjustedQty: item.adjustedQty,
          });

          item.variantLinks = variantLinks;
        }

        let variantsSum = 0;
        for (let variant of variants[item.accountId]) {
          variantsSum += Number(variant.adjustedQty)*variant.childQty;
        }
        item.variantsSum = variantsSum;
      }
    }

    purchaseResponse.barcodes = barcodes;
    purchaseResponse.variants = variants;
    console.log('BARCODES: ', barcodes);

    purchaseResponse.allowAddNew = true;
    purchaseResponse.activeItemIndex = null;

    this.activePurchase = purchaseResponse;

    this.setInputFocus();

    return;
  }

  getAccountIndex(accountId) {
    let itemIndex = 0;
    for (let item of this.activePurchase.items) {
      if (item.accountId == accountId) {
        return itemIndex;
      }
      itemIndex++;
    }
    return null;
  }

  refresh = async () => {
    await this.loadPurchaseUpdates(this.activePurchase.activeItemIndex);
    return;
  }

  loadPurchaseUpdates = async (omitIndex) => {
    let purchaseUpdatesResponse: any = await this.ioService.post('/transfer/getTransferUpdates', {
      purchaseId: this.activePurchase._id,
      lastRetrieved: this.activePurchase.lastRetrieved,
      omitIndex,
    });

    if (purchaseUpdatesResponse.updatedItems && purchaseUpdatesResponse.updatedItems.length > 0) {
      for (let updatedItem of purchaseUpdatesResponse.updatedItems) {
        let localIndex = this.getAccountIndex(updatedItem.item.accountId);

        console.log('localIndex: ', localIndex);

        this.activePurchase.items[localIndex].qty = updatedItem.item.qty;
        this.activePurchase.items[localIndex].adjustedQty = updatedItem.item.adjustedQty;
        this.activePurchase.items[localIndex].counted = updatedItem.item.counted;

        // this.activePurchase.items[updatedItem.index] = updatedItem.item;
        // this.activePurchase.items[updatedItem.index].qty = updatedItem.qty;
        // this.activePurchase.items[updatedItem.index].adjustedQty = updatedItem.adjustedQty;
        // this.activePurchase.items[updatedItem.index].counted = updatedItem.counted;
      }
    }

    this.checkCountComplete();

    this.activePurchase.lastRetrieved = purchaseUpdatesResponse.lastRetrieved;

    return;
  }

  checkCountComplete() {
    let total = 0;
    let counted = 0;
    for (let item of this.activePurchase.items) {
      total ++;
      if (item.counted) {
        counted++;
      } else {
        if (item.targetQty == 0) {
          counted++;
        }
      }
    }

    if (counted == total) {
      this.activePurchase.countComplete = true;
    } else {
      this.activePurchase.countComplete = false;
    }

    return {
      total,
      counted,
    };
  }

  toggleManualSearch() {
    this.activePurchase.showManualSearch = !this.activePurchase.showManualSearch;
    this.setInputFocus();
  }

  toggleAllowAddNew() {
    this.activePurchase.allowAddNew = !this.activePurchase.allowAddNew;
    this.setInputFocus();
  }

  toggleHideCountedItems() {
    this.activePurchase.hideCountedItems = !this.activePurchase.hideCountedItems;
    this.activateRow(null);
    this.setInputFocus();
  }

  activateRow = async (itemIndex) => {

    console.log('activateRow: ', itemIndex);

    if (itemIndex === null) {
      this.activePurchase.activeItemIndex = null;
      this.activePurchase.activeItem = null;
      this.setInputFocus();
    } else {
      if (this.activePurchase.activeItem && this.activePurchase.activeItem.index === itemIndex) {
        // let activeItem = this.getActiveItem();
        this.activePurchase.activeItem.activeValue = String(Number(this.activePurchase.activeItem.activeValue)+1);
        this.updateCount();
      } else {
        console.log('this is not the active item');

        let variantIndex = null;
        if (this.activePurchase.activeItemIndex !== null) {
          let lineItem = this.activePurchase.items[this.activePurchase.activeItemIndex];
          // See if it is a variant being selected
          if (lineItem.variantLinks) {
            console.log('This could be a variant of the active item');
            for (let variantLink of lineItem.variantLinks) {
              if (variantLink.index === itemIndex) {
                variantIndex = itemIndex;
console.log('This is a variant!');
              }
            }
          }
        }


        if (this.activePurchase.activeItem == null || variantIndex !== null) {
console.log('no item is currently selected');
          // No item is currently selected
          this.activePurchase.activeItem = this.activePurchase.items[itemIndex];
          let activeItem = this.activePurchase.activeItem;
          if (this.activePurchase.activeItem.childAccountId) {
            // Go to the child row
            this.activePurchase.activeItemIndex = this.getAccountIndex(activeItem.childAccountId);
          } else {
            // Go to the active item row
            this.activePurchase.activeItemIndex = itemIndex;
          }

          console.log('activeItemIndex: ', this.activePurchase.activeItemIndex);

          if (this.activePurchase.hideCountedItems && activeItem.counted) {
            this.activePurchase.hideCountedItems = false;
          }

          // let el = document.getElementById("item-"+this.activePurchase.activeItemIndex);
          setTimeout(() => {
            this.viewPort.scrollToIndex(this.activePurchase.activeItemIndex);
          }, 10);

          // let activeItem = this.getActiveItem();
          console.log('ITEM: ', activeItem);

          console.log('VARIANTS: ', activeItem.variantLinks);

          // Prevent NAN
          if (!activeItem.adjustedQty) {
            activeItem.adjustedQty = 0;
          }

          activeItem.activeValue = String(activeItem.adjustedQty);

          // Update Data From Server
          let itemDetail: any = await this.ioService.post('/inventory/getInventoryAccount', {
            accountId: activeItem.accountId
          });
  
          if (itemDetail._id == activeItem.accountId) {
            activeItem.timeRetrieved = itemDetail.timeRetrieved;
            activeItem.name = itemDetail.name;
            activeItem.sku = itemDetail.sku;
            
            activeItem.unitOfMeasure = itemDetail.unitOfMeasure;
            activeItem.unitDescription = itemDetail.unitDescription;
            if (itemDetail.category) {
              activeItem.categoryName = itemDetail.category.name;
            }
    
            // activeItem.adjustedQty = 0;
            if (!activeItem.childAccountId && itemDetail.balance && itemDetail.balance[this.activePurchase.customerId]) {
              activeItem.targetQty = itemDetail.balance[this.activePurchase.customerId];
            }
          } else {
            console.log('this is no longer the active item');
          }

          
          this.updateCount();

        } else {
          // An item is selected and must be dismissed first
          // let el = document.getElementById("item-"+this.activePurchase.activeItemIndex);
          setTimeout(() => {
            // el.scrollIntoView({
            //   behavior: 'smooth'
            // });
            this.viewPort.scrollToIndex(this.activePurchase.activeItemIndex);//, 'smooth');
            // console.log('offsetTop: ', el.offsetTop);
            // this.viewPort.scrollToOffset(el.offsetTop, 'smooth');

            // this.viewPort.scrollToIndex(this.getVisualIndex(this.activePurchase.activeItemIndex), 'smooth');
          }, 50);
        }

      }
    }
  }

  getVisualIndex(activeItemIndex) {
    let visualIndex = 0;
    let arrayIndex = 0;
    for (let item of this.activePurchase.items) {
      if (arrayIndex == activeItemIndex) {
        break;
      }
      if (item.childAccountId == null) { // hideCountedItems
        visualIndex++;
      } else {
        // console.log(item.childAccountId);
      }
      
      arrayIndex++;
    }


    // add 1 for every multiple of the scroll height
    // let amountToAdd = Math.floor(arrayIndex/10);
    // console.log('amountToAdd: ', amountToAdd);
    // visualIndex += amountToAdd;

    console.log('activeItemIndex: ', activeItemIndex);
    console.log('visualIndex: ', visualIndex);




    return visualIndex;
  }

  getActiveItem() {
    // return this.activePurchase.items[this.activePurchase.activeItemIndex];
    return this.activePurchase.activeItem;
  }

  startChangeCount() {
    let activeItem = this.getActiveItem();
    activeItem.activeValue = "0";
    this.updateCount();
    activeItem.counted = false;
  }

  startAddToCount() {
    let activeItem = this.getActiveItem();
    activeItem.existingCount = activeItem.adjustedQty;
    // activeItem.adjustedQty = 0;

    activeItem.activeValue = "0";    
  }

  countBtnPress(value) {
    let activeItem = this.getActiveItem();
    if (!activeItem.saving) {
      if (Number.isInteger(value)) {
        if (activeItem.activeValue == "0") {
          activeItem.activeValue = "";
        }
        activeItem.activeValue = activeItem.activeValue+value;
      } else {
        switch (value) {
          case "d":
            activeItem.activeValue = activeItem.activeValue.slice(0, -1);
            break;
  
          case "c":
            activeItem.activeValue = "0";
            break;
        }
      }
      this.updateCount();
    }

    this.setInputFocus();
  }

  updateCount() {
    let activeItem = this.getActiveItem();
    if (activeItem.activeValue) {
      activeItem.discrepancy = Number(activeItem.activeValue)-activeItem.targetQty;
      if (activeItem.existingCount) {
        activeItem.discrepancy += activeItem.existingCount;
      }
    }
  }

  
  discardCount() {

    this.activateRow(null);
  }

  saveCount = async () => {
    let activeItem = this.getActiveItem();

    activeItem.saving = true;

    activeItem.adjustedQty = Number(activeItem.activeValue);

    if (activeItem.existingCount) {
      activeItem.adjustedQty += activeItem.existingCount;
      delete activeItem.existingCount;
    }

    activeItem.counted = true;

    activeItem.qty = activeItem.adjustedQty - activeItem.targetQty;
    activeItem.qtySpecifiedField = 'adjustedQty';

    // Send Update to Server
    let countUpdate: any = await this.ioService.post('/transfer/updateTransferItem', {
      purchaseId: this.activePurchase._id,
      type: this.activePurchase.type,
      locationId: this.activePurchase.locationId,
      customerId: this.activePurchase.customerId,
      itemIndex: this.activePurchase.activeItem.originalIndex,
      item: activeItem,
    });

    // Update child count
    if (activeItem.childAccountId || activeItem.variantLinks) {
      // Find the child item and show the qty from the parent
      console.log('need to update variantsSum');

      let countableTarget;
      if (activeItem.childAccountId) {
        countableTarget = this.activePurchase.items[this.getAccountIndex(activeItem.childAccountId)];
      } else {
        countableTarget = activeItem;
      }
console.log('countableTarget: ', countableTarget);
      let variantsSum = 0;
      for (let variantLink of countableTarget.variantLinks) {
        console.log('variantLink: ', variantLink);
        let variantItem = this.activePurchase.items[variantLink.index];
        console.log('variantItem: ', variantItem);
        if (!variantItem.childQty) {
          // This is the countable item
          variantsSum += variantItem.adjustedQty;
        } else {
          variantsSum += Number(variantItem.adjustedQty)*variantItem.childQty;
        }
      }
      countableTarget.variantsSum = variantsSum;

    }

    activeItem.saving = false;

    this.loadPurchaseUpdates(this.activePurchase.activeItem.index);

    this.activateRow(null);
  }

  search() {
    if (this.activePurchase.searchTimeout) {
      clearTimeout(this.activePurchase.searchTimeout);
    }

    let searchDebounceTime = 50;
    if (this.activePurchase.showManualSearch) {
      searchDebounceTime = 700;
    }

    this.activePurchase.searchTimeout = setTimeout(() => {
      console.log(this.activePurchase.searchText);
      this.searchLocal();
    }, searchDebounceTime);
  }

  searchLocal() {
    let barcodeIndex = this.activePurchase.barcodes[this.activePurchase.searchText.trim()];
    console.log(barcodeIndex);
    if (barcodeIndex !== undefined) {
      this.activePurchase.searchText = null;
      this.activateRow(barcodeIndex);
    } else {
      // No direct match found
      if (this.activePurchase.showManualSearch) {
        // Run Server-Side Search
        console.log('Search Server');

      } else {
        // Notify User Barcode Not Found
        alert("Not found: "+this.activePurchase.searchText);
        this.activePurchase.searchText = null;
      }
    }
  }

  setInputFocus() {
    if (!this.activePurchase.showManualSearch) {
      this.searchInput.nativeElement.focus();
    }
  }

  submitCount = async () => {
    let statusUpdate: any = await this.ioService.post('/transfer/updateTransferStatus', {
      purchaseId: this.activePurchase._id,
      status: 'pending',
    });


  }

  trackByIndex(index: number) {
    return index;
  }

  toggleVariants() {
    this.activateRow(null);

    if (this.activePurchase.showVariantsOnly) {
      this.activePurchase.hideCountedItems = false;
      this.activePurchase.showVariantsOnly = false;
    } else {
      // Now showing only variants
      this.activePurchase.hideCountedItems = false;
      this.activePurchase.showVariantsOnly = true;
    }
  }

  print = async () => {
    // this.activePurchase.printing = true;
    this.activateRow(null);
    await this.refresh();
    this.activePurchase.hideCountedItems = false;
    this.activePurchase.showVariantsOnly = true;
    setTimeout(() => {

      window.print();
      this.activePurchase.showVariantsOnly = false;
    }, 200);

  }

}
