import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IoService } from 'src/app/services/io/io.service';
import { LocationService } from 'src/app/services/location/location.service';
import { DiscountGroup } from '../../shared/interfaces/discounts/discount-group';
import { DiscountItemSaleType } from '../../shared/interfaces/discounts/discount-group-item';

@Component({
  selector: 'app-detail-discount-group',
  templateUrl: './detail-discount-group.component.html',
  styleUrls: ['./detail-discount-group.component.scss']
})
export class DetailDiscountGroupComponent implements OnInit {

  documentId$: Observable<any>;
  documentId: string;
  discountGroup: DiscountGroup;

  constructor( private route: ActivatedRoute,
               private ioService: IoService,
             ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.retrieveGroup();
      }
    });
  }

  async retrieveGroup() {
    if ( !this.documentId ) {
      console.error('Invalid group document Id.');
      return;
    }
    this.discountGroup = new DiscountGroup();
    this.discountGroup.setValue( await this.ioService.post('/group/getDiscountGroup', {
      groupId: this.documentId,
      detail: true
    }));
    this.discountGroup.type = 'discount';
    if ( !this.discountGroup.subtype ) {
      this.discountGroup.subtype = 'sale-ad';
      this.discountGroup.saleType = DiscountItemSaleType.SalePrice;
    }
  }
}
