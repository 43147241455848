<div class="report-data">
  <header *ngIf="activeReportConfig" class="page-header">
    <div *ngIf="canAdd" class="btn-add" (click)="add()"></div>
    <div *ngIf="canImport" class="btn-import" (click)="importRecords()">
      <input #importrecords class="invisible" type="file" name="importrecords" (change)="onImportFileChange($event)" />
    </div>

    <h1>
      {{activeReportConfig.name}}
      <span *ngIf="totals">({{totals.count | rf: 'number'}})</span>
      <span *ngIf="activeReportConfig.canUpdate" class="update-btn" (click)="updateReport()">
        <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M448 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm644-420l-682 682q-37 37-90 37-52 0-91-37l-106-108q-38-36-38-90 0-53 38-91l681-681q39 98 114.5 173.5t173.5 114.5zm634-435q0 39-23 106-47 134-164.5 217.5t-258.5 83.5q-185 0-316.5-131.5t-131.5-316.5 131.5-316.5 316.5-131.5q58 0 121.5 16.5t107.5 46.5q16 11 16 28t-16 28l-293 169v224l193 107q5-3 79-48.5t135.5-81 70.5-35.5q15 0 23.5 10t8.5 25z"/></svg>
    </span>
    </h1>
  </header>
  <div *ngIf="activeReportConfig" class="table-container">
      <div class="table-controls">
        <div *ngIf="activeReportConfig?.datePath" class="date-range">
          <div class="icon"></div>
          <label for="date-range">Date Range</label>
          <app-filter-date-range class="w-100" (rangeChanged)="dateRangeUpdated($event)" [rangeConfig]="dateRangeSettings"></app-filter-date-range>
          <!-- {{activeReportConfig | json}} -->
        </div>

        <div class="spacer"></div>
        <div *ngIf="availableFields && availableFields.length > 0" class="fields dropdown-button" (click)="toggleFieldAdd()" [class.open]="fieldAddOpen">
          <!-- <div class="icon"></div> -->
          <svg width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1472 930v318q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q63 0 117 25 15 7 18 23 3 17-9 29l-49 49q-10 10-23 10-3 0-9-2-23-6-45-6h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-254q0-13 9-22l64-64q10-10 23-10 6 0 12 3 20 8 20 29zm231-489l-814 814q-24 24-57 24t-57-24l-430-430q-24-24-24-57t24-57l110-110q24-24 57-24t57 24l263 263 647-647q24-24 57-24t57 24l110 110q24 24 24 57t-24 57z"/></svg>
          <label for="fields">Fields</label>
          <div class="field-add-container dropdown-menu">
            <a *ngFor="let availableField of availableFields" (click)="addField(availableField)">{{availableField.name}}</a>
          </div>
        </div>
        <div *ngIf="!totals || !totals.count || totals.count < 100000" class="search input">
          <div class="icon"></div>
          <label for="search">Search</label>
          <input type="text" id="search" placeholder="Search Report..." (keyup)="setSearch($event)">
        </div>

        <ng-container *ngIf="templateOptions && templateOptions.print">
          <div *ngFor="let printTemplate of templateOptions.print" class="print-options" (click)="printReport(printTemplate)">
            <div class="icon"></div>
            <label for="print">{{printTemplate.name}}</label>
          </div>
        </ng-container>

        <div *ngIf="templateOptions && templateOptions.list && templateOptions.list.length > 0" class="list-options" (click)="loadLists(templateOptions.list)">
          <div class="icon"></div>
          <label>Lists</label>
        </div>

        <div class="download" (click)="downloadReport()">
          <div class="icon"></div>
          <label for="download">Download</label>
        </div>
        <div class="refresh-report" (click)="refreshReport()">
          <div class="icon"></div>
        </div>
      </div>

      <div *ngIf="valueFilters && valueFilters.length > 0" class="value-filters">
        <div *ngFor="let valueFilter of valueFilters; let valueFilterIndex = index" class="value-filter">
          
          <ng-container *ngIf="!valueFilter.hidden && valueFilter.type !== 'link'">
            <div class="filter-name">
              {{valueFilter.name}}:
            </div>
            <div class="filter-value">
             {{valueFilter.value | rf:valueFilter.type}}
            </div>
            <div class="filter-remove" (click)="removeValueFilterIndex(valueFilterIndex)">x</div>
          </ng-container>

          <ng-container *ngIf="valueFilter.type == 'link'">
            <div class="filter-link" (click)="filterLink(valueFilter.value)">
              {{valueFilter.name}}
            </div>
          </ng-container>

        </div>
      </div>

      <div class="flex-table">
          <div class="flex-body">
              <div class="header-row-wrapper">
                  <div class="flex-row header-row" [style.margin-left.px]="tsl*-1" [dragula]="'fields'" [(dragulaModel)]="activeFields">
                      <div class="field sortable" *ngFor="let activeField of activeFields" (click)="setSort(activeField)" [class.asc]="activeField.sort == 1" [class.desc]="activeField.sort == -1" [style.flex-basis.px]="activeField.width">
                        {{activeField.name}}
                        <img src="/assets/global/icon-angle-right.svg" />
                        <div class="close" (click)="removeField($event, activeField)"></div>
                      </div>
                  </div>
              </div>
              <cdk-virtual-scroll-viewport style="position: absolute; top: 35px; bottom: 40px; left: 0; right: 0;" itemSize="37" (scrolledIndexChange)="reportScrolled($event)">
                  <!-- <div *ngIf="!(records)" class="loading">LOADING . . .</div> -->
                  <div *ngIf="records?.length === 0">No Results</div>

                  <ng-container *cdkVirtualFor="let record of records; trackBy: trackByIndex">
                    <div class="flex-row" [class.loading]="!record._id" [class.active]="record._id == activeDocumentId" (click)="setDetailDocumentId(record._id)" [style.width.px]="tableWidth">
                      <div *ngFor="let activeField of activeFields" class="field" [class]="activeField.type" [style.flex-basis.px]="activeField.width" (contextmenu)="onRightClick(record, activeField)">
                        <div *ngIf="activeField.type == 'string'; else unwrapped" class="single-line-text" [style.min-width.px]="activeField.width-30">
                          {{getValueByPath(record, activeField.path) | rf:activeField.type}}
                        </div>
                        <ng-template #unwrapped>
                          {{getValueByPath(record, activeField.path) | rf:activeField.type}}
                        </ng-template>
                      </div>
                      <div *ngIf="listBuilderActive" class="on-list-toggle" (click)="addToList($event, record._id)">
                        &gt;
                      </div>
                    </div>
                  </ng-container>
              </cdk-virtual-scroll-viewport>

              <div class="footer-row-wrapper">
                  <div class="flex-row footer-row" *ngIf="totals" [style.margin-left.px]="tsl*-1">
                      <div *ngFor="let activeField of activeFields" class="field" [class]="activeField.type" [style.flex-basis.px]="activeField.width">
                        {{getValueByPath(totals, activeField.path) | rf: activeField.type}}
                      </div>
                  </div>
              </div>

          </div>
      </div>

  </div>
</div>
<app-list-builder *ngIf="listBuilderActive"></app-list-builder>
<router-outlet></router-outlet>
