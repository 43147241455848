<div class="container">
    <div class="header">
        <div *ngIf="data.showClose" class="close" (click)="dismiss()"></div>
        <div class="stretcher"></div>
        <div class="title">{{data.title}}</div>
    </div>
    <div class="content">
        <div>{{data.message}}</div>
    </div>
    <div class="footer">
        <button class="button" *ngFor="let option of data.options"
            [class.red]="option.style === buttonStyle.Cancel"
            [class.green]="option.style===buttonStyle.Confirm"
            (click)="selectOption(option)">
            {{option.name}}
        </button>
    </div>
</div>