import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  open = false;
  reportCategories;

  reportsLoaded: Subscription;

  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.reportCategories = this.reportService.getReportCategories();

    this.reportsLoaded = this.reportService.reportsLoadedObservable().subscribe(reportsData => {
      this.reportCategories = reportsData.reportCategories;
    });
  }

  ngOnDestroy() {
    this.reportsLoaded.unsubscribe();
  }

  toggleMenu() {
    this.open = !this.open;
  }

  toggleCategory(category) {
    category.active = !category.active;
  }

  setActiveReport(report) {
    event.stopPropagation();
    this.open = false;
    // this.reportService.setActiveReport(report);
  }

}
