import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  templateId;
  template;
  preview;

  previewWidth = 320;
  previewWidthOptions = [
    600,
    620,
    800,
    1200,
    320,
    480
  ];
  testEmail = 'forrest@redfiredevelopment.com';

  changeTimer;

  constructor(
    private route: ActivatedRoute,
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.templateId = this.route.snapshot.paramMap.get('templateId');
    this.getTemplate();
  }

  ngOnDestroy(): void {
    if (this.changeTimer) {
      clearTimeout(this.changeTimer);
    }
  }

  getTemplate = async () => {
    this.template = await this.ioService.post('/template/getTemplate', {
      templateId: this.templateId
    });

    console.log('template: ', this.template);
    this.getPreview();
  }

  updateTemplate = async () => {
    await this.ioService.post('/template/updateTemplate', {
      template: this.template
    });
  }

  getPreview = async () => {
    this.preview = await this.ioService.post('/template/getPreview', {
      templateId: this.templateId,
      locationId: this.locationService.getActiveLocation()._id,
      ejs: this.template.ejs
    });

    return true;
  }

  sendTest = async () => {
    await this.ioService.post('/template/sendTestEmail', {
      templateId: this.templateId,
      locationId: this.locationService.getActiveLocation()._id,
      ejs: this.template.ejs,
      email: this.testEmail
    });

    return true;
  };

  templateChanged() {
    if (this.changeTimer) {
      clearTimeout(this.changeTimer);
    }
    this.changeTimer = setTimeout(() => {
      this.getPreview();
    }, 1000);
  }

  togglePreviewWidth() {
    let previewIndex = this.previewWidthOptions.indexOf(this.previewWidth);
    previewIndex++;
    if (previewIndex >= this.previewWidthOptions.length) {
      previewIndex = 0;
    }
    this.previewWidth = this.previewWidthOptions[previewIndex];
  }


}
