import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { NgxMaskModule } from 'ngx-mask';

import { InventoryComponent } from './inventory.component';



@NgModule({
  declarations: [
    InventoryComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ScrollingModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot()
  ]
})
export class InventoryModule { }
