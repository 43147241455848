import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  static showLoadingObs = new Subject<any>();
  static hideLoadingObs = new Subject<any>();

  loadingVisible = false;
  showLoadingSub: Subscription;
  hideLoadingSub: Subscription;

  constructor() { }

  static showLoading() {
    this.showLoadingObs.next();
  }

  static hideLoading() {
    this.hideLoadingObs.next();
  }

  ngOnInit(): void {
    this.showLoadingSub = LoadingComponent.showLoadingObs.subscribe( () => {
      this.loadingVisible = true;
    });
    this.hideLoadingSub = LoadingComponent.hideLoadingObs.subscribe( () => {
      this.loadingVisible = false;
    });
  }

  ngOnDestroy(): void {
    if ( this.showLoadingSub ) {
      this.showLoadingSub.unsubscribe();
    }
    if ( this.hideLoadingSub ) {
      this.hideLoadingSub.unsubscribe();
    }
  }
}
