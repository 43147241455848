import { DiscountGroupItem, DiscountItemSaleType } from "./discount-group-item";

export class DiscountGroup {
    public _id: string = null;
    public startDate: string = null;
    public endDate: string = null;
    public name: string = null;
    public items: DiscountGroupItem[] = null;
    public type: string = null;
    public subtype: string = null;
    public saleType: DiscountItemSaleType = null;
    public locationId: string[] = null;

    public static getProjection(): any {
        const o = {};
        Object.keys( new DiscountGroup() ).forEach((k) => o[k] = 1 );
        return o;
    }

    constructor() {
        this.items = new Array<DiscountGroupItem>();
    }

    public setValue(o: any) {
        Object.keys(o).forEach((k) => this[k] = o[k]);
        if ( this.items?.length > 0 ) {
            for ( let i = 0; i < this.items.length; i++ ) {
                this.items[i] = Object.assign(new DiscountGroupItem(), this.items[i]);
            }
        } else {
            this.items = new Array<DiscountGroupItem>();
        }
    }

    public stripped(): any {
        const o = {
            ... this
        };
        for ( let i = 0; i < this.items.length; i++ ) {
            o.items[i] = this.items[i].stripped();
        }
        return o;
    }
}
