<app-header></app-header>
<ng-container *ngIf="template">
  <div class="columns">
    <div class="general">
      <div class="header">Configuration</div>
      <div class="fields">
        <div class="field">
          <label>Template Name</label>
          <input type="text" [(ngModel)]="template.name" />
        </div>

      </div>
      <div>
        <input type="email" [(ngModel)]="testEmail" />
      </div>
      <div class="button-full yellow" (click)="sendTest()">Send Test</div>
      <div class="button-full green" (click)="updateTemplate()">Save Template Changes</div>
    </div>
    <div class="ejs">
      <div class="header">EJS</div>
      <textarea [(ngModel)]="template.ejs" (keyup)="templateChanged()"></textarea>
    </div>
    <div class="preview" style="flex: {{previewWidth}}px 0 0;">
      <div class="header" (click)="togglePreviewWidth()">Preview ({{previewWidth}}px)</div>
      <div class="preview-content-wrapper">
        <div class="preview-content" [innerHTML]="preview | rf: 'safeHtml'"></div>
      </div>
    </div>
  </div>
</ng-container>
