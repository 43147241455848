<div class="detail" [class.visible]="account">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Account</h2>
  </header>
  <section>
    <div *ngIf="!account" class="loading">Loading . . .</div>
    <div *ngIf="account">

      <h2>Transaction History</h2>
      <div *ngFor="let transaction of account.transactions">
        {{transaction.amount | rf: 'currency'}} : {{transaction.time | rf: 'dateTime'}} : {{transaction.locationName}}
        <a (click)="loadDetail('transaction', transaction._id)">Transaction</a>
        <a *ngIf="transaction.purchaseId" (click)="loadDetail('purchase', transaction.purchaseId)">Purchase</a>
      </div>
      <h3>Balance: {{account.balance | rf: 'currency'}}</h3>

    </div>
  </section>
</div>
