import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ClientService } from '../client/client.service';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IoService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private clientService: ClientService
  ) { }

  post(path: string, postData: any = {}) {
    return new Promise((resolve, reject) => {
      if (this.authenticationService.isSignedIn()) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token')
          })
        };

        if (!postData.rfId) {
          postData.rfId = this.clientService.getRfId();
        }

        if (path.substring(0, 1) === '/') {
          path = path.substr(1);
        }

        this.http.post<any>(this.clientService.getServerPath() + path, postData, httpOptions).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        reject('Expired authorization.');
      }
    });
  }

  upload(path: string, postData: any = {}, file) {
      if (!postData.rfId) {
        postData.rfId = this.clientService.getRfId();
      }

      // Build FormData object
      const formData = new FormData();
      formData.append('file', file);
      formData.append('data', JSON.stringify(postData));

      // Define post headers
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'rfId': postData.rfId
      });

      return this.http.post<any>(this.clientService.getServerPath() + path, formData, {
        headers,
        reportProgress: true,
        observe: 'events'
      });
  }

  download(path: string, postData: any = {}, filename: string = 'report.csv') {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isSignedIn()) {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        });

        if (!postData.rfId) {
          postData.rfId = this.clientService.getRfId();
        }

        if (path.substring(0, 1) == '/') {
          path = path.substr(1);
        }

        this.http.post<any>(this.clientService.getServerPath() + path, postData, {
          headers,
          responseType: 'blob' as 'json'
        })
          .toPromise()
          .then(response => {

            if (navigator.msSaveBlob) {
                 // IE 10+
                navigator.msSaveBlob(new Blob([response], { type: 'text/csv;charset=utf-8;' }), filename);
            } else {
                const saveByteArray = (function() {
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    // a.style = "display: none";
                    return function(data, name) {
                        const blob = new Blob(data, {type: 'octet/stream'});
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = name;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    };
                }());
                saveByteArray([response], filename);
            }

            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } else {
        reject('Expired authorization.');
      }
    });
  }

  print = async (path: string, postData: any = {}) => {
    let serverResponse:any = await this.post(path, postData);
    let printWindow = window.open("", "printWindow", "width=600,height=600");
    printWindow.document.write(serverResponse.rendered);
    setTimeout(() => {
      printWindow.print();
    }, 1000);
  }

  async logError(message: string) {
    try {
      await this.post('client/logError', {
        message,
      });
    } catch (err) {
      console.error('Failed to log error to server. ', message);
    }
  }
}
