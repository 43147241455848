import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { ClientService } from '../../../services/client/client.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  configCode;
  clientConfig;
  clientConfigChanged: Subscription;

  signInForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private clientService: ClientService
  ) {
    if (authenticationService.isSignedIn) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.clientConfigChanged = this.clientService.clientConfigChangedObservable().subscribe(clientConfig => {
      this.clientConfig = clientConfig;
      if (this.clientConfig && this.clientConfig.logoUrl) {
        console.log('UPDATE LOGO SOURCE');
        this.clientConfig.logoUrl = this.clientConfig.logoUrl.replace("white", "black");
      }
    });

    this.clientConfig = this.clientService.getConfig();
    if (this.clientConfig && this.clientConfig.logoUrl) {
      console.log('UPDATE LOGO SOURCE');
      this.clientConfig.logoUrl = this.clientConfig.logoUrl.replace("white", "black");
    }

    this.signInForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // Capture where the user was redirected from
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    if (this.clientConfigChanged) {
      this.clientConfigChanged.unsubscribe();
    }
  }

  get field() {
    return this.signInForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.error = null;

    if (this.signInForm.invalid) {
      this.error = 'Email/Phone and password are required.';
      return;
    }

    this.loading = true;

    // Reload client config
    this.clientService.requestClientConfig()
    .then( () => {} )
    .finally( () => {
      this.authenticationService.signin(this.field.email.value, this.field.password.value)
        .then(() => {
          console.log('router navigate to root');
          this.router.navigate(['/']);
        })
        .catch(signinError => {
          this.error = signinError.error.msg;
        });
      });
  }

  requestClientConfig() {
    this.clientService.requestClientConfig(this.configCode);
  }

  resetClientConfig() {
    this.clientService.resetClientConfig();
  }

}
