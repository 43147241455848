import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-value-compare',
  templateUrl: './value-compare.component.html',
  styleUrls: ['./value-compare.component.scss']
})
export class ValueCompareComponent implements OnInit {

  @Input() fromValue: string;
  @Input() toValue: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private jsonPipe: JsonPipe,
  ) {
    this.fromValue = data.fromValue;
    this.toValue = data.toValue;
  }

  ngOnInit(): void {
    this.fromValue = this.jsonPipe.transform(this.fromValue);
    this.toValue = this.jsonPipe.transform(this.toValue);
  }
}
