import { DropdownOption } from '../option-interfaces';
import { DiscountPurchaseQualifier } from './discount-purchase-qualifier';
import { DiscountItemQualifier, DiscountItemQualified } from './discount-qualifiers';

export class Discount {
    _id: any;
    active: boolean;
    name: string;
    description: string;
    type: string;
    subtype: string;
    locationId: Array<string>;
    categoryId: string;
    groupId: Array<string>;
    autoApply: boolean;
    variableAmount: boolean;
    displayOnItem: boolean;
    maxPurchaseApplications: number;
    purchaseQualifier: DiscountPurchaseQualifier;
    itemQualifier: DiscountItemQualifier;
    itemQualified: DiscountItemQualified;
    barcode: string;
    selection: Array<DropdownOption>;    // Current dropdown selection lookup sent from API

    constructor() {
        this.purchaseQualifier = new DiscountPurchaseQualifier();
        this.itemQualifier = new DiscountItemQualifier();
        this.itemQualified = new DiscountItemQualified();
    }

    toJSON(param) {
        const retObj = Object.assign( new Discount(), this ) as Discount;
        delete retObj.selection;
        retObj.itemQualifier.filters.forEach((f) => {
            delete f.selectedValue;
        });
        retObj.itemQualified.filters.forEach((f) => {
            delete f.selectedValue;
        });
        return retObj;
    }
}

export class DiscountSubtype {
    key: string;
    displayName: string;
}
