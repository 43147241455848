import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-detail-form',
  templateUrl: './detail-form.component.html',
  styleUrls: ['./detail-form.component.scss']
})
export class DetailFormComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  form;
  activeVersionIndex;
  activeVersion;
  editable;

  savingForm;

  noteText;
  savingNote;

  private permissionsLoaded: Subscription;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService,
    private authorizationService: AuthorizationService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;

        if (this.authorizationService.permissionsAreLoaded()) {
          this.loadFormDetail();
        } else {
          this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
            this.loadFormDetail();
          });
        }

      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  ngOnDestroy() {
    if (this.permissionsLoaded) {
      this.permissionsLoaded.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.location.back();
  }

  loadDetail(type, id) {
    this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
  }

  loadFormDetail() {
    console.log('loadFormDetail: ');
    this.form = null;
    this.editable = null;
    this.savingNote = null;
    this.noteText = null;
    this.activeVersionIndex = null;

    this.ioService.post('/form/getDetails', {
      formId: this.documentId
    }).then((formResponse: any) => {
      console.log('formResponse: ', formResponse);
      formResponse.form.inputs = {};
      this.form = formResponse.form;

      // Determine if this form is editable
      this.form.canSubmit = this.authorizationService.checkPermission('location.form.submit', this.form.locationId);
      this.form.canApprove = this.authorizationService.checkPermission('location.form.approve', this.form.locationId);

      // If status is available or user can location.form.approve
      if ((this.form.canApprove && this.form.status != 'accepted') || ((this.form.status == 'available' || this.form.status == 'rejected') && this.form.canSubmit)) {
        this.editable = true;

        this.form.inputs = {
          square: (this.form.square/100).toFixed(2),
          paypal: (this.form.paypal/100).toFixed(2),
          credit: (this.form.credit/100).toFixed(2),
          cash: (this.form.cash/100).toFixed(2),
          onlineCount: this.form.onlineCount,
          gift: (this.form.gift/100).toFixed(2)
        };

        this.updateCalc();
      }
    });
  }

  updateCalc() {
    console.log('updateCalc: ');
    // Translate input values into object values
    this.form.square = this.toInt(this.form.inputs.square);
    console.log('square: ', this.form.square);
    this.form.paypal = this.toInt(this.form.inputs.paypal);
    this.form.credit = this.toInt(this.form.inputs.credit);
    console.log('credit: ', this.form.credit);
    this.form.cash = this.toInt(this.form.inputs.cash);
    this.form.onlineCount = this.form.inputs.onlineCount;
    this.form.gift = this.toInt(this.form.inputs.gift);

    if (!this.form.ise) {
      this.form.ise = 0;
    }


    // Calculate Online
    this.form.online = this.form.onlineCount * (this.form.onlineSignPrice + this.form.onlineSignShipping);

    this.form.onlineSignNoShip = this.form.onlineCount * this.form.onlineSignPrice;

    // Calculate Gross Sales Total
    this.form.salesTotal = this.form.ise + this.form.square + this.form.paypal + this.form.credit + this.form.cash + this.form.gift + (this.form.onlineCount * this.form.onlineSignPrice);

    // Calculate Online Shop Sales Total
    this.form.onlineTotal = this.form.gift + this.form.online;

    // Calculate Royalty
    this.form.royalty = Math.ceil(this.form.salesTotal * this.form.royaltyRate);

    // Calculate Royalty Total
    this.form.royaltyTotal = this.form.royalty - this.form.onlineTotal;

    // If they are exempt from royalties, then they do not get a credit for online sales
    if (this.form.royaltyRate == 0) {
      this.form.royaltyTotal = 0;
    }

    // Calculate Brand Fund Total
    this.form.brandFund = Math.ceil(this.form.salesTotal * this.form.brandFundRate);

    // Calculate Grand Total
    this.form.grandTotal = this.form.royaltyTotal + this.form.brandFund;

  }

  toInt(inputValue) {
    if (inputValue == null || inputValue == '') {
      inputValue = 0;
    }
    inputValue = String(inputValue).replace(/,/g, '');
    inputValue = parseFloat(inputValue);
    return Math.round(inputValue*100);
  }

  showVersion(versionIndex) {
    this.activeVersionIndex = versionIndex;
    this.activeVersion = this.form.history[this.activeVersionIndex];
  }

  saveMonthEnd() {
    console.log('save');
    this.savingForm = true;
    this.ioService.post('/boardandbrush/saveMonthEndCombined', {
      form: this.form,
      action: 'save'
    }).then((saveResponse: any) => {
      console.log('saved form');
      this.savingForm = false;
      this.loadFormDetail();
      this.reportService.refresh();
    });
  }

  submitMonthEnd() {
    console.log('submit');
    this.savingForm = true;
    this.ioService.post('/boardandbrush/saveMonthEndCombined', {
      form: this.form,
      action: 'submit'
    }).then((saveResponse: any) => {
      console.log('submitted form');
      this.savingForm = false;
      this.loadFormDetail();
      this.reportService.refresh();
    });
  }

  acceptMonthEnd() {
    console.log('accept');
    this.savingForm = true;
    this.ioService.post('/boardandbrush/saveMonthEndCombined', {
      form: this.form,
      action: 'accept'
    }).then((saveResponse: any) => {
      console.log('accepted form');
      this.savingForm = false;
      this.loadFormDetail();
      this.reportService.refresh();
    });
  }

  rejectMonthEnd() {
    console.log('submit');
    this.savingForm = true;
    this.ioService.post('/boardandbrush/saveMonthEndCombined', {
      form: this.form,
      action: 'reject'
    }).then((saveResponse: any) => {
      console.log('rejected form');
      this.savingForm = false;
      this.loadFormDetail();
      this.reportService.refresh();
    });
  }

  saveNote() {
    console.log('save note');
    this.savingNote = true;
    this.ioService.post('/boardandbrush/addMonthEndNote', {
      formId: this.form._id,
      text: this.noteText
    }).then((saveResponse: any) => {
      console.log('note saved');
      this.noteText = null;
      this.savingNote = false;
      this.form.notes.push(saveResponse.note);
    });
  }

}
