
<div class="grow">
  <section class="conversations" [class.conversation-selected]="conversationSelected">
    <div *ngFor="let conversation of conversations" [class.active]="conversation._id == conversationSelected" (click)="selectConversation(conversation._id)">
      <div class="time">{{conversation.lastTime | rf: 'dateTime'}}</div>
      <div class="customer">{{conversation.customer.firstName}} {{conversation.customer.lastName}}</div>
      <div class="body">{{conversation.lastBody}}</div>
    </div>
  </section>
  <section *ngIf="conversation" class="conversation" [class.conversation-selected]="conversationSelected">
    <div class="customer">
      <div class="back" (click)="closeConversation()"></div>
      <div class="name">{{conversation.customer.firstName}} {{conversation.customer.lastName}}</div>
    </div>
    <div class="messages" #messages>
      <div *ngFor="let message of conversation.messages" class="message {{message.direction}}">
        <div class="body">{{message.body}}</div>
        <div class="time">{{message.fromName}} at {{message.time | rf: 'dateTime'}} {{message.locationName}}</div>
      </div>
    </div>
    <div *ngIf="location._id" class="new-message">
      <textarea #message name="message" [(ngModel)]="messageBody" (keydown)="messageKeydown($event)"></textarea>
      <div class="send-container" (click)="send()">
        <div class="send">Send</div>
      </div>
    </div>
  </section>
</div>
<header>
  <div class="close" (click)="close()"></div>
  <h2>Messaging: {{location.name}}</h2>
</header>
