<section class="gate">
  <div class="tinter">
    <div class="gate-container">
        <div class="gate-content">
            <div class="gate-logo">
                <!-- <img src="{{clientConfig.logoUrl}}" alt="{{clientConfig.name}}" draggable="false" /> -->
            </div>
            <h1 class="gate-title">Reset Password</h1>
            <div *ngIf="!done">
                <div class="input">
                    <label for="userKey" class="hidden">Email or Phone</label>
                    <input type="text" placeholder="Email or Phone" (keyup)="updateUserKey($event)" required>
                </div>
                <div class="error">{{error}}</div>
                <div class="form-actions right">
                    <a [routerLink]="['/sign-in']">Sign In</a>
                    <button class="button" [class.disabled]="!validInput || processing" (click)="sendResetRequest()">Reset Password</button>
                </div>
            </div>
            <div class="done" *ngIf="done">
              <div class="msg">Password reset sent to {{userKey}}.</div>
              <div>You may now close this window or <a [routerLink]="['/sign-in']">sign in</a>.</div>
            </div>
        </div>
    </div>

    <div class="powered-by">
        powered by
        <img src="assets/global/logo-red-fire.svg" alt="Red Fire Technology" draggable="false">
    </div>
  </div>
</section>
