import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountGroup } from 'src/app/modules/shared/interfaces/discounts/discount-group';
import { DiscountGroupItem } from 'src/app/modules/shared/interfaces/discounts/discount-group-item';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from 'src/app/modules/shared/interfaces/option-interfaces';
import { IoService } from 'src/app/services/io/io.service';

@Component({
  selector: 'app-discount-group-category',
  templateUrl: './discount-group-category.component.html',
  styleUrls: ['./discount-group-category.component.scss']
})
export class DiscountGroupCategoryComponent implements OnInit {

  @Input() groupSubtype = 'group';
  @Input() discountGroup: DiscountGroup;
  @Output() discountGroupChange = new EventEmitter<DiscountGroup>();

  activeItems: Array<DiscountGroupItem>;
  statusText = '';

  dropdownPlaceholder = '';
  groupDropdown: DropdownOptionGroup = {
    key: '',
    name: ' ',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.None,
  };

  constructor( private ioService: IoService ) { }

  ngOnInit(): void {
    this.refresh();
  }

  async newItemSelected() {
    if ( this.groupDropdown.selection?.length > 0 ) {
      const newItem = new DiscountGroupItem();
      newItem.active = true;
      newItem.accountId = this.groupDropdown.selection[0]._id;
      newItem.name = this.groupDropdown.selection[0].name;
      newItem.saleType = this.discountGroup.saleType;
      this.groupDropdown.selection = new Array<DropdownOption>();
      if ( this.groupSubtype === 'group' ) {
        newItem.filterKey = 'groupId';
      } else {
        newItem.filterKey = 'categoryId';
      }

      this.discountGroup.items.push( newItem );
      this.activeItems.push( newItem );
      this.discountGroupChange.emit(this.discountGroup);
    }
  }

  public refresh() {
    if ( this.groupSubtype === 'group' ) {
      this.dropdownPlaceholder = 'Add Group';
      this.groupDropdown.key = 'groupId';
    } else {
      this.dropdownPlaceholder = 'Add Category';
      this.groupDropdown.key = 'categoryId';
    }
    this.activeItems = this.discountGroup.items.filter((i) => i.filterKey === this.groupDropdown.key && i.active);
  }

  async deleteItem( item: DiscountGroupItem ) {
    item.active = false;
    this.activeItems.splice(this.activeItems.indexOf(item), 1);
    this.discountGroupChange.emit(this.discountGroup);
  }

  async searchGroups( searchText: string ) {
    const searchParameters: any = {
      idField: this.groupDropdown.key,
      type: 'inventory',
      search: searchText,
      limit: 10,
      omit: this.activeItems.map( i => i.accountId )
    };

    this.groupDropdown.options = (await this.ioService.post('/discount/searchOptions', searchParameters) as any[])
                                                      .map( o => Object.assign(new DropdownOption(o._id, o.name), o));
  }
}
