<div [class.date-relative-skewed]="skewed">
  <input *ngIf="rangeConfig.dateConfig" 
    ngxDaterangepickerMd
    [(ngModel)]="rangeConfig.dateRange"
    [showCustomRangeLabel]="rangeConfig.dateConfig.showRanges && rangeConfig.dateConfig.ranges != null"
    [ranges]="rangeConfig.dateConfig.showRanges ? rangeConfig.dateConfig.ranges : null"
    [timePicker]="rangeConfig.dateConfig.includeTime"
    [showDropdowns]="false"
    [singleDatePicker]="rangeConfig.dateConfig.singleDate"
    [autoApply]="rangeConfig.dateConfig.autoApply"
    [closeOnAutoApply]="rangeConfig.dateConfig.closeOnAutoApply"
    (datesUpdated)="datesUpdated($event)"
    [opens]="rangeConfig.dateConfig.opens"
    class="form-control"
    type="text"
    autocomplete=”off” />
  <div *ngIf="rangeConfig.dateConfig.label" class="date-label">{{rangeConfig.dateConfig.label}}</div>
</div>