import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription} from 'rxjs';

import { HistoryService } from '../../../services/history/history.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {

  showBackButton;

  conversationActive;
  conversationActiveUpdated: Subscription;

  conversations;
  messagesUpdated: Subscription;

  constructor(
    private location: Location,
    private historyService: HistoryService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });
    this.conversationActive = this.messageService.getConversationActive();

    // console.log('ready to load conversations');
    this.messageService.loadConversations().then(conversations => {
      this.conversations = conversations;

      // Subscribe to new messages
      this.messagesUpdated = this.messageService.messagesUpdatedObservable().subscribe(messages => {
        this.conversations = this.messageService.getConversations();
      });

      this.messageService.monitorMessages(true);
    });
  }

  ngOnDestroy() {
    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
  }

  close() {
    // let pathArray = this.router.url.split("/");
    // console.log(pathArray);
    // this.router.navigate([pathArray[1], pathArray[2], 'conversations']);

    this.router.navigate(['../'], { relativeTo: this.route });
  }

  back() {
    this.location.back();
  }

  loadConversation(customerId) {
    this.messageService.loadConversation(customerId);
  }

}
