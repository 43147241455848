import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorPickerService } from 'ngx-color-picker';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { HistoryService } from 'src/app/services/history/history.service';
import { IoService } from 'src/app/services/io/io.service';
import { LocationService } from 'src/app/services/location/location.service';
import { ReportService } from 'src/app/services/report/report.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';

@Component({
  selector: 'app-detail-menu-action',
  templateUrl: './detail-menu-action.component.html',
  styleUrls: ['./detail-menu-action.component.scss']
})
export class DetailMenuActionComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  action;
  saving;
  errors = [];

  groupDropdown: DropdownOptionGroup =
  {
    key: 'groupId',
    name: 'Action Group',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.One,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private historyService: HistoryService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadActionDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadActionDetail() {
    this.action = null;
    this.ioService.post('/menu/getAction', {
      actionId: this.documentId
    }).then((action: any) => {
      console.log('actionResponse: ', action);
      this.action = action;
      this.groupDropdown.selection = new Array<DropdownOption>();
      if ( this.action.selectedGroup ) {
        this.groupDropdown.selection.push(
          Object.assign( new DropdownOption( this.action.selectedGroup._id, this.action.selectedGroup.name ), this.action.selectedGroup)
        );
      }
    });
  }

  saveAction() {
    if (!this.saving) {
      console.log('save action');
      this.saving = true;

      const updateAction = JSON.parse(JSON.stringify(this.action));
      updateAction.groupId = this.groupDropdown.selection[0]?._id;
      this.ioService.post('/menu/updateAction', {
        action: updateAction
      }).then((updateResponse: any) => {
        console.log('updateResponse: ', updateResponse);
        this.saving = false;
        this.reportService.loadReport(0);
        this.close();
      });
    }
  }

  async searchGroups( searchText: string ) {
    const groupResults = await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'menu',
      subtype: 'action',
      anyLocation: true,
      search: searchText,
      limit: 10,
      omit: [this.groupDropdown.selection[0]?._id]
    }) as Array<any>;

    this.groupDropdown.options = groupResults.map((r) => Object.assign( new DropdownOption( r._id, r.name ), r));
  }
}
