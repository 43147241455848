import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  sendTimes = [
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM'
  ];

  charLimit = 160;

  marketing;

  activeLocationSet: Subscription;

  constructor(
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.getMarketingSettings();
    });
    this.getMarketingSettings();
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['marketing', 'dashboard']);
  }

  getMarketingSettings() {
    this.marketing = null;
    let activeLocation = this.locationService.getActiveLocation();

    if (activeLocation) {

      this.ioService.post('/marketing/getSettings', {
        locationId: activeLocation._id
      }).then((settingsResponse: any) => {
        console.log('settingsResponse: ', settingsResponse);
        let marketing = settingsResponse.marketing;

        if (!marketing) {
          marketing = {
            birthday: {
              active: true,
              content: 'Happy Birthday!',
              sendTime: '11:30 AM'
            },
            anniversary: {
              active: false,
              content: '',
              sendTime: '11:30'
            }
          };
        }

        this.marketing = marketing;
        console.log(this.marketing);
      });
    }
  }

}
