import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';
import { AuditComponent } from '../../shared/audit/audit.component';

@Component({
  selector: 'app-detail-inventory',
  templateUrl: './detail-inventory.component.html',
  styleUrls: ['./detail-inventory.component.scss']
})
export class DetailInventoryComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  account;
  accounts;
  showTaxControl = false;
  reportAuthorizations = {
    dependentItems: false,
    descendantItems: false,
  };

  optionFields: Array<DropdownOptionGroup> = [
    {
      key: 'locationId',
      name: 'Available at Locations',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    },
    {
      key: 'categoryId',
      name: 'Category',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.One,
    },
    {
      key: 'groupId',
      name: 'Group(s)',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    },
    {
      key: 'taxId',
      name: 'Taxes',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    }
  ];

  accountOptionField: DropdownOptionGroup = {
    key: 'options',
    name: 'Options',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.None
  };

  permissions = {
    canViewAudit: false,
  };

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private reportService: ReportService,
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadAccount();
      }
    });
    if ( this.authorizationService.checkPermission('Developer', 'general', true)) {
      this.permissions.canViewAudit = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadAccount = async () => {
    console.log('loadInventoryDetail: ');
    this.account = null;

    const account: any = await this.ioService.post('/inventory/getInventoryAccount', {
      accountId: this.documentId
    });

    for (const actId of Object.keys(account.accounts)) {
      if (!account.accounts[actId].calculatedCost && !account.accounts[actId].cost) {
        account.accounts[actId].cost = 0;
      }
    }

    this.accounts = account.accounts;

    // Define Required fields
    if (!account.taxId) {
      account.taxId = [];
    }

    if (!account.locationId) {
      account.locationId = [];
    }

    if (!Array.isArray(account.barcode)) {
      account.barcode = [account.barcode];
    }

    const barcodeObjects = [];
    for (const barcode of account.barcode) {
      barcodeObjects.push({
        value: barcode
      });
    }
    account.barcode = barcodeObjects;

    // Temporary Conversions
    account.price = (account.price / 100).toFixed(2);
    account.cost = (account.cost / 100).toFixed(4);
    if (account.options) {
      for (const option of account.options) {
        this.updateOptionGroupCosts(option, false);
        if (option.options) {
          for (const suboption of option.options) {
            if (suboption.price) {
              suboption.price = (suboption.price / 100).toFixed(2);
            }
          }
        }
      }
    }

    this.buildDropdown( 'locationId', account.authorizedLocations, account.locationId);
    this.buildDropdown( 'taxId', account.authorizedTaxes, account.taxId);
    this.buildDropdown( 'groupId', account.authorizedGroups, account.groupId);
    this.buildDropdown( 'categoryId', [account.category], account.categoryId);

    account.views = [
      {
        name: 'Configuration',
        key: 'configuration'
      }
    ];
    if ( this.permissions.canViewAudit ) {
      account.views.push( {name: 'Audit', key: 'audit' } );
    }

    this.account = account;
    this.showTaxControl = (account.subtype !== 'selector' && account.subtype !== 'vendor');

    this.updateAccountCosts();

    // TODO: Bind this to the auth state
    setTimeout(() => {
      this.getAuthorizedReports();
    }, 2000);
    
  }

  getAuthorizedReports() {
    this.reportAuthorizations.dependentItems = this.authorizationService.checkPermission('report.'+this.reportService.getReportIdByKey('dependent-items'));
    this.reportAuthorizations.descendantItems = this.authorizationService.checkPermission('report.'+this.reportService.getReportIdByKey('descendant-items'));
  }

  buildDropdown( key: string, rootOptions: any, rootSelections: any ) {
    const optionField = this.optionFields.find( (f) => f.key === key );
    if ( !optionField ) {
      return;
    }
    optionField.options = new Array<DropdownOption>();
    optionField.selection = new Array<DropdownOption>();
    for (const opt of rootOptions) {
      if ( !opt ) {
        continue;
      }
      const newOption = Object.assign(new DropdownOption( opt._id, opt._name ), opt );
      if (rootSelections && (Array.isArray(rootSelections) ? rootSelections.indexOf(opt._id) !== -1 : rootSelections === opt._id)) {
        optionField.selection.push( newOption );
      } else {
        optionField.options.push( newOption );
      }
    }
    rootOptions = null;
  }

  save = async (redirectAfter = true) => {
    this.account.errors = [];

    // Run validation
    if (!this.account.name) {
      this.account.errors.push('Name is required.');
    }

    if (!this.account.locationId || this.account.locationId.length === 0) {
      this.account.errors.push('You must select at least one location.');
    }

    // Create a clone of the account object
    const account = JSON.parse(JSON.stringify(this.account));

    this.updateDropdownKeys( account );

    // Remove Temporary Fields
    account.accounts = null;
    account.category = null;
    account.authorizedLocations = null;
    account.authorizedTaxes = null;
    account.authorizedGroups = null;
    account.views = null;
    account.activeOptionGroup = null;
    account.margin = null;
    account.marginPercent = null;

    let simpleBarcodes = [];
    if (account.barcode) {
      for (const barcode of account.barcode) {
        simpleBarcodes.push(barcode.value);
      }

      if (simpleBarcodes.length === 1) {
        simpleBarcodes = simpleBarcodes[0];
      }
      account.barcode = simpleBarcodes;
    }

    // Reverse Temporary Conversions
    if (!account.price || isNaN(account.price)) {
      account.price = null;
    } else {
      account.price = account.price * 100;
    }
    if (!account.cost || isNaN(account.cost)) {
      account.cost = null;
    } else {
      account.cost = account.cost * 100;
    }

    if (!account.options || account.options.length == 0) {
      account.calculatedCost = null;
    }

    if (account.options) {
      for (const option of account.options) {
        if (option.options) {
          account.cost = 0;
          for (const suboption of option.options) {
            if (!suboption.price || isNaN(suboption.price) || account.subtype == 'selector') {
              suboption.price = null;
            } else {
              suboption.price = suboption.price * 100;
            }
          }
        }
      }
    }

    if (this.account.active && this.account.errors.length > 0) {
      return false;
    }

    await this.ioService.post('/account/updateAccount', {
      account
    });

    if (redirectAfter) {
      this.reportService.loadReport(null);
      if (this.account.subtype === 'component') {
        this.back();
      } else {
        this.close();
      }
    }

    return true;
  }

  updateDropdownKeys( account: any ) {
    for ( const field of this.optionFields ) {
      if ( field.selectMode === DropdownSelectionMode.One ) {
        account[field.key] = field.selection[0]?._id;
      } else {
        account[field.key] = field.selection.map((s) => s._id);
      }
    }
  }

  deleteAccount = async () => {
    try {
      const deleteResponse = await this.ioService.post('/inventory/deleteInventoryAccount', {
        accountId: this.account._id
      });

      setTimeout(() => {
        this.reportService.loadReport(null);
        this.close();
      }, 500);
    } catch (e) {
      // console.log('delete error: ', e.error);
      this.account.deleteError = e.error;
    }
  }

  viewDependentAccount = async (accountId) => {
    this.router.navigate(['../', accountId], { relativeTo: this.route });
  }

  viewVendorAccount = async (accountId) => {
    this.router.navigate(['../../vendor-product/', accountId], { relativeTo: this.route });
  }

  viewDiscountAccount = async (accountId) => {
    this.router.navigate(['../../discount/', accountId], { relativeTo: this.route });
  }

  cloneAccount = async () => {
    await this.save(false);

    // Temporary fix because account is being cloned before the save is complete
    await this.sleep(500);

    const newItem: any = await this.ioService.post('/account/cloneAccount', {
      accountId: this.account._id
    });

    this.reportService.loadReport(0);

    this.router.navigate(['../', newItem._id], { relativeTo: this.route });
  }

  subtypeChanged() {
    console.log('subtypeChanged: ');
    if (this.account.subtype === 'component') {
      this.account.viewOptions = false;
    }
  }

  addBarcode() {
    this.account.barcode.push({
      value: ''
    });
  }

  removeBarcode(barcodeIndex) {
    this.account.barcode.splice(barcodeIndex, 1);
  }

  async search( optionGroup: DropdownOptionGroup, searchText: string ) {
    let searchResults: Array<any>;
    const selection = optionGroup.selection.map( (s) => s._id );
    switch ( optionGroup.key ) {
      case 'locationId':
        searchResults = await this.searchLocations( selection, searchText );
        break;
      case 'groupId':
        searchResults = await this.searchGroups( selection, searchText );
        break;
      case 'categoryId':
        searchResults = await this.searchCategories( selection, searchText );
        break;
      case 'taxId':
        searchResults = await this.searchTaxes( selection, searchText );
        break;
    }
    optionGroup.options = searchResults.map((r) => Object.assign( new DropdownOption( r._id, r.name), r));
  }

  async searchLocations(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/location/getLocations', {
      active: true,
      authorizationKey: 'account.inventory.manage',
      type: 'retail',
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  async searchTaxes(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/account/getAccounts', {
      active: true,
      type: 'tax',
      locationId: this.account.locationId,
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  async searchCategories(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      subtype: 'category',
      locationId: this.account.locationId,
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  async searchGroups(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      subtype: 'group',
      locationId: this.account.locationId,
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  toggleBoolean(toggleTarget) {
    this.account[toggleTarget] = !this.account[toggleTarget];
  }

  toggleViewOptions() {
    this.account.viewOptions = !this.account.viewOptions;
  }

  toggleDetailView(viewKey) {
    if (this.account.activeView === viewKey) {
      this.account.activeView = null;
    } else {
      this.account.activeView = viewKey;
    }
  }

  setActiveOptionGroup(optionGroup) {
    this.account.activeOptionGroup = optionGroup;
  }

  addOptionGroup() {
    if (!this.account.options) {
      this.account.options = [];
    }

    const accountOption = {
      active: true,
      type: 'components',
      name: 'Components',
      key: Date.now().toString(),
      options: [],
    };

    switch (this.account.subtype) {
      case 'selector':
      case 'collection':
        accountOption.type = 'select';
        accountOption.name = 'Options';
        break;
    }

    this.updateOptionGroupCosts(accountOption);

    this.account.options.push(accountOption);

    this.setActiveOptionGroup(this.account.options[this.account.options.length - 1]);
  }

  removeOptionGroup(optionIndex) {
    if (this.account.activeOptionGroup && this.account.options[optionIndex] === this.account.activeOptionGroup) {
      this.setActiveOptionGroup(null);
    }
    this.account.options.splice(optionIndex, 1);
  }

  async searchAccounts(searchText: string) {
    if (searchText && searchText.length > 2) {
      const accountParameters: any = {
        active: true,
        type: 'inventory',
        locationId: this.account.locationId,
        search: searchText,
        limit: 10,
        omit: this.account.activeOptionGroup.options.map( (s) => s.accountId ),
        returnCategoryName: true
      };

      if (this.account.subtype === 'selector') {
        accountParameters.subtype = 'retail';
      }

      accountParameters.omit.push(this.account._id);

      const accountResults = await this.ioService.post('/account/getAccounts', accountParameters) as Array<any>;
      this.accountOptionField.options = accountResults.map((r) => Object.assign( new DropdownOption(r._id, r.name), r));
    }
  }

  addNewAccount = async (name) => {
    const newAccountConfig = {
      type: 'inventory',
      locationId: this.account.locationId,
      subtype: 'component', // should be dynamic
      name,
      unitOfMeasure: 'EA'
    };

    switch (this.account.subtype) {
      case 'selector':
      case 'collection':
        newAccountConfig.subtype = 'product';
        break;
    }

    const account: any = await this.ioService.post('/account/addAccount', newAccountConfig);
    this.accounts[account._id] = account;
    this.addAccountOption(account);
  }

  addAccountOption( account ) {
    this.accounts[account._id] = account;
    this.account.activeOptionGroup.options.push( Object.assign( account, {
        active: true,
        accountId: account._id,
        name: account.shortName ?? account.name,
        qty: 1
      }));
  }

  updateAccountOptions = async () => {
    if ( this.accountOptionField.selection && this.accountOptionField.selection.length > 0 ) {
      this.addAccountOption( this.accountOptionField.selection[0]);
      this.accountOptionField.selection = new Array<DropdownOption>();
      this.updateAccountCosts();
    }
  }

  removeOption = async (optionIndex) => {
    this.account.activeOptionGroup.options.splice(optionIndex, 1);
  }
  viewOption = async (accountId) => {
    console.log('viewOption: ', accountId);
    await this.save(false);
    console.log(['../', accountId]);
    this.router.navigate(['../', accountId], { relativeTo: this.route });
  }

  optionQtyUpdated(option) {
    console.log('optionQtyUpdated: ', option);
    switch (this.account.activeOptionGroup.type) {
      case 'select':
      case 'multiple':
        if (option.default) {
          option.default = option.qty;
        }
        break;
    }

    this.updateOptionGroupCosts(this.account.activeOptionGroup);
  }

  optionToggleDefault(option, multiplier = 1) {
    console.log('optionToggleDefault: ', option);
    switch (this.account.activeOptionGroup.type) {
      case 'select':
        if (option.default) {
          option.default = null;
        } else {
          for (const tempOption of this.account.activeOptionGroup.options) {
            tempOption.default = null;
          }

          option.default = multiplier; // option.qty;
        }
        break;

      case 'multiple':
        if (option.default) {
          option.default = null;
        } else {
          option.default = multiplier; // option.qty;
        }
        break;

      case 'multipleMultiple':
        if (option.default) {
          option.default = null;
        } else {
          option.default = multiplier;
        }
        break;
    }

    this.updateOptionGroupCosts(this.account.activeOptionGroup);
  }

  optionPriceUpdated() {
    this.updateOptionGroupCosts(this.account.activeOptionGroup);
  }

  updateOptionGroupCosts(optionGroup, doUpdateAccountCosts = true) {
    console.log('updateOptionGroupCosts: ', optionGroup);

    if (!optionGroup.options || optionGroup.options.length === 0) {
      return;
    }

    // Update Costs
    let optionGroupCost = {
      uncoveredHigh: 0,
      high: 0,
      low: 0,
      default: 0
    };

    const optionCostStats = {
      uncoveredHigh: null,
      high: null,
      low: null,
      default: null
    };

    switch (optionGroup.type) {
      case 'components':
        // All Components and included in the cost
        for (const suboption of optionGroup.options) {
          if (!this.accounts[suboption.accountId].calculatedCost) {
            this.accounts[suboption.accountId].calculatedCost = {
              uncoveredHigh: this.accounts[suboption.accountId].cost,
              high: this.accounts[suboption.accountId].cost,
              low: this.accounts[suboption.accountId].cost,
              default: this.accounts[suboption.accountId].cost
            };
          }

          optionGroupCost.uncoveredHigh += suboption.qty * this.accounts[suboption.accountId].calculatedCost.uncoveredHigh;
          optionGroupCost.high += suboption.qty * this.accounts[suboption.accountId].calculatedCost.high;
          optionGroupCost.low += suboption.qty * this.accounts[suboption.accountId].calculatedCost.low;
          optionGroupCost.default += suboption.qty * this.accounts[suboption.accountId].calculatedCost.default;
        }
        break;

      case 'select':
        // Only one of the components will be included in the cost

        for (const suboption of optionGroup.options) {
          if (!this.accounts[suboption.accountId].calculatedCost) {
            this.accounts[suboption.accountId].calculatedCost = {
              uncoveredHigh: this.accounts[suboption.accountId].cost,
              high: this.accounts[suboption.accountId].cost,
              low: this.accounts[suboption.accountId].cost,
              default: this.accounts[suboption.accountId].cost
            };
          }

          // Check for new uncoveredHigh
          if ((!optionCostStats.uncoveredHigh || suboption.qty * this.accounts[suboption.accountId].calculatedCost.uncoveredHigh > optionCostStats.uncoveredHigh) && !suboption.price) {
            optionCostStats.uncoveredHigh = suboption.qty * this.accounts[suboption.accountId].calculatedCost.uncoveredHigh;
          }

          // Check for new high
          if (!optionCostStats.high || suboption.qty * this.accounts[suboption.accountId].calculatedCost.high > optionCostStats.high) {
            optionCostStats.high = suboption.qty * this.accounts[suboption.accountId].calculatedCost.high;
          }

          // Check for new low
          if (!optionCostStats.low || suboption.qty * this.accounts[suboption.accountId].calculatedCost.low < optionCostStats.low) {
            optionCostStats.low = suboption.qty * this.accounts[suboption.accountId].calculatedCost.low;
          }

          // Check for new default
          if (!optionCostStats.default || suboption.default) {
            optionCostStats.default = suboption.default * suboption.qty * this.accounts[suboption.accountId].calculatedCost.default;
          }
        }
        optionGroupCost = optionCostStats;
        break;

      case 'multiple':
        // Multiple components may be included in the cost
        let minCount = 0;
        let maxCount = 9999;
        if (optionGroup.min) {
          minCount = optionGroup.min;
        }
        if (optionGroup.max) {
          maxCount = optionGroup.max;
        }


        const lowValues = [];
        const highValues = [];
        const uncoveredHighValues = [];

        if (optionGroup.options && optionGroup.options.length > 0) {
          for (const suboption of optionGroup.options) {
            if (!this.accounts[suboption.accountId].calculatedCost) {
              this.accounts[suboption.accountId].calculatedCost = {
                uncoveredHigh: this.accounts[suboption.accountId].cost,
                high: this.accounts[suboption.accountId].cost,
                low: this.accounts[suboption.accountId].cost,
                default: this.accounts[suboption.accountId].cost
              };
            }

            lowValues.push(suboption.qty * this.accounts[suboption.accountId].calculatedCost.low);
            highValues.push(suboption.qty * this.accounts[suboption.accountId].calculatedCost.high);
            if (!suboption.price) {
              uncoveredHighValues.push(suboption.qty * this.accounts[suboption.accountId].calculatedCost.uncoveredHigh);
            }

            if (suboption.default) {
              optionCostStats.default += suboption.default * suboption.qty * this.accounts[suboption.accountId].calculatedCost.default;
            }
          }

          // Get low by totaling the minCount cheapest options
          if (minCount == 0) {
            optionCostStats.low = 0;
          } else {
            lowValues.sort((a, b) => a - b);
            console.log('lowValues: ', lowValues);
            optionCostStats.low = lowValues.slice(0, minCount).reduce((a, b) => a + b);
          }

          // Get high by totaling the maxCount most expensive options
          highValues.sort((a, b) => b - a);
          console.log('highValues: ', highValues);
          optionCostStats.high = highValues.slice(0, maxCount).reduce((a, b) => a + b);

          // Get uncoveredHigh
          uncoveredHighValues.sort((a, b) => b - a);
          console.log('uncoveredHighValues: ', uncoveredHighValues);
          if (uncoveredHighValues && uncoveredHighValues.length > 0) {
            optionCostStats.uncoveredHigh = uncoveredHighValues.slice(0, maxCount).reduce((a, b) => a + b);
          } else {
            optionCostStats.uncoveredHigh = null;
          }

        }

        optionGroupCost = optionCostStats;
        break;

      case 'multipleMultiple':
        if (optionGroup.options && optionGroup.options.length > 0) {
          for (const suboption of optionGroup.options) {
            if (!this.accounts[suboption.accountId].calculatedCost) {
              this.accounts[suboption.accountId].calculatedCost = {
                uncoveredHigh: this.accounts[suboption.accountId].cost,
                high: this.accounts[suboption.accountId].cost,
                low: this.accounts[suboption.accountId].cost,
                default: this.accounts[suboption.accountId].cost
              };
            }

            let minCount = 0;
            let maxCount = 9999;
            if (optionGroup.min) {
              minCount = optionGroup.min;
            }
            if (optionGroup.max) {
              maxCount = optionGroup.max;
            }

            // Check for new uncoveredHigh
            if ((!optionCostStats.uncoveredHigh || suboption.qty * this.accounts[suboption.accountId].calculatedCost.uncoveredHigh * maxCount > optionCostStats.uncoveredHigh) && !suboption.price) {
              optionCostStats.uncoveredHigh = suboption.qty * this.accounts[suboption.accountId].calculatedCost.uncoveredHigh * maxCount;
            }

            // Check for new high
            if (!optionCostStats.high || suboption.qty * this.accounts[suboption.accountId].calculatedCost.high * maxCount > optionCostStats.high) {
              optionCostStats.high = suboption.qty * this.accounts[suboption.accountId].calculatedCost.high * maxCount;
            }

            // Check for new low
            if (!optionCostStats.low || suboption.qty * this.accounts[suboption.accountId].calculatedCost.low * minCount < optionCostStats.low) {
              optionCostStats.low = suboption.qty * this.accounts[suboption.accountId].calculatedCost.low * minCount;
            }

            // Check for new default
            if (!optionCostStats.default || suboption.default) {
              optionCostStats.default = suboption.default * suboption.qty * this.accounts[suboption.accountId].calculatedCost.default;
              // TODO: Add the cheapest options remaining to hit the minimum of the optionGroup

            }
          }
        }
        optionGroupCost = optionCostStats;
        break;
    }

    optionGroup.calculatedCost = optionGroupCost;
    console.log('optionGroup.calculatedCost: ', optionGroup.calculatedCost);

    if (doUpdateAccountCosts) {
      this.updateAccountCosts();
    }
  }

  updateAccountCosts() {
    console.log('updateAccountCosts:');

    if (this.account.options && this.account.options.length > 0) {

      const accountCostStats = {
        uncoveredHigh: 0,
        high: 0,
        low: 0,
        default: 0
      };

      for (const option of this.account.options) {
        console.log(option.calculatedCost);

        if (!option.calculatedCost) {
          option.calculatedCost = {
            low: 0,
            high: 0,
            uncoveredHigh: 0,
            default: 0
          };
        }

        accountCostStats.low += option.calculatedCost.low;
        accountCostStats.high += option.calculatedCost.high;
        accountCostStats.uncoveredHigh += option.calculatedCost.uncoveredHigh;
        accountCostStats.default += option.calculatedCost.default;
      }

      this.account.calculatedCost = accountCostStats;

      this.account.margin = (this.account.price - (this.account.calculatedCost.high / 100))*100;
      console.log('account.calculatdCost: ', accountCostStats);
    } else {
      this.account.margin = (this.account.price - this.account.cost) * 100;
    }

    this.account.marginPercent = this.account.margin / this.account.price / 100;
  }

  // trackByIndex(index: number, value: number) {
  //   console.log("ngFor");
  //   console.log(index);
  //   console.log(value);
  //   return index;
  // }


  viewDependentItems() {
    this.reportService.loadFilteredReport('dependent-items', [
      {
        name: 'Items Containing '+this.account.name,
        type: 'link',
        value: this.account._id,
      },
      {
        name: 'Descendant Account Ids',
        path: 'descendantAccountIds',
        type: 'id',
        value: this.account._id,
        hidden: true,
      },
      {
        name: 'Exclude Self',
        path: '_id',
        type: 'idOmit',
        value: this.account._id,
        hidden: true,
      }
    ]);
  }

  viewDescendantItems() {
    this.reportService.loadFilteredReport('descendant-items', [
      {
        name: 'Items Contained In '+this.account.name,
        type: 'link',
        value: this.account._id,
      },
      {
        name: 'Descendant Account Ids',
        path: 'accountId',
        type: 'id',
        value: this.account._id,
        hidden: true,
      }
    ]);
  }

  viewAudit() {
    AuditComponent.showModal( 'Account', this.documentId );
  }

  viewTransferHistory() {
    this.reportService.loadFilteredReport('inventory-transactions', [
      {
        name: 'Transfer History of '+this.account.name,
        type: 'link',
        value: this.account._id,
      },
      {
        name: 'Account ID',
        path: 'accountId',
        type: 'id',
        value: this.account._id,
        hidden: true,
      }
    ]);
  }
}

