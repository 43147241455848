export class DialogConfig {
    public title: string;
    public message: string;
    public options: DialogOption[];
    public showClose = true;
    public height: string; // i.e. 500px, 20vw
    public width: string;
    constructor() {
        this.options = new Array<DialogOption>();
    }
}

export enum DialogButtonStyle {
    Normal = 1,
    Cancel,
    Confirm,
}

export class DialogOption {
    public name: string;
    public value: any;
    public style: DialogButtonStyle;

    constructor( n: string, v: any, s: DialogButtonStyle ) {
        this.name = n;
        this.value = v;
        this.style = s;
    }
}
