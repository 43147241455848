<app-header></app-header>
<div class="vt-body">

  <div *ngIf="!transaction" class="location-waiting">
    Please select a location.
  </div>

  <div *ngIf="transaction">
    <div class="selected-option">
      {{transaction.locationName}}
    </div>

    <ng-container *ngIf="!transaction.ledger && transaction.ledgers">
      <div class="options-header">Transaction Type</div>
      <ng-container *ngFor="let ledger of transaction.ledgers">
        <div *ngIf="ledger.type == 'gift' || ledger.type == 'credit'" class="button-full" (click)="setTransactionLedger(ledger)">{{ledger.name}}</div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="transaction.ledger">
      <div class="selected-option">
        {{transaction.ledger.name}}
      </div>

      <ng-container *ngIf="!transaction.action">
        <div class="options-header">What do you want to do?</div>
        <div *ngFor="let action of transaction.ledger.actions" class="button-full" (click)="setTransactionAction(action)">{{action.name}}</div>
      </ng-container>

      <ng-container *ngIf="transaction.action">
        <div class="selected-option">
          {{transaction.action.name}}
        </div>


        <!-- Transaction Type and Action are selected. Determine the necessary inputs -->
        <div *ngIf="transaction.ledger.subtypes && transaction.ledger.subtypes.length > 0 && transaction.action.key != 'check' && transaction.action.key != 'history'" class="field">
          <label>Subtype</label>
          <div>
            <select [(ngModel)]="transaction.subtype">
              <option [value]="undefined">-</option>
              <option *ngFor="let subtype of transaction.ledger.subtypes" [value]="subtype.key">{{subtype.name}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="transaction.action.key != 'check' && transaction.action.key != 'history'" class="field">
          <label>Amount</label>
          <div>
            {{transaction.ledger.amount.prefix}}<input class="amount" type="number" [(ngModel)]="transaction.amount" (ngModelChange)="amountChanged($event)" />{{transaction.ledger.amount.suffix}}
          </div>
        </div>

        <div *ngIf="transaction.ledger.account.selector == 'key' && !transaction.ledger.account.tokenizerUrl" class="field">
          <label>Card Number</label>
          <div>
            <input class="key" type="text" [(ngModel)]="transaction.key" (ngModelChange)="keyChanged($event)" (keyup.enter)="keyEntered()" />
          </div>
        </div>

        <div *ngIf="transaction.ledger.account.tokenizerUrl" class="field">
          <iframe id="rfoos" title="credit-card-payment" [src]="transaction.oosUrl" frameborder="0" height="195px" width="100%"></iframe>
        </div>

        <div *ngIf="transaction.action.key != 'history'" class="field">
          <label>Note</label>
          <div>
            <textarea [(ngModel)]="transaction.note" class="note"></textarea>
          </div>
        </div>

        <!-- <div *ngIf="transaction.action.customerIdAllowed" class="field">
          CUSTOMER SELECT
        </div> -->

        <ng-container>
          <div *ngIf="transaction.action.key == 'check'" class="button-full green" (click)="transact()">Check Balance</div>
          <div *ngIf="transaction.action.key == 'history'" class="button-full green" (click)="getHistory()">View History</div>
          <div *ngIf="transaction.action.key != 'check' && transaction.action.key != 'history'" class="button-full green" [class.disabled]="!transaction.valid || transaction.processing" (click)="transact()">{{transaction.ledger.amount.prefix}}{{transaction.amountDisplay}}{{transaction.ledger.amount.suffix}}</div>
        </ng-container>

        <div *ngIf="transaction.successCount">Success Count: {{transaction.successCount | rf:'number'}}</div>

        <div class="transaction-responses">
          <div class="transaction-response" *ngFor="let response of responses">
            
            <ng-container *ngIf="transaction.action.key == 'history'">
              <div>Transaction ID: <span>{{response._id}}</span></div>
              <div>Time: <span>{{response.time | rf:'dateTime'}}</span></div>
              <div *ngIf="response.locationName">Location: <span>{{response.locationName}}</span></div>
              <div *ngIf="response.action">Action: <span>{{response.action}}</span></div>
              <div *ngIf="response.amount">Amount: <span>{{response.amount | rf:'currency'}}</span></div>
              <div *ngIf="response.endBalance">Balance: <span>{{response.endBalance | rf:'currency'}}</span></div>
              <div *ngIf="response.lastFour">Last 4: <span>xxxx{{response.lastFour}}</span></div>
              <div *ngIf="response.msg">MSG: <span>{{response.msg}}</span></div>
            </ng-container>

            <ng-container *ngIf="transaction.action.key != 'history'">
              <div>Transaction ID: <span>{{response.transactionId}}</span></div>
              <div>Time: <span>{{response.time | rf:'dateTime'}}</span></div>
              <div *ngIf="response.amount">Amount: <span>${{response.amount}}</span></div>
              <div *ngIf="response.balance">Balance: <span>${{response.balance}}</span></div>
              <div *ngIf="response.lastFour">Last 4: <span>xxxx{{response.lastFour}}</span></div>
              <div *ngIf="response.msg">MSG: <span>{{response.msg}}</span></div>
            </ng-container>

          </div>
          <!-- <div class="transaction-response" *ngFor="let response of responses">{{response | json}}</div> -->
        </div>

        <div *ngIf="transaction.error" class="error">
          {{transaction.error.msg}}
        </div>





      </ng-container>

    </ng-container>








  </div>

<router-outlet></router-outlet>
