import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { LocationService } from '../../../services/location/location.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';

@Component({
  selector: 'app-detail-vendor-inventory',
  templateUrl: './detail-vendor-inventory.component.html',
  styleUrls: ['./detail-vendor-inventory.component.scss']
})
export class DetailVendorInventoryComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  account;

  optionFields: Array<DropdownOptionGroup> = [
    {
      key: 'categoryId',
      name: 'Inventory Category',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.One,
    },
    {
      key: 'locationId',
      name: 'Vendor',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    }
  ];

  accountDropdown: DropdownOptionGroup =
  {
    key: 'options',
    name: 'Add Component',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.None,
  };

  permissions = {
    canViewAudit: false,
  };

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService,
    private reportService: ReportService,
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadAccount();
      }
    });
    this.permissions.canViewAudit = this.authorizationService.checkPermission('Developer', 'general', true);
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadAccount = async () => {
    const account: any = await this.ioService.post('/inventory/getVendorProduct', {
      accountId: this.documentId
    });

    // Temporary Conversions
    account.price = (account.price / 100).toFixed(2);

    if (!account.options || account.options.length === 0) {
      account.options = [
        {
          key: new Date().getTime(),
          type: 'components',
          options: []
        }
      ];
    }
    this.account = account;
    this.loadDropdownSelections();

    account.views = [];
    if ( this.permissions.canViewAudit ) {
      account.views.push({name: 'Audit', key: 'audit' } );
    }
  }

  loadDropdownSelections() {
    const categoryDropdown = this.optionFields.find((f) => f.key === 'categoryId');
    if ( !categoryDropdown ) {
      console.error('Invalid category dropdown configuration.');
    } else {
      categoryDropdown.selection = new Array<DropdownOption>();
      if ( this.account.category ) {
        categoryDropdown.selection.push(
          Object.assign( new DropdownOption( this.account.category._id, this.account.category.name), this.account.category)
        );
      }
    }

    const vendorDropdown = this.optionFields.find((f) => f.key === 'locationId');
    if ( !vendorDropdown ) {
      console.error('Invalid vendor dropdown configuration.');
    } else {
      vendorDropdown.selection = new Array<DropdownOption>();
      for ( const l of this.account.selectedLocations ) {
          vendorDropdown.selection.push( new DropdownOption( l._id, l.name ));
      }
    }
  }

  deleteAccount = async () => {
    await this.ioService.post('/inventory/deleteVendorProduct', {
      accountId: this.documentId
    });

    this.reportService.loadReport(null);
    this.close();
  }

  cloneAccount = async () => {

  }

  saveAccount = async (redirectAfter = true) => {
    this.updateDropdownKeys();
    const account = JSON.parse(JSON.stringify(this.account));

    // Reverse Temporary Conversions
    delete account.accounts;
    delete account.category;
    delete account.active;
    delete account.balance;
    delete account.imported;
    delete account.ip;
    delete account.locked;
    delete account.locationName;

    if (!account.price || isNaN(account.price)) {
      account.price = null;
    } else {
      account.price = account.price * 100;
    }

    await this.ioService.post('/inventory/updateVendorProduct', {
      account
    });

    if (redirectAfter) {
      this.reportService.loadReport(null);
      this.close();
    }
  }

  updateDropdownKeys() {
    for ( const field of this.optionFields ) {
      if ( field.selectMode === DropdownSelectionMode.One ) {
        this.account[field.key] = field.selection[0]?._id;
      } else {
        this.account[field.key] = field.selection.map((s) => s._id);
      }
    }
  }

  async search( optionGroup: DropdownOptionGroup, searchText: string ) {
    let searchResults: Array<any>;
    const selection = optionGroup.selection.map( (s) => s._id );
    switch ( optionGroup.key ) {
      case 'locationId':
        searchResults = await this.searchLocations( selection, searchText );
        break;
      case 'categoryId':
        searchResults = await this.searchCategories( selection, searchText );
        break;
    }
    optionGroup.options = searchResults.map((r) => Object.assign( new DropdownOption( r._id, r.name), r));
  }

  async searchCategories(selection: Array<string>, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      subtype: 'category',
      search: searchText,
      limit: 10,
      anyLocation: true,
      omit: selection
    }) as Array<any>;
  }

  removeOption = async (optionIndex) => {
    this.account.options[0].options.splice(optionIndex, 1);
  }

  viewOption = async (accountId) => {
    console.log('viewOption: ', accountId);
    await this.saveAccount(false);
    console.log(['../', accountId]);
    this.router.navigate(['../../inventory/', accountId], { relativeTo: this.route });
  }

  async searchLocations( selection: Array<string>, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/location/getLocations', {
      active: true,
      type: 'vendor',
      search: searchText,
      limit: 10,
      omit: selection
    }) as Array<any>;
  }

  async searchAccounts( searchText: string ) {
    if (searchText && searchText.length > 2) {
      // Build Omit array of all account IDs already selected as components
      const omit: Array<string> = this.account.options.map((o) => o._id);

      const accountParameters: any = {
        active: true,
        type: 'inventory',
        locationId: this.locationService.getLocationIds(),
        search: searchText,
        limit: 10,
        omit,
        returnCategoryName: true,
        subtype:  ['retail', 'component'],
      };

      const accountResults = await this.ioService.post('/inventory/getInventoryAccounts', accountParameters) as Array<any>;

      console.log('accountResults: ', accountResults);
      this.accountDropdown.options = accountResults.map((o) => Object.assign(new DropdownOption(o._id, o.name), o));
    }
  }

  toggleDetailView(viewKey) {
    if (this.account.activeView === viewKey) {
      this.account.activeView = null;
    } else {
      this.account.activeView = viewKey;
    }
  }

  addAccount = async () => {
    if (!(this.accountDropdown.selection.length > 0)) {
      return;
    }
    const selection = this.accountDropdown.selection[0] as any;
    this.account.accounts[selection._id] = selection;

    const option = {
      active: true,
      accountId: selection._id,
      name: selection.name,
      qty: 1,
      // key: Date.now().toString(),
    };

    if (selection.shortName) {
      option.name = selection.shortName;
    }

    this.account.options[0].options.push(option);
    this.accountDropdown.selection = new Array<DropdownOption>();
  }
}
