<section class="gate">
  <div class="tinter">

    <div *ngIf="clientConfig" class="gate-container">
        <div class="gate-content">
            <div class="gate-label">Welcome back,</div>
            <div class="gate-logo" (click)="resetClientConfig()">
                <img src="{{clientConfig.logoUrl}}" alt="{{clientConfig.name}}" draggable="false" />
            </div>
            <h1 class="gate-title">Login</h1>
            <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
                <div class="input">
                    <label for="email" class="hidden">Email or Phone</label>
                    <input type="email" placeholder="Email or Phone" formControlName="email" required>
                </div>
                <div class="input">
                    <label for="password" class="hidden">Password</label>
                    <input type="password" placeholder="Password" formControlName="password" required>
                </div>
                <div class="error">{{error}}</div>
                <div class="form-actions right">
                    <a [routerLink]="['/reset-password']">Reset Password</a>
                    <button type="submit" class="button">Sign In</button>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="!clientConfig" class="gate-container">
      <div class="gate-content">
        Client Config Screen
        <div class="input">
            <label class="hidden">Client Key</label>
            <input type="text" [(ngModel)]="configCode" />
            <button (click)="requestClientConfig()">Request Client Config</button>
        </div>
      </div>
    </div>

    <div class="powered-by">
        powered by
        <a href="https://redfiretechnology.com/" target="_blank">
          <img src="assets/global/logo-red-fire.svg" alt="Red Fire Technology" draggable="false">
        </a>
    </div>
  </div>
</section>
