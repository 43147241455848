import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { IoService } from '../../services/io/io.service';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';


@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.scss']
})
export class CountComponent implements OnInit {

  purchaseId$: Observable<any>;
  purchase;

  activeItem;

  validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  lastKey;
  keyboardBuffer: String;
  barcode: String;

  barcodes = [];


  constructor(
    private route: ActivatedRoute,
    private ioService: IoService,
    private tableScroll: ScrollDispatcher,
  ) { }

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.target.toString() == "[object HTMLBodyElement]") {
      if (this.validKeys.indexOf(event.key.toLowerCase()) !== -1) {
        this.keyboardBuffer += event.key;
      } else {
        if (event.key == "Enter") {
          this.searchBarcode(this.keyboardBuffer);
          this.keyboardBuffer = "";
        }
      }
    }
  }

  ngOnInit(): void {

    this.purchaseId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('purchaseId'))));
    this.purchaseId$.subscribe(purchaseId => {
      if (purchaseId) {
        console.log('purchaseId is defined: ', purchaseId);
        this.loadPurchase(purchaseId);
      } else {
        console.log('purchaseId is not defined.');
      }
    });

  }

  loadPurchase = async (purchaseId) => {
    console.log('loadPurchase: ', purchaseId);
    let purchaseResponse: any = await this.ioService.post('/transfer/getTransfer', {
      purchaseId,
    });

    let itemIndex = 0;
    let barcodeIndexes = {};
    for (let item of purchaseResponse.items) {
      item.index = itemIndex;

      if (!item.count) {
        item.count = 0;
      }

      if (item.barcode) {
        if (Array.isArray(item.barcode)) {
          for (let barcode of item.barcode) {
            barcodeIndexes[String(barcode)] = itemIndex;
          }
        } else {
          barcodeIndexes[String(item.barcode)] = itemIndex;
        }
      }

      itemIndex++;
    }

    purchaseResponse.barcodeIndexes = barcodeIndexes;

    console.log('barcodeIndexes:', barcodeIndexes);

    this.purchase = purchaseResponse;

    this.keyboardBuffer = "";

    this.barcodes = [];

    return;
  }

  searchBarcode(barcode) {
    console.log("BARCODE INDEXES:");
    console.log(this.purchase.barcodeIndexes);

    let barcodeIndex = this.purchase.barcodeIndexes[barcode];
    
    if (barcodeIndex !== undefined) {
      console.log('barcodeIndex: '+barcodeIndex);
      setTimeout(() => {
        // this.viewPort.scrollToIndex(barcodeIndex);
        this.setActiveItem(barcodeIndex);
      }, 0);
    } else {
      alert('ITEM NOT FOUND: *'+barcode+'*');
    }
  }

  setActiveItem(itemIndex) {
    if (this.activeItem) {
      if (itemIndex === this.activeItem.index) {
        // This is the active item, add to its count

        this.activeItem.count++;

      } else {
        // An item is already active

      }

    } else {
      this.viewPort.scrollToIndex(itemIndex);
      let activeItem = this.purchase.items[itemIndex];

      if (activeItem.counted) {

      } else {
        activeItem.expectedQty = activeItem.qty;
        activeItem.qty = 0;
      }


      this.activeItem = activeItem;
      console.log('activeItem: ', this.activeItem);
    }

  }

  countBtnPress(value) {
    if (!this.activeItem.saving) {
      if (Number.isInteger(value)) {
        if (this.activeItem.qty == "0") {
          this.activeItem.qty = "";
        }
        this.activeItem.qty = this.activeItem.qty+value;
      } else {
        switch (value) {
          case "d":
            this.activeItem.qty = this.activeItem.qty.slice(0, -1);
            break;
  
          case "c":
            this.activeItem.qty = "0";
            break;
        }
      }
    }
  }

  cancel() {
    this.activeItem.qty = this.activeItem.expectedQty;
    delete this.activeItem.expectedQty;
    this.activeItem = null;
  }

  save = async () => {

    let activeItem = this.activeItem;

    activeItem.saving = true;
    activeItem.counted = true;

    // Send Update to Server
    let countUpdate: any = await this.ioService.post('/transfer/updateTransferItem', {
      purchaseId: this.purchase._id,
      type: this.purchase.type,
      locationId: this.purchase.locationId,
      customerId: this.purchase.customerId,
      itemIndex: activeItem.index,
      item: activeItem,
    });

    activeItem.saving = false;

    
    this.activeItem = null;

    

    this.loadPurchaseUpdates(this.purchase.activeItem.index);
  }

  loadPurchaseUpdates = async (omitIndex) => {
    let purchaseUpdatesResponse: any = await this.ioService.post('/transfer/getTransferUpdates', {
      purchaseId: this.purchase._id,
      lastRetrieved: this.purchase.lastRetrieved,
      omitIndex,
    });

    if (purchaseUpdatesResponse.updatedItems && purchaseUpdatesResponse.updatedItems.length > 0) {
      for (let updatedItem of purchaseUpdatesResponse.updatedItems) {
        this.purchase.items[updatedItem.index].qty = updatedItem.item.qty;
        this.purchase.items[updatedItem.index].expectedQty = updatedItem.item.expectedQty;
        this.purchase.items[updatedItem.index].counted = updatedItem.item.counted;
      }
    }

    this.checkCountComplete();

    this.purchase.lastRetrieved = purchaseUpdatesResponse.lastRetrieved;

    return;
  }

  checkCountComplete() {
    let total = 0;
    let counted = 0;
    for (let item of this.purchase.items) {
      total ++;
      if (item.counted) {
        counted++;
      } else {
        if (item.targetQty == 0) {
          counted++;
        }
      }
    }

    if (counted == total) {
      this.purchase.countComplete = true;
    } else {
      this.purchase.countComplete = false;
    }

    return {
      total,
      counted,
    };
  }

}
