import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface ClientConfigResponse {
  success: boolean,
  msg: string
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private clientConfig;

  private clientConfigChanged = new Subject<any>();


  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    const clientConfig = localStorage.getItem('clientConfig');
    if (clientConfig) {
      this.clientConfig = JSON.parse(clientConfig);
      if ( !this.clientConfig.key ) {
        this.resetClientConfig();
      }
    }
  }

  getConfig() {
    return this.clientConfig;
  }

  getClientKey() {
    return this.clientConfig?.key;
  }

  getRfId() {
    return this.clientConfig?.rfId;
  }

  getServerPath() {
    return this.clientConfig?.serverUrl;
  }

  getLogoUrl() {
    return this.clientConfig?.logoUrl;
  }

  clientConfigChangedObservable(): Observable<any> {
    return this.clientConfigChanged.asObservable();
  }

  requestClientConfig(key: string = null): Promise<ClientConfigResponse> {
    if ( null == key ) {
      if ( this.clientConfig  ) {
        key = this.clientConfig.key;
      } else {
        return new Promise((resolve, reject) => {
          console.error('invalid config code');
          reject( 'Invalid config.' );
        });
      }
    }
    return new Promise((resolve, reject) => {
      this.http.post<any>('https://stoker.rftapi.com/ise/getClientConfig', {
        key
      }, {}).toPromise()
        .then(clientConfigResponse => {

          if (clientConfigResponse.success) {
            this.clientConfig = clientConfigResponse.clientConfig;

            if (!environment.production && environment.localServer ) {
              this.clientConfig.serverUrl = 'http://localhost:3000/';
            }

            localStorage.setItem('clientConfig', JSON.stringify(this.clientConfig));
            this.clientConfigChanged.next(this.clientConfig);
          }

          resolve(clientConfigResponse);
        })
        .catch(err => {
          console.error('requestClientConfig failed');
          resolve(err.error);
        });
    });
  }

  resetClientConfig() {
    localStorage.removeItem('clientConfig');
    this.clientConfig = null;
    this.clientConfigChanged.next(this.clientConfig);
  }
}
