import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ClientService } from '../../../services/client/client.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  clientId;
  clientConfig;
  clientConfigChanged: Subscription;

  validInput;
  processing;

  password;
  passwordi;
  key;
  error;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientConfigChanged = this.clientService.clientConfigChangedObservable().subscribe(clientConfig => {
      this.clientConfig = clientConfig;
      if (this.clientConfig && this.clientConfig.logoUrl) {
        console.log('UPDATE LOGO SOURCE');
        this.clientConfig.logoUrl = this.clientConfig.logoUrl.replace("white", "black");
      }
    });

    this.clientConfig = this.clientService.getConfig();
    if (this.clientConfig && this.clientConfig.logoUrl) {
      console.log('UPDATE LOGO SOURCE');
      this.clientConfig.logoUrl = this.clientConfig.logoUrl.replace("white", "black");
    }

    let clientKey = this.route.snapshot.queryParamMap.get('clientKey');
    if (clientKey) {
      this.clientService.requestClientConfig(clientKey);
    }

    this.key = this.route.snapshot.queryParamMap.get('key');
  }

  ngOnDestroy() {
    if (this.clientConfigChanged) {
      this.clientConfigChanged.unsubscribe();
    }
  }


  updatePassword(event: any) {
    this.password = event.target.value;
    this.checkValid();
  }

  updatePasswordi(event: any) {
    this.passwordi = event.target.value;
    this.checkValid();
  }

  checkValid() {
    if (this.password.length > 7 && this.password == this.passwordi) {
      this.validInput = true;
    } else {
      this.validInput = false;
    }
  }

  resetPassword() {
    this.error = null;
    if (this.validInput && !this.processing) {
      this.processing = true;
      this.authenticationService.setPassword(this.password, this.key)
        .then(() => {
          this.router.navigate(['/sign-in']);
        })
        .catch(resetError => {
          this.processing = false;
          console.log(resetError);
          this.error = resetError.error.msg;
        });
    } else {
      if (!this.validInput) {
        if (!this.password || !(this.password.length > 7)) {
          this.error = 'Password must be at least 8 characters.';
        } else {
          this.error = 'Passwords do not match.';
        }
      }
    }
  }

}
