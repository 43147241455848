<header>
  <div class="row">
    <div class="type">Type</div>
    <div class="name">Name</div>
    <div class="date-time">Date/Time</div>
    <div class="audience">Audience</div>
  </div>
</header>
<main>
  <div *ngFor="let communication of communications" class="row" [class.active]="communication.sendFinish" [routerLink]="['/marketing', 'communication', communication._id]">
    <div class="type">{{communication.type}}</div>
    <div class="name">{{communication.name}}</div>
    <div class="date-time">{{communication.sendStart | rf: 'dateTimeShort'}}</div>
    <div class="audience">{{communication.customerCount | rf: 'number'}}</div>
  </div>
</main>
