import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

import * as moment from 'moment';

@Component({
  selector: 'app-anniversary',
  templateUrl: './anniversary.component.html',
  styleUrls: ['./anniversary.component.scss']
})
export class AnniversaryComponent implements OnInit {

    sendTimes = [
      '9:00 AM',
      '9:30 AM',
      '10:00 AM',
      '10:30 AM',
      '11:00 AM',
      '11:30 AM',
      '12:00 PM',
      '12:30 PM',
      '1:00 PM',
      '1:30 PM',
      '2:00 PM',
      '2:30 PM',
      '3:00 PM',
      '3:30 PM',
      '4:00 PM',
      '4:30 PM',
      '5:00 PM',
      '5:30 PM',
      '6:00 PM',
      '6:30 PM',
      '7:00 PM',
      '7:30 PM',
      '8:00 PM',
      '8:30 PM',
      '9:00 PM'
    ];

    charLimit = 160;
    activeLocationSet: Subscription;
    location;
    saving;


  constructor(
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.getSettings();
    });
    this.getSettings();
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['marketing', 'dashboard']);
  }

  getSettings() {
    return new Promise<void>((resolve, reject) => {
      this.saving = false;
      let activeLocation = this.locationService.getActiveLocation();
      if (!activeLocation || !activeLocation._id) {
        this.close();
      } else {
        this.ioService.post('/marketing/getSettings', {
          locationId: activeLocation._id
        }).then((settingsResponse: any) => {
          console.log('settingsResponse: ', settingsResponse);
          let location = settingsResponse.location;

          // Apply defaults
          if (!location.marketing) {
            location.marketing = {};
          }

          if (!location.marketing.anniversary || location.marketing.anniversary.active == null) {
            location.marketing.anniversary = {
              active: false,
              content: '',
              sendTime: '11:30 AM'
            }
          }

          this.location = location;
          resolve();
          console.log(this.location);
        });
      }
    });
  }

  toggleActive(active) {
    if (active == null) {
      this.location.marketing.anniversary.active = !this.location.marketing.anniversary.active;
    } else {
      this.location.marketing.anniversary.active = active;
    }
  }

  contentChanged() {
    console.log('contentChanged');
    if (this.location.marketing.anniversary.content.length > this.charLimit) {
       this.location.marketing.anniversary.content = this.location.marketing.anniversary.content.substring(0, this.charLimit);
    }
  }

  save() {
    return new Promise<void>((resolve, reject) => {
      if (!this.saving) {
        this.saving = true;

        this.ioService.post('/marketing/saveSettings', {
          location: this.location
        }).then((saveResponse: any) => {
          console.log('saveResponse: ', saveResponse);

          this.saving = false;
          this.close();
          resolve();
          console.log(this.location);
        }).catch(error => {
          this.saving = false;
          reject(error);
        });

      } else {
        reject({
          msg: 'Already saving.'
        });
      }
    });
  }

}
