export class DiscountGroupItem {
    public active: boolean = null;
    public accountId: string = null;
    public discountId: string = null;
    public name: string = null;
    public retailPrice: number = null;
    public cost: number = null;
    public saleType: string = null;
    public saleValue: number = null;
    public matchingDiscounts: any[] = null;
    public filterKey: string = null;

    constructor() {
        this.active = true;
     }

     // Send like this to the API
    public stripped(): any {
        const o = {
            ... this,
        };
        delete o.name;
        delete o.retailPrice;
        delete o.cost;
        delete o.matchingDiscounts;
        return o;
    }
}

export enum DiscountItemSaleType {
    SalePrice = 'sale-price',
    PercentOff = 'percentage',
    DollarsOff = 'dollars-off',
}
