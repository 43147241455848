import { Component, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { ListBuilderService } from '../../../services/list-builder/list-builder.service';

@Component({
  selector: 'app-list-builder',
  templateUrl: './list-builder.component.html',
  styleUrls: ['./list-builder.component.scss']
})
export class ListBuilderComponent implements OnInit {

  itemAdded: Subscription;

  constructor(
    private listBuilderService: ListBuilderService,
  ) { }

  ngOnInit(): void {

    // this.itemAdded = this.listBuilderService.itemAddedObservable().subscribe(item => {
      
    // });

  }

}
