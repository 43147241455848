import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownOption, DropdownOptionGroup, DropdownSelectionMode } from 'src/app/modules/shared/interfaces/option-interfaces';
import { IoService } from 'src/app/services/io/io.service';
import { DiscountFilter } from '../../../shared/interfaces/discounts/discount-filter';

@Component({
  selector: 'app-discount-filter',
  templateUrl: './discount-filter.component.html',
  styleUrls: ['./discount-filter.component.scss']
})
export class DiscountFilterComponent implements OnInit {

  @Input() filter: DiscountFilter;
  @Output() filterChange = new EventEmitter<any>();

  keyDropdown: DropdownOptionGroup = {
    key: 'key',
    name: 'Key',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.One,
  };

  operatorDropdown: DropdownOptionGroup = {
    key: 'operator',
    name: 'Operator',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.One,
  };

  valueDropdown: DropdownOptionGroup = {
    key: 'value',
    name: 'Value',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.Multi,
  };

  constructor( private ioService: IoService ) { }

  ngOnInit(): void {
    this.updateDropdownOptions();
    this.initializeSelections();
  }

  initializeSelections() {
    if ( !this.filter ) {
      return;
    }
    this.keyDropdown.selection = new Array<DropdownOption>();
    if ( this.filter.key  ) {
      const selection = this.keyDropdown.options.find((k) => k._id === this.filter.key);
      if (!selection ) {
        console.error('Invalid key dropdown configuration.');
      } else {
        this.keyDropdown.selection.push(selection);
      }
    }
    this.operatorDropdown.selection = new Array<DropdownOption>();
    if ( this.filter.operator ) {
      const selection = this.operatorDropdown.options.find((k) => k._id === this.filter.operator);
      if ( !selection ) {
        console.error('Invalid operator dropdown configuration.');
      } else {
        this.operatorDropdown.selection.push(selection);
      }
    }
    this.valueDropdown.selection = new Array<DropdownOption>();
    if ( this.filter.selectedValue ) {
      if ( Array.isArray(this.filter.selectedValue) ) {
        this.filter.selectedValue.forEach((v) => { this.valueDropdown.selection.push(v); });
      } else {
        this.valueDropdown.selection.push(this.filter.selectedValue);
      }
    }
  }

  updateDropdownOptions() {
    this.keyDropdown.options = DiscountFilter.DefaultKeys();
    this.operatorDropdown.options = DiscountFilter.DefaultOperators();
  }

  async searchValues(searchText: string) {
    // Search values based on selected key
    const searchParameters: any = {
      search: searchText,
      idField: this.filter.key,
      limit: 10,
      omit: this.valueDropdown.selection.map( s => s._id )
    };

    switch ( this.filter.key ) {
      case 'accountId':
        searchParameters.type = 'inventory';
        searchParameters.subtype = 'retail';
        break;
      case 'groupId':
      case 'categoryId':
        searchParameters.type = 'inventory';
        break;
      case 'userId':
        searchParameters.type = 'user';
        break;
      case 'customerId':
        searchParameters.type = 'customer';
        break;
      default:
        console.error( 'Invalid discount filter key: ' + this.filter.key );
        return;
    }
    this.valueDropdown.options = (await this.ioService.post('/discount/searchOptions', searchParameters) as any[])
                            .map( o => Object.assign(new DropdownOption(o._id, o.name), o));

  }

  updateFilterKey() {
    const newKey = this.getKey();
    if ( newKey !== this.filter.key && this.filter.key ) {
      this.valueDropdown.selection = new Array<DropdownOption>();
    }
    this.filter.key = newKey;
    this.filterChange.emit(this.filter);
  }

  updateFilterOperator() {
    this.filter.operator = this.getOperator();
    this.filterChange.emit(this.filter);
  }

  updateFilterValue() {
    this.filter.value = this.getValue();
    this.filterChange.emit(this.filter);
  }

  getKey(): string {
    if ( !this.keyDropdown.selection || this.keyDropdown.selection.length === 0 ) {
      return null;
    }
    return this.keyDropdown.selection[0]._id;
  }

  getOperator(): string {
    if ( !this.operatorDropdown.selection || this.operatorDropdown.selection.length === 0 ) {
      return null;
    }
    return this.operatorDropdown.selection[0]._id;
  }

  getValue(): Array<string> {
    if ( !this.valueDropdown.selection || this.valueDropdown.selection.length === 0 ) {
      return null;
    }
    return this.valueDropdown.selection.map((s) => s._id);
  }
}
