<label class="option-header" *ngIf="optionGroup?.name">{{optionGroup?.name}}</label>
<div class="select-one-empty" *ngIf="(optionGroup.selectMode == selectionMode.One) && (!optionGroup.selection || optionGroup.selection.length === 0 || !optionGroup.selection[0])">
    -None-
</div>
<div class="main-container" [class.reversed]="searchLocation=='above'">
    <div class="selection-container" *ngIf="optionGroup.selectMode !== selectionMode.None">
        <ng-container *ngFor="let selectedOption of optionGroup.selection" >
            <div *ngIf="selectedOption" [ngClass]="optionGroup.selectMode == selectionMode.Multi ? 'select-multiple-option' : 'select-one-option'">
                <div class="close small-close-button" *ngIf="optionGroup.selectMode == selectionMode.Multi || optionGroup.selectMode == selectionMode.One" (click)="removeSelection(selectedOption)"></div>
                <div class="option">{{selectedOption.name}}</div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="(optionGroup.selectMode != selectionMode.None && optionGroup.selectMode != selectionMode.One) && (!optionGroup.selection || optionGroup.selection.length == 0)" class="all-option">None</div>
    <div class="dynamic-builder">
        <input type="text" #input
                [(ngModel)]="searchText" 
                (focus)="search()"
                (blur)="blur()"
                (keydown)="keyDown($event)"
                [readonly]="disableSearch"
                [placeholder]="placeholder ? placeholder : 'Search ' + optionGroup?.name" />
        <div *ngIf="optionGroup.options" id="dynamicOptions" [class.hidden]="!showList" (mouseover)="mouseInList()" (mouseout)="mouseOutOfList()">
            <div *ngIf="optionGroup.options?.length == 0" class="no-results">
            - No Results -
            </div>
            <div *ngIf="optionGroup.options?.length > 0 && optionGroup.selectMode == selectionMode.Multi" class="dynamic-option" (click)="addAllOptions()">
                + Select ALL
                </div>
            <div *ngFor="let searchOption of optionGroup.options; index as i" (click)="searchOptionSelected(searchOption)" (mouseover)="selectItem(i)" class="dynamic-option" [class.selected]="selectedIndex === i">
                <div *ngIf="searchOption.type" class="option-type">{{searchOption.type}}</div>
                <div *ngIf="searchOption.subtype" class="option-type">{{searchOption.subtype}}</div>
                <div *ngIf="searchOption.category" class="option-category">{{searchOption.category}}</div>
                <div class="w-100">
                    <span style="float:left">{{searchOption.name}}</span><span style="float:right" *ngIf="searchOption.unitDescription">{{searchOption.unitDescription}}</span>
                </div>
            </div>
            <div *ngIf="enableAddNew" (click)="addNew()" class="dynamic-option new">
                + {{searchText}}
            </div>
        </div>
        <div class="searching" *ngIf="optionGroup.options === null && showList && searchText?.length > 2" >
            <i class="icon"></i>Searching...
        </div>
    </div>
</div>