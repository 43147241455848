import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateRangeSettings } from '../../filter-date-range/date-config-interface';
import { DiscountGroup } from 'src/app/modules/shared/interfaces/discounts/discount-group';
import { DiscountGroupItem, DiscountItemSaleType } from 'src/app/modules/shared/interfaces/discounts/discount-group-item';
import { IoService } from 'src/app/services/io/io.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from 'src/app/modules/shared/interfaces/option-interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/services/report/report.service';
import { LoadingComponent } from 'src/app/modules/shared/loading/loading.component';
import { DiscountItemListComponent } from './discount-item-list/discount-item-list.component';
import { LocationService } from 'src/app/services/location/location.service';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { DiscountGroupCategoryComponent } from './discount-group-category/discount-group-category.component';

@Component({
  selector: 'app-discount-sale-price',
  templateUrl: './discount-sale-price.component.html',
  styleUrls: ['./discount-sale-price.component.scss'],
})
export class DiscountSalePriceComponent implements OnInit {
  @ViewChild(DiscountItemListComponent) itemList: DiscountItemListComponent;
  @ViewChildren(DiscountGroupCategoryComponent) groupList: QueryList<DiscountGroupCategoryComponent>;

  @Input()
  discountGroup: DiscountGroup;

  @Output() saved = new EventEmitter<DiscountGroup>();

  typeEnum = DiscountItemSaleType;
  saleTypes = [];

  startDateSettings = new DateRangeSettings();
  endDateSettings = new DateRangeSettings();
  statusText = '';

  locationDropdown: DropdownOptionGroup =
  {
    key: 'locationId',
    name: 'Available at Locations',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.Multi,
  };

  canEdit: boolean = false;

  constructor( private ioService: IoService,
               private router: Router,
               private route: ActivatedRoute,
               private reportService: ReportService,
               private locationService: LocationService,
               private authorizationService: AuthorizationService ) { }

  async ngOnInit() {
    this.startDateSettings.dateConfig.singleDate = this.endDateSettings.dateConfig.singleDate = true;
    this.startDateSettings.dateConfig.opens = this.endDateSettings.dateConfig.opens = 'left';
    this.startDateSettings.dateConfig.autoApply = this.endDateSettings.dateConfig.autoApply = false;
    this.startDateSettings.dateConfig.showRanges = this.endDateSettings.dateConfig.showRanges = false;

    if ( !this.discountGroup.locationId || !this.discountGroup.locationId?.length ) {
      const availableLocations = await this.locationService.getLocationsByType('retail');
      this.discountGroup.locationId = availableLocations.map((l) => l._id);
    }

    this.saleTypes = Object.keys(DiscountItemSaleType);
    this.updateDiscountGroup();
    this.locationDropdown.selection = new Array<DropdownOption>();
    this.searchLocations('');

    this.canEdit = this.authorizationService.checkPermission('account.discount.manage', 'any');
    console.log('canEdit: ', this.canEdit);
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  toggleStartDate() {
    if ( this.discountGroup.startDate ) {
      this.disableStartDate();
    } else {
      this.enableStartDate();
    }
  }
  toggleEndDate() {
    if ( this.discountGroup.endDate ) {
      this.disableEndDate();
    } else {
      this.enableEndDate();
    }
  }
  disableStartDate() {
    this.discountGroup.startDate = null;
    this.updateDiscountGroup();
  }
  enableStartDate() {
    this.discountGroup.startDate = moment().toISOString();
    this.updateDiscountGroup();
  }
  disableEndDate() {
    this.discountGroup.endDate = null;
    this.updateDiscountGroup();
  }
  enableEndDate() {
    this.discountGroup.endDate = moment().toISOString();
    this.updateDiscountGroup();
  }
  updateDiscountGroup() {
    if (this.discountGroup) {
      this.startDateSettings.dateRange.startDate = this.discountGroup.startDate ?
                                                  moment(this.discountGroup.startDate)
                                                  : null;
      this.endDateSettings.dateRange.startDate = this.discountGroup.endDate ?
                                                moment(this.discountGroup.endDate)
                                                : null;
      this.statusText = '';
      this.checkItems();
    }
  }

  startDateChanged(dateRange) {
    if (!this.discountGroup ) {
      return;
    }
    this.discountGroup.startDate = (dateRange.startDate as Moment).toISOString();
    this.checkItems();
  }
  endDateChanged(dateRange) {
    if (!this.discountGroup ) {
      return;
    }
    this.discountGroup.endDate = (dateRange.startDate as Moment).toISOString();
    this.checkItems();
  }

  async save() {
    LoadingComponent.showLoading();
    this.discountGroup.locationId = [];
    if ( this.locationDropdown.selection && this.locationDropdown.selection.length > 0 ) {
      this.discountGroup.locationId = this.locationDropdown.selection.map( l => l._id );
    }
    this.ioService.post('/group/saveDiscountGroup', {
      discountGroup: this.discountGroup.stripped(),
    })
    .then( (success) => {
      this.reportService.loadReport(null);
    })
    .catch( (err) => {
      console.error( err );
    })
    .finally(() => {
      LoadingComponent.hideLoading();
      this.saved.emit(this.discountGroup);
    });
  }

  async delete() {
    LoadingComponent.showLoading();
    this.ioService.post('/group/deactivateDiscountGroup', {
      discountGroupId: this.discountGroup._id,
    })
    .then( (success) => {
      this.router.navigate(['/reports/sale-ads']);
      this.reportService.loadReport(null);
    })
    .catch( (err) => {
      console.error( err );
    })
    .finally(() => {
      LoadingComponent.hideLoading();
      this.saved.emit(this.discountGroup);
    });
  }

  saleTypeChanged() {
    for ( const item of this.discountGroup.items ) {
      if ( item.saleType !== this.discountGroup.saleType ) {
        item.saleValue = null;
        item.saleType = this.discountGroup.saleType;
      }
    }
    if ( this.discountGroup.saleType === 'sale-price' ) {
      this.discountGroup.items = this.discountGroup.items.filter((i) => Array.from(['groupId', 'categoryId']).indexOf(i.filterKey) === -1);
    }
    this.itemList.refreshPrices();
    this.groupList.forEach((g) => g.refresh());
  }

  async checkItems(): Promise<boolean> {
    let noNewWarnings = true;
    for ( const item of this.discountGroup.items.filter((i) => i.active && i.filterKey === 'accountId') ) {
      if ( item.matchingDiscounts?.length > 0) {
        continue;
      }
      const matchingDiscounts = (await this.ioService.post('/discount/getQualifiedDiscounts', {
        accountId: item.accountId,
        startDate: this.discountGroup.startDate,
        endDate: this.discountGroup.endDate,
      }) as any[]);
      if ( matchingDiscounts ) {
        item.matchingDiscounts = matchingDiscounts.filter((d) => !item.discountId || (item.discountId !== d._id));
        if (item.matchingDiscounts.length > 0 ) {
          noNewWarnings = false;
        }
      }
    }
    return noNewWarnings;
  }

  canSave(): boolean {
    this.statusText  = '';
    if (this.discountGroup.items?.length &&
        this.discountGroup.items.findIndex((i) => i.filterKey === 'accountId' && !i.saleValue && i.active === true) !== -1) {
      this.statusText = 'All items must have a value.';
      return false;
    }
    if (this.discountGroup.items?.length &&
        this.discountGroup.items.findIndex((i) => i.filterKey === 'groupId' && !i.saleValue && i.active === true) !== -1) {
      this.statusText = 'All groups must have a value.';
      return false;
    }
    if (this.discountGroup.items?.length &&
        this.discountGroup.items.findIndex((i) => i.filterKey === 'categoryId' && !i.saleValue && i.active === true) !== -1) {
      this.statusText = 'All categories must have a value.';
      return false;
    }
    if ( !this.locationDropdown.selection || !this.locationDropdown.selection?.length ) {
      this.statusText = 'At least one location must be selected.';
      return false;
    }
    return true;
  }

  async searchLocations( searchText: string ) {
    const availableLocations = await this.locationService.getLocationsByType('retail');
    this.locationDropdown.options = new Array<DropdownOption>();
    for (const location of availableLocations) {
      if (location._id) {
        if ( !this.authorizationService.checkPermission('account.discount.manage', location._id) ) {
          continue;
        }
        const newOption = new DropdownOption( location._id, location.name );
        if (this.discountGroup.locationId && this.discountGroup.locationId.indexOf( location._id ) !== -1 ) {
          this.locationDropdown.selection.push(newOption);
        } else if ( null == this.discountGroup.locationId && !this.locationDropdown.selection.some( l => l._id === location._id ) ) {
          this.locationDropdown.options.push( newOption );
        }
      }
    }
    this.discountGroup.locationId = null;
  }






  viewSummary() {
    this.reportService.loadFilteredReport('sale-tags', [
      {
        name: 'Items Contained In '+this.discountGroup.name,
        type: 'link',
        value: this.discountGroup._id,
      },
      {
        name: 'Group ID',
        path: 'groupId',
        type: 'id',
        value: this.discountGroup._id,
        hidden: true,
      }
    ]);
  }

  printTags() {
    this.ioService.print('/report/loadRecords', {
      key: 'sale-tags',
      templateKey: 'sale-tags',
      utcOffset: new Date().getTimezoneOffset(),
      clientConfig: {
        download: true,
        clientFilters: [
          {
            path: 'groupId',
            type: 'id',
            value: this.discountGroup._id
          }
        ]
      }
    });
  }

}
