import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { LocationService } from '../../../services/location/location.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';

@Component({
  selector: 'app-detail-tax',
  templateUrl: './detail-tax.component.html',
  styleUrls: ['./detail-tax.component.scss']
})
export class DetailTaxComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;
  errors;

  tax;
  locations;
  canManage;
  saving;

  roundingOptions = [
    {
      name: 'Round Half Up',
      value: 'roundHalfUp'
    },
    {
      name: 'Round Up',
      value: 'roundUp'
    },
  ];

  basisOptions = [
    {
      name: 'Invoice-Level',
      value: 'invoice'
    },
    {
      name: 'Item-Level',
      value: 'item'
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private historyService: HistoryService,
    private reportService: ReportService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadTaxDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadTaxDetail() {
    this.ioService.post('/account/getAccount', {
      accountId: this.documentId,
      detail: true
    }).then(async (tax: any) => {
      console.log('tax: ', tax);

      // Get Tax Location Data
      // let availableLocations = this.locationService.getLocations();
      const availableLocations = await this.locationService.getLocationsByType('retail');
      this.canManage = false;
      this.locations = [];
      for (const location of availableLocations) {
        if (location._id) {
          location.canManageTax = this.authorizationService.checkPermission('account.tax.manage', location._id);
          location.taxActive = (tax.locationId.indexOf(location._id) !== -1);
          if (location.canManageTax) {
            this.canManage = true;
          }
          this.locations.push(location);
        }
      }
      console.log('LOCATIONS: ', this.locations);

      tax.percentage = this.toPercentage(tax.percentage);

      this.tax = tax;

    });
  }

  saveTax() {
    if (!this.saving) {
      console.log('save tax');
      this.saving = true;

      if (this.tax.percentage) {
        this.tax.percentage = this.toDecimal(this.tax.percentage);
      }

      let updateTax = JSON.parse(JSON.stringify(this.tax));

      // Update Locations field
      updateTax.locationId = [];
      for (let location of this.locations) {
        if (location.taxActive) {
          updateTax.locationId.push(location._id);
        }
      }


      this.ioService.post('/account/updateAccount', {
        account: updateTax
      }).then((updateResponse: any) => {
        console.log('updateResponse: ', updateResponse);
        this.saving = false;
        this.reportService.loadReport(0);
        this.close();
      }).catch((updateError: any) => {
        this.errors = [updateError.error.msg];
        this.saving = false;
      });

    }
  }


  toDecimal(inputValue) {
    return Number.parseFloat((Number.parseFloat(inputValue)/100).toPrecision(4));
  }

  toPercentage(inputValue) {
    if (!inputValue) {
      inputValue = 0;
    }
    return (inputValue*100).toFixed(4);
  }

  toggleBoolean(toggleTarget) {
    this.tax[toggleTarget] = !this.tax[toggleTarget];
  }

}
