import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from 'ng2-charts';

import { RecentComponent } from './recent/recent.component';
import { ScheduledComponent } from './scheduled/scheduled.component';
import { MarketingComponent } from './marketing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommunicationComponent } from './communication/communication.component';
import { SettingsComponent } from './settings/settings.component';
import { MenuComponent } from './menu/menu.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { AnniversaryComponent } from './anniversary/anniversary.component';
import { AutoresponderComponent } from './autoresponder/autoresponder.component';

@NgModule({
  declarations: [
    RecentComponent,
    ScheduledComponent,
    MarketingComponent,
    DashboardComponent,
    CommunicationComponent,
    SettingsComponent,
    MenuComponent,
    BirthdayComponent,
    AnniversaryComponent,
    AutoresponderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    SharedModule,
    ChartsModule
  ]
})
export class MarketingModule { }
