import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';

import { SharedModule } from '../shared/shared.module';

import { NgxMaskModule } from 'ngx-mask';

import { VirtualTerminalComponent } from './virtual-terminal.component';

@NgModule({
  declarations: [VirtualTerminalComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    NgxMaskModule.forRoot()
  ]
})
export class VirtualTerminalModule { }
