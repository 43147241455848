<app-dropdown [(optionGroup)]="keyDropdown"
    (optionGroupChange)="updateFilterKey()"
    (searchChanged)="updateDropdownOptions()">
</app-dropdown>
<app-dropdown [(optionGroup)]="operatorDropdown"
    (optionGroupChange)="updateFilterOperator()"
    (searchChanged)="updateDropdownOptions()">
</app-dropdown>
<app-dropdown [(optionGroup)]="valueDropdown"
    (optionGroupChange)="updateFilterValue()"
    (searchChanged)="searchValues($event)">
</app-dropdown>