import { Component, Input, OnInit } from '@angular/core';
import { AuditChange, AuditRecord } from '../../interfaces/audit/audit-change';
import { RfPipe } from 'src/app/pipes/rf/rf.pipe';
import { AuditChangeType } from '../../interfaces/audit/audit-change-type';
import { JsonPipe } from '@angular/common';
import { ValueCompareComponent } from '../value-compare/value-compare.component';
import { AppComponent } from 'src/app/app.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-audit-item',
  templateUrl: './audit-item.component.html',
  styleUrls: ['./audit-item.component.scss']
})
export class AuditItemComponent implements OnInit {

  changeType = AuditChangeType;

  @Input() change: AuditRecord;

  oldValue = '';
  newValue = '';
  displayedColumns: string[] = ['type', 'key', 'fromValue', 'toValue'];

  constructor( private jp: JsonPipe,
               private clipboard: Clipboard ) { }

  ngOnInit(): void {}

  formatValue( value: any ): string {
    if ( null === value || undefined === value) {
      return '<null>';
    } else if ( value === '' )
    {
      return '<blank>';
    } else
    if ( Array.isArray(value) || (typeof value === 'object' ) ) {
      return this.jp.transform(value);
    } else {
      return value;
    }
  }

  isArrayOrObject( value: AuditChange ): boolean {
    let compareVal = value.fromValue;
    if ( compareVal === null || compareVal === undefined) {
      compareVal = value.toValue;
    }
    if ( compareVal === null || compareVal === undefined) {
      return true;
    }
    return (Array.isArray( compareVal ) || (typeof compareVal === 'object') );
  }

  showComparison( fromValue: string, toValue: string ) {
    AppComponent.dialog.open( ValueCompareComponent, {
      height: '60vh',
      width: '40vw',
      disableClose: false,
      data: {
        fromValue,
        toValue,
      },
    });
  }

  copyToClipboard( value: any ) {
    this.clipboard.copy( this.jp.transform(value) );
    AppComponent.showSnack('Copied', '', 2000);
  }
}
