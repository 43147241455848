export class DropdownOption {
    _id: string;
    name: string;

    constructor( id: string, name: string) {
        this._id = id;
        this.name = name;
    }
}

export enum DropdownSelectionMode {
    None = 1,
    One,
    Multi
}
export class DropdownOptionGroup {
    key: string;
    name: string;
    selectMode: DropdownSelectionMode;
    selection: Array<DropdownOption>;
    options: Array<DropdownOption>;
    opposingIdKey: string = null;
}
