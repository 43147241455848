import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.scss']
})
export class RecentComponent implements OnInit {

  activeLocationSet: Subscription;

  locationId;
  communications;

  constructor(
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.loadRecent();
    });
    if (this.locationService.getActiveLocation()) {
      this.loadRecent();
    }
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  loadRecent() {
    this.ioService.post('/communication/getCompleted', {
      locationId: this.locationService.getActiveLocation()._id
    }).then((completedResponse: any) => {
      console.log('completedResponse: ', completedResponse);
      this.communications = completedResponse.communications;
    });
  }

}
