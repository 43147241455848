<div *ngIf="discountGroup.saleType === 'sale-price'">
    Sale Price type discounts cannot be applied to Groups and Categories.
</div>
<div *ngIf="discountGroup.saleType !== 'sale-price'" class="container">
    <div class="header">
        <app-dropdown [(optionGroup)]="groupDropdown"
            (optionGroupChange)="newItemSelected()"
            (searchChanged)="searchGroups($event)"
            [placeholder]="dropdownPlaceholder">
        </app-dropdown>
    </div>
    <div>
        <div class="item-div" *ngFor="let item of activeItems; let idx = index">
            <div class="inner">
                <div class="close small-close-button" (click)="deleteItem(item)"></div>
                <app-sale-item [item]="item" [basicView]="true"></app-sale-item>
            </div>
        </div>
    </div>
</div>