<div class="detail" [class.visible]="account">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="account" class="back" (click)="back()"></div>
    <h2 *ngIf="!account">Vendor Product</h2>
    <h2 *ngIf="account" class="upper">{{account.subtype}}</h2>
  </header>
  <section>
    <div *ngIf="!account" class="loading">Loading . . .</div>
    <div *ngIf="account" class="account">
      <div class="column-general">
        <div class="field">
          <label>Item Name</label>
          <input type="text" [(ngModel)]="account.name" />
        </div>

        <div class="line-field">
          <div class="label-large">Cost from Vendor $</div>
          <input type="number" [(ngModel)]="account.price" />
        </div>

        <div class="line-field">
          <div class="label-large">Unit of Measure</div>
          <select [(ngModel)]="account.unitOfMeasure">
            <option value="EA">EA</option>
            <option value="OZ">OZ</option>
            <option value="FOZ">FOZ</option>
            <option value="BX">BX</option>
            <option value="LB">LB</option>
            <option value="YD">YD</option>
            <option value="SLICE">SLICE</option>
            <option value="GL">GL</option>
            <option value="GR">GR</option>
            <option value="FT">FT</option>
            <option value="CS">CS</option>
            <option value="QT">QT</option>
            <option value="BT">BT</option>
            <option value="CS">CS</option>
            <option value="CN">CN</option>
            <option value="BG">BG</option>
            <option value="PK">PK</option>
            <option value="RL">RL</option>
            <option value="ST">ST</option>
            <option value="BK">BK</option>
            <option value="USD">USD</option>
          </select>
        </div>

        <div class="field">
          <label>Unit Description</label>
          <input type="text" [(ngModel)]="account.unitDescription" />
        </div>

        <div class="line-field">
          <div class="label-large">SKU</div>
          <input type="text" [(ngModel)]="account.sku" />
        </div>

        <div class="line-field">
          <div class="label-large">Barcode / UPC</div>
          <input type="text" [(ngModel)]="account.barcode" />
        </div>
        <div class="line-field">
          <div class="label-large">Remote ID</div>
          <input type="text" [(ngModel)]="account.remoteId" />
        </div>

        <!-- <div class="field">
          <label>Image</label>
          <input type="text" [(ngModel)]="account.imgUrl" />
        </div> -->
        <div class="field">
          <label>Description</label>
          <textarea class="note-input" [(ngModel)]="account.description"></textarea>
        </div>

        <!-- Dropdown Selections -->
        <div *ngFor="let optionGroup of optionFields; let i = index" class="field">
          <app-dropdown 
            [(optionGroup)]="optionFields[i]"
            (searchChanged)="search(optionGroup, $event)">
          </app-dropdown>
        </div>        

        <!-- <div class="field">
          <app-dropdown
              title="Inventory Category"
              selectMode="one"
              [searchResults]="account.categorySearchResults"
              [(selection)]="account.category"
              (optionSelected)="setCategory($event)"
              (searchChanged)="searchCategories($event)">
          </app-dropdown>
        </div>

        <div class="field">
          <app-dropdown
            title="Vendor"
            selectMode="one"
            [searchResults]="account.locationSearchResults"
            [(selection)]="account.locations"
            (optionRemoved)="removeDefaultGroup()"
            (optionSelected)="setLocation($event)"
            (searchChanged)="searchLocations($event)">
          </app-dropdown>
        </div> -->

        <div style="padding: 32px 0;">
          <label style="padding: 0 8px; font-weight: bold; font-size: 2rem;">Vendor Unit Contains</label>

          <ng-container>
            <div *ngFor="let option of account.options[0].options; let optionIndex = index;" class="group-option">
              <div class="account-name">{{account.accounts[option.accountId].name}}</div>
              <div *ngIf="account.accounts[option.accountId].cost" class="cost">{{account.accounts[option.accountId].cost | rf:'currencyDetail'}} x {{option.qty}} = {{account.accounts[option.accountId].cost*option.qty | rf:'currency'}}</div>
              <div class="qty-units">
                <input type="number" [(ngModel)]="option.qty" />
                <div *ngIf="account.accounts[option.accountId].unitOfMeasure != 'EA'">({{account.accounts[option.accountId].unitOfMeasure}})</div>
                <div>{{account.accounts[option.accountId].unitDescription}}</div>
              </div>

              <div class="icon-buttons">
                <div class="icon-button red" (click)="removeOption(optionIndex)">
                  <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 736v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm128 724v-948h-896v948q0 22 7 40.5t14.5 27 10.5 8.5h832q3 0 10.5-8.5t14.5-27 7-40.5zm-672-1076h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>
                </div>
                <div class="icon-button" (click)="viewOption(option.accountId)">
                  <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
                </div>
              </div>

            </div>
          </ng-container>

          <div *ngIf="account.options[0].options.length === 1 && ((account.accounts[account.options[0].options[0].accountId].cost*account.options[0].options[0].qty) - (account.price*100) > 1 || (account.accounts[account.options[0].options[0].accountId].cost*account.options[0].options[0].qty) - (account.price*100) < -1)" class="warning">Retail Product Cost will be updated on Vendor Product Save.</div>
          <div *ngIf="account.options[0].options.length > 1" class="warning">Costs of Retail Products may need to be manually adjusted to fit this pricing. No automatic costing will be applied.</div>

          <div class="field">
            <app-dropdown [(optionGroup)]="accountDropdown"
              placeholder="Find / Add"
              (optionGroupChange)="addAccount()"
              (searchChanged)="searchAccounts($event)">
            </app-dropdown>
          </div>  
          <div class="column-spacer"></div>
        </div>
        <div class="buttons">
          <div *ngFor="let view of account.views" class="button options-toggle" [class.green]="account.activeViews == view.key" (click)="toggleDetailView(view.key)">
            <ng-container *ngIf="account.activeView == view.key">Hide</ng-container>
            <ng-container *ngIf="account.activeView != view.key">View</ng-container>
            {{view.name}}
          </div>
          <div class="button red" (click)="deleteAccount()">Delete</div>
          <!-- <div class="button yellow" (click)="cloneAccount()">Clone</div> -->
          <div class="button green" (click)="saveAccount()">Save</div>
          <div *ngFor="let error of account.errors" class="error">{{error}}</div>
        </div>
      </div>
      <div *ngIf="account.activeView =='audit'" class="column-audit">
        <app-audit collectionName="Account" [documentId]="documentId"></app-audit>
      </div>
    </div>
  </section>
</div>
