import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { IoService } from 'src/app/services/io/io.service';
import { AuditChange } from '../interfaces/audit/audit-change';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  @Input() collectionName: string;
  @Input() documentId: string;

  records: Array<AuditChange>;
  retrieving = false;

  displayedColumns: string[] = ['timestamp', 'user', 'changes'];

  public static showModal( collectionName: string, documentId: string ) {
    AppComponent.dialog.open( AuditComponent, {
      height: '80vh',
      width: '50vw',
      hasBackdrop: true,
      disableClose: false,
      data: {
        collectionName,
        documentId,
      },
    });
  }

  constructor( private ioService: IoService, ) { }

  ngOnInit(): void {
    this.refresh();
  }

  public async refresh() {
    if ( !this.collectionName || !this.documentId ) {
      console.error('AuditComponent requires a valid collection name and document Id.');
      return;
    }
    try {
      this.records = null;
      this.retrieving = true;
      const response = await this.ioService.post( 'audit/getHistory', {
        collectionName: this.collectionName,
        documentId: this.documentId,
      });
      this.records = response as AuditChange[];
    } catch ( err ) {
      this.records = null;
      AppComponent.showSnack( 'Failed to retrieve audit history.', 'OK');
      console.error( err );
    } finally {
      this.retrieving = false;
    }
  }
}
