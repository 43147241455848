import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-detail-batch',
  templateUrl: './detail-batch.component.html',
  styleUrls: ['./detail-batch.component.scss']
})
export class DetailBatchComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    batch;

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private ioService: IoService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadBatchDetail();
        }
      });
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    loadBatchDetail() {
      console.log('loadBatchDetail: ');
      // this.ioService.post('/batch/getDetails', {
      //   transactionId: this.documentId
      // }).then((batchResponse: any) => {
      //   console.log('batchResponse: ', batchResponse);
      //
      //
      // });
    }

}
