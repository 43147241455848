<app-header class="no-print"></app-header>
<ng-container>
    <div *ngIf="activePurchase" class="inventory">
        <div class="transfer-summary">
            <div>{{activePurchase.key}}</div>
            <div>{{activePurchase.customerName}}</div>
            <div>{{activePurchase.name}}</div>
        </div>
        <div class="search-container" [class.soft-hidden]="!activePurchase.showManualSearch">
            <input type="text" class="search-input" #searchinput [(ngModel)]="activePurchase.searchText" (ngModelChange)="search()" placeholder="Search / Add Item" />
            <div class="search-btn">Lookup</div>
        </div>
        <div class="header">
            <div class="col-sku">SKU</div>
            <div class="col-item">Item</div>
            <div class="col-expected">Expected</div>
            <div class="col-actual">Actual</div>
            <div *ngIf="activePurchase.showVariantsOnly" class="col-variance">Variance</div>
        </div>




        <cdk-virtual-scroll-viewport *ngIf="!activePurchase.showVariantsOnly" itemSize="50" minBufferPx="500" maxBufferPx="500" class="inventory-table no-print" id="inventory-table">
            <ng-container *cdkVirtualFor="let item of activePurchase.items; let itemIndex = index;">
                <ng-container *ngIf="(!activePurchase.hideCountedItems || !item.counted) && !item.childAccountId">

                    <div class="row" id="item-{{itemIndex}}" [class.counted]="item.counted" [class.hidden-non-variant]="activePurchase.showVariantsOnly && (item.adjustedQty == item.targetQty || item.variantsSum == item.targetQty)" [class.parent]="item.childAccountId" [class.active]="itemIndex == activePurchase.activeItemIndex" (click)="activateRow(itemIndex)">
                        <div class="col-sku">{{item.sku}}</div>
                        <div class="col-item">
                            {{item.name}}
                            <ng-container *ngIf="!item.variantLinks">{{item.unitDescription}}</ng-container>
                        </div>
                        <div class="col-expected">
                            <ng-container *ngIf="!item.childAccountId">{{item.targetQty | rf:'number'}}</ng-container>
                            <ng-container *ngIf="item.childAccountId">-</ng-container>
                        </div>

                        <div *ngIf="!item.variantLinks" class="col-actual" [class.increase]="item.adjustedQty > item.targetQty" [class.decrease]="item.adjustedQty < item.targetQty">
                            <ng-container>{{item.adjustedQty | rf:'number'}}</ng-container>
                        </div>

                        <div *ngIf="item.variantLinks" class="col-actual" [class.increase]="item.variantsSum > item.targetQty" [class.decrease]="item.variantsSum < item.targetQty">
                            <ng-container>{{item.variantsSum | rf:'number'}}</ng-container>
                        </div>

                        <div *ngIf="activePurchase.showVariantsOnly" class="col-variance">{{item.qty | rf:'number'}}</div>

                    </div>

                    <div *ngIf="itemIndex == activePurchase.activeItemIndex && item.counted && !item.saving && item.existingCount === undefined && !item.variantLinks" class="already-counted">
                        <div class="message">Item already counted.</div>
                        <div class="options">
                            <div class="red" (click)="activateRow(null)">Cancel</div>
                            <div class="yellow" (click)="startChangeCount()">Change Count</div>
                            <div class="green" (click)="startAddToCount()">Add to Count</div>
                        </div>
                    </div>

                    <div *ngIf="itemIndex === activePurchase.activeItemIndex && (!item.counted || item.existingCount !== undefined || item.variantLinks)" class="item-expanded">

                        <div class="item-detail">

                            <div *ngIf="item.variantLinks" class="variants">
                                <div *ngFor="let variant of item.variantLinks" class="variant-option" [class.counted]="activePurchase.items[variant.index].counted" [class.active]="variant.index == activePurchase.activeItem.index" (click)="activateRow(variant.index)">
                                    <div class="description">{{activePurchase.items[variant.index].unitOfMeasure}} {{activePurchase.items[variant.index].unitDescription}}</div>
                                    <div class="math">{{variant.childQty}} x {{activePurchase.items[variant.index].adjustedQty}} = {{activePurchase.items[variant.index].adjustedQty*variant.childQty | rf:'number'}}</div>
                                </div>
                            </div>

                            <div class="item-specs">
                                <label>Item Name</label>
                                <div class="name">{{activePurchase.activeItem.name}}</div>
                                <label>Unit Description</label>
                                <div>{{activePurchase.activeItem.unitDescription}}</div>
                                <label>Unit of Measure</label>
                                <div>{{activePurchase.activeItem.unitOfMeasure}}</div>
                                <label>Category</label>
                                <div>{{activePurchase.activeItem.categoryName}}</div>
                                <ng-container *ngIf="item.childAccountId">
                                    <label>Unit Contains</label>
                                    <div>{{aactivePurchase.ctiveItem.childQty}}</div>
                                </ng-container>
                                <!-- <label>Last Counted</label> -->
                                <!-- <div>07/03/2021 10:44 AM</div> -->
                            </div>
                        </div>
    
                        <div class="num-pad">
                            <div class="active-value">
                                <span *ngIf="activePurchase.activeItem.existingCount">{{activePurchase.activeItem.existingCount | rf:'number'}} + </span>
                                {{activePurchase.activeItem.activeValue | rf:'number'}}
                            </div>
                            <div class="count-label">COUNT</div>
                            <div *ngIf="!item.variantLinks" class="discrepancy" [class.increase]="activePurchase.activeItem.discrepancy > 0" [class.decrease]="activePurchase.activeItem.discrepancy < 0">
                                <span *ngIf="activePurchase.activeItem.discrepancy > 0">+</span>{{activePurchase.activeItem.discrepancy | rf:'number'}}
                            </div>
                            <div class="ripple-btn" (click)="countBtnPress(1)">1</div>
                            <div class="ripple-btn" (click)="countBtnPress(2)">2</div>
                            <div class="ripple-btn" (click)="countBtnPress(3)">3</div>
                            <div class="ripple-btn" (click)="countBtnPress(4)">4</div>
                            <div class="ripple-btn" (click)="countBtnPress(5)">5</div>
                            <div class="ripple-btn" (click)="countBtnPress(6)">6</div>
                            <div class="ripple-btn" (click)="countBtnPress(7)">7</div>
                            <div class="ripple-btn" (click)="countBtnPress(8)">8</div>
                            <div class="ripple-btn" (click)="countBtnPress(9)">9</div>
                            <div class="ripple-btn" (click)="countBtnPress('c')">C</div>
                            <div class="ripple-btn" (click)="countBtnPress(0)">0</div>
                            <div class="ripple-btn" (click)="countBtnPress('d')">&lt;</div>
                            <div class="reset ripple-btn" (click)="discardCount()">Discard Count</div>
                            <div class="done ripple-btn" (click)="saveCount()">Save Count</div>
                        </div>
    
                    </div>

                </ng-container>
            </ng-container>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
            <div class="virtual-spacer"></div>
        </cdk-virtual-scroll-viewport>
        

























        <div *ngIf="activePurchase.showVariantsOnly" class="inventory-table inventory-table-print" id="inventory-table">
            <ng-container *ngFor="let item of activePurchase.items; let itemIndex = index;">
                <ng-container *ngIf="(!activePurchase.hideCountedItems || !item.counted) && !item.childAccountId">

                    <div *ngIf="item.adjustedQty !== item.targetQty && item.variantsSum !== item.targetQty" class="row">
                        <div class="col-sku">{{item.sku}}</div>
                        <div class="col-item">{{item.name}} <ng-container *ngIf="!item.variantLinks">{{item.unitDescription}}</ng-container></div>
                        <div class="col-expected">
                            <ng-container>{{item.targetQty | rf:'number'}}</ng-container>
                        </div>

                        <div *ngIf="!item.variantLinks" class="col-actual" [class.increase]="item.adjustedQty > item.targetQty" [class.decrease]="item.adjustedQty < item.targetQty">
                            <ng-container>{{item.adjustedQty | rf:'number'}}</ng-container>
                        </div>

                        <div *ngIf="item.variantLinks" class="col-actual" [class.increase]="item.variantsSum > item.targetQty" [class.decrease]="item.variantsSum < item.targetQty">
                            <ng-container>{{item.variantsSum | rf:'number'}}</ng-container>
                        </div>

                        <div *ngIf="!item.variantLinks" class="col-variance">{{item.qty | rf:'number'}}</div>
                        <div *ngIf="item.variantLinks" class="col-variance">{{item.variantsSum - item.targetQty | rf:'number'}}</div>

                    </div>


                </ng-container>
            </ng-container>

        </div>

































        <div class="toolbar no-print">
            <!-- <div *ngIf="!activePurchase.showManualSearch" class="btn-toggle ripple-btn" (click)="toggleManualSearch()">Show Manual Search</div>
            <div *ngIf="activePurchase.showManualSearch" class="btn-toggle ripple-btn" (click)="toggleManualSearch()">Hide Manual Search</div> -->

            <div *ngIf="activePurchase.hideCountedItems" class="btn-toggle ripple-btn" (click)="toggleHideCountedItems()">Show Counted</div>
            <div *ngIf="!activePurchase.hideCountedItems" class="btn-toggle ripple-btn" (click)="toggleHideCountedItems()">Hide Counted</div>

            <!-- <div *ngIf="activePurchase.showVariantsOnly" class="btn-toggle pipple-btn" (click)="toggleVariants()">Show All</div>
            <div *ngIf="!activePurchase.showVariantsOnly" class="btn-toggle pipple-btn" (click)="toggleVariants()">Show Variants</div> -->

            <div class="btn-toggle ripple-btn" (click)="refresh()">Refresh</div>
            <div class="btn-toggle ripple-btn print-btn" (click)="print()">Print Variants</div>

            <!-- <div *ngIf="!activePurchase.allowAddNew" class="btn-toggle ripple-btn" (click)="toggleAllowAddNew()">Allow Add New</div> -->
            <!-- <div *ngIf="activePurchase.allowAddNew" class="btn-toggle ripple-btn" (click)="toggleAllowAddNew()">Do Not Allow Add New</div> -->

            <div class="spacer"></div>
            <div *ngIf="activePurchase.countComplete" class="btn-complete ripple-btn" (click)="submitCount()">Submit Count</div>
        </div>
    </div>
</ng-container>
