<div *ngIf="change" class="container">
    <div class="change-header">
        <div class="timestamp-user">
            {{(change.timestamp | rf : 'dateTimeDay') + ' by ' + change.user.firstName + ' ' + change.user.lastName}}
        </div>
        <button *ngIf="change.changes && change.changes.length !== 0" class="button" (click)="showComparison(change.oldValue, change.newValue)">
            {{"..."}}
        </button>
    </div>
    <div class="change-wrapper">
        <div *ngIf="!change.changes || change.changes.length === 0" class="raw-value">
            <button class="button" (click)="showComparison(change.oldValue, change.newValue)">
                Comparison
            </button>
        </div>
        <div *ngIf="change.changes && change.changes.length > 0" class="changes">
            <table mat-table [dataSource]="change.changes" class="mat-elevation-z8 change-list">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element"><div>{{changeType[element.type]}}</div></td>
                </ng-container>
                <ng-container matColumnDef="key">
                    <th mat-header-cell *matHeaderCellDef>Key</th>
                    <td mat-cell *matCellDef="let element">{{element.key}}</td>
                </ng-container>
                <ng-container matColumnDef="fromValue">
                    <th mat-header-cell *matHeaderCellDef>From</th>
                    <td mat-cell class="column-value" *matCellDef="let element">
                        <div *ngIf="!isArrayOrObject(element)">
                            {{formatValue(element.fromValue)}}
                        </div>
                        <div *ngIf="isArrayOrObject(element)" class="value-data">
                            <button class="button" (click)="showComparison(element.fromValue, element.toValue)"
                                    [matTooltip]="(element.removed ? jp.transform(element.removed) : '')">
                                {{element.removed ? 'Removed' : 'Comparison'}}
                            </button>
                            <div class="copy-icon" *ngIf="element.removed" class="copy-icon" (click)="copyToClipboard(element.removed)"></div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="toValue">
                    <th mat-header-cell *matHeaderCellDef>To</th>
                    <td mat-cell class="column-value" *matCellDef="let element">
                        <div *ngIf="!isArrayOrObject(element)">
                            {{formatValue(element.toValue)}}
                        </div>
                        <div *ngIf="isArrayOrObject(element)" class="value-data">
                            <button class="button" (click)="showComparison(element.fromValue, element.toValue)"
                                    [matTooltip]="(element.added ? jp.transform(element.added) : '')">
                                {{element.added ? 'Added' : 'Comparison'}}
                            </button>
                            <div class="copy-icon" *ngIf="element.added" class="copy-icon" (click)="copyToClipboard(element.added)"></div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row class="table-sticky-header" *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>