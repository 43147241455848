import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogButtonStyle, DialogConfig, DialogOption } from './modules/shared/ui/dialog/dialog-options';
import { DialogComponent } from './modules/shared/ui/dialog/dialog.component';

import { HistoryService } from './services/history/history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  static snackBar: MatSnackBar;
  static dialog: MatDialog;

  title = 'InfraRed 2.2.0';

  constructor( historyService: HistoryService,
               private sb: MatSnackBar,
               private dg: MatDialog ) {
    historyService.loadRouting();
    AppComponent.snackBar = sb;
    AppComponent.dialog = dg;
  }

  public static showSnack( message: string, actionLabel: string, durationMs: number = null ) {
    if ( durationMs ) {
      AppComponent.snackBar.open( message, actionLabel, { duration: durationMs } );
    } else {
      AppComponent.snackBar.open( message, actionLabel );
    }
  }

  public static showMessage( title: string, message: string, options: string [],
                             showClose: boolean = true, width: string = '500px', height: string = '300px', ) {
    const dialogOptions = new DialogConfig();
    dialogOptions.showClose = showClose;
    dialogOptions.title = title;
    dialogOptions.message = message;
    dialogOptions.width = width;
    dialogOptions.height = height;
    if ( !options ) {
      options = [ 'OK' ];
    }
    options.forEach( (o) => {
      let style = DialogButtonStyle.Normal;
      if ( Array.from( ['yes', 'confirm', 'save' ]).indexOf(o.toLowerCase()) !== -1 ) {
        style = DialogButtonStyle.Confirm;
      } else if ( Array.from( ['no', 'cancel', 'delete' ]).indexOf(o.toLowerCase()) !== -1) {
        style = DialogButtonStyle.Cancel;
      }
      dialogOptions.options.push( new DialogOption( o, o, style));
    });
    return this.showDialog(dialogOptions);
  }

  public static showDialog( dialogConfig: DialogConfig ): MatDialogRef<DialogComponent> {
    return AppComponent.dialog.open( DialogComponent, {
      height: dialogConfig.height,
      width: dialogConfig.width,
      data: dialogConfig,
    });
  }
}
