import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';

@Component({
  selector: 'app-detail-location',
  templateUrl: './detail-location.component.html',
  styleUrls: ['./detail-location.component.scss']
})
export class DetailLocationComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  location;
  creditLedger;
  saving;
  savingCredit;
  importingTransactions;

  closingBatch;

  addingApiKey;
  apiKey;

  importingFromRemote;

  smsCreditBalance;
  smsCreditAmount;
  smsCreditLedgerId;

  emailCreditBalance;
  emailCreditAmount;
  emailCreditLedgerId;

  timeZoneOptions = [
    {
      name: 'Eastern',
      value: 'America/New_York'
    },
    {
      name: 'Central',
      value: 'America/Chicago'
    },
    {
      name: 'Mountain',
      value: 'America/Denver'
    },
    {
      name: 'Pacific',
      value: 'America/Los_Angeles'
    }
  ];

  batchTimeOptions = [
    '',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '10:40 PM',
    '10:45 PM',
    '10:50 PM',
    '11:00 PM',
    '11:55 PM',
    '12:00 AM',
    '12:55 AM',
    '1:00 AM',
    '1:55 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM'
  ];

  states = [
    { 'label':'Alabama', 'value': 'AL' },
    { 'label':'Alaska', 'value': 'AK'},
    { 'label':'American Samoa', 'value': 'AS'},
    { 'label':'Arizona', 'value': 'AZ'},
    { 'label':'Arkansas', 'value': 'AR'},
    { 'label':'California', 'value': 'CA'},
    { 'label':'Colorado', 'value': 'CO'},
    { 'label':'Connecticut', 'value': 'CT'},
    { 'label':'Delaware', 'value': 'DE'},
    { 'label':'District of Columbia', 'value': 'DC'},
    { 'label':'States of Micronesia', 'value': 'FM'},
    { 'label':'Florida', 'value': 'FL'},
    { 'label':'Georgia', 'value': 'GA'},
    { 'label':'Guam', 'value': 'GU'},
    { 'label':'Hawaii', 'value': 'HI'},
    { 'label':'Idaho', 'value': 'ID'},
    { 'label':'Illinois', 'value': 'IL'},
    { 'label':'Indiana', 'value': 'IN'},
    { 'label':'Iowa', 'value': 'IA'},
    { 'label':'Kansas', 'value': 'KS'},
    { 'label':'Kentucky', 'value': 'KY'},
    { 'label':'Louisiana', 'value': 'LA'},
    { 'label':'Maine', 'value': 'ME'},
    { 'label':'Marshall Islands', 'value': 'MH'},
    { 'label':'Maryland', 'value': 'MD'},
    { 'label':'Massachusetts', 'value': 'MA'},
    { 'label':'Michigan', 'value': 'MI'},
    { 'label':'Minnesota', 'value': 'MN'},
    { 'label':'Mississippi', 'value': 'MS'},
    { 'label':'Missouri', 'value': 'MO'},
    { 'label':'Montana', 'value': 'MT'},
    { 'label':'Nebraska', 'value': 'NE'},
    { 'label':'Nevada', 'value': 'NV'},
    { 'label':'New Hampshire', 'value': 'NH'},
    { 'label':'New Jersey', 'value': 'NJ'},
    { 'label':'New Mexico', 'value': 'NM'},
    { 'label':'New York', 'value': 'NY'},
    { 'label':'North Carolina', 'value': 'NC'},
    { 'label':'North Dakota', 'value': 'ND'},
    { 'label':'Northern Mariana Islands', 'value': 'MP'},
    { 'label':'Ohio', 'value': 'OH'},
    { 'label':'Oklahoma', 'value': 'OK'},
    { 'label':'Oregan', 'value': 'OR'},
    { 'label':'Palau', 'value': 'PW'},
    { 'label':'Pennsilvania', 'value': 'PA'},
    { 'label':'Puerto Rico', 'value': 'PR'},
    { 'label':'Rhode Island', 'value': 'RI'},
    { 'label':'South Carolina', 'value': 'SC'},
    { 'label':'South Dakota', 'value': 'SD'},
    { 'label':'Tennessee', 'value': 'TN'},
    { 'label':'Texas', 'value': 'TX'},
    { 'label':'Utah', 'value': 'UT'},
    { 'label':'Vermont', 'value': 'VT'},
    { 'label':'Virgin Islands', 'value': 'VI'},
    { 'label':'Virginia', 'value': 'VA'},
    { 'label':'Washington', 'value': 'WA'},
    { 'label':'West Virginia', 'value': 'WV'},
    { 'label':'Wisconsin', 'value': 'WI'},
    { 'label':'Wyoming', 'value': 'WY'}
  ];

  groupDropdown: DropdownOptionGroup =
  {
    key: 'groupId',
    name: 'Default Item Group',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.One,
  };

  @ViewChild('import') importEl: ElementRef<HTMLElement>;
  @ViewChild('inventory') inventoryEl: ElementRef<HTMLElement>;

  constructor(
    private locationA: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService,
    private reportService: ReportService
  ) { }

  ngOnInit() {

    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadLocationDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationA.back();
  }

  loadLocationDetail() {
    return new Promise((resolve, reject) => {
      this.location = null;
      this.creditLedger = null;
      this.smsCreditBalance = null
      this.emailCreditBalance = null;


      this.addingApiKey = null;
      this.apiKey = null;


      this.ioService.post('/location/getLocation', {
        locationId: this.documentId
      }).then((locationResponse: any) => {
        console.log('locationResponse: ');
        console.log(locationResponse);


        if (!locationResponse.address) {
          locationResponse.address = {};
        }
        this.location = locationResponse;
        this.loadGroupDetail();
        // this.smsCreditBalance = locationResponse.smsCreditBalance;
        // this.smsCreditLedgerId = locationResponse.smsCreditLedgerId;
        //
        // this.emailCreditBalance = locationResponse.emailCreditBalance;
        // this.emailCreditLedgerId = locationResponse.emailCreditLedgerId;
        //
        // if (locationResponse.creditLedger) {
        //   if (!locationResponse.creditLedger.batchEmailAddresses) {
        //     locationResponse.creditLedger.batchEmailAddresses = [];
        //   }
        //
        //   if (!locationResponse.creditLedger.receiptEmailAddresses) {
        //     locationResponse.creditLedger.receiptEmailAddresses = [];
        //   }
        //
        //   this.creditLedger = locationResponse.creditLedger
        // }
        //
        // this.citygroConfig = {
        //   importing: false,
        //   pointMethod: 'remainder'
        // };
        //
        // console.log('citygro: ', this.citygroConfig);

      });
    });
  }

  saveChanges() {
    console.log('saveChanges: ', this.location);
    this.saving = true;
    this.location.custom = null;
    this.location.defaultGroupId = this.groupDropdown.selection[0]?._id;
    this.ioService.post('/location/updateLocation', {
      location: this.location
    }).then((saveResponse: any) => {
      console.log('saved.');
      this.saving = false;
      this.reportService.loadReport(0);
      this.close();
    });
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  addBatchEmailAddress() {
    this.creditLedger.batchEmailAddresses.push('');
  }

  addReceiptEmailAddress() {
    this.creditLedger.receiptEmailAddresses.push('');
  }

  deleteBatchEmail(index) {
    this.creditLedger.batchEmailAddresses.splice(index, 1);
  }

  deleteReceiptEmail(index) {
    this.creditLedger.receiptEmailAddresses.splice(index, 1);
  }

  saveCreditChanges() {
    console.log('saveCreditChanges: ', this.creditLedger);
    this.savingCredit = true;
    this.ioService.post('/location/saveCreditChanges', {
      locationId: this.location._id,
      batchEmailAddresses: this.creditLedger.batchEmailAddresses,
      receiptEmailAddresses: this.creditLedger.receiptEmailAddresses,
      customerReceipt: this.creditLedger.customerReceipt,
      processor: this.creditLedger.processor
    }).then((saveResponse: any) => {
      console.log('saved cc config.');
      this.savingCredit = false;
    });
  }

  importTransactions() {
    let el: HTMLElement = this.importEl.nativeElement;
    el.click();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ioService.upload('/import/transactions', {
        locationId: this.documentId
      }, file).subscribe(event => {
        console.log(event);
      });
    }
  }

  createApiUserKey() {
    this.addingApiKey = true;
    this.ioService.post('/location/createApiUserKey', {
      locationId: this.location._id
    }).then((createResponse: any) => {
      console.log('createResponse: ', createResponse);
      this.apiKey = createResponse.apiKey;
      // this.addingApiKey = false;
    });
  }

  remoteExport() {
    if (!this.importingFromRemote) {
      this.importingFromRemote = true;
      this.ioService.post('/boardandbrush/remoteExport', {
        locationId: this.location._id,
        minId: 0,
        limit: 5000
      }).then((result: any) => {
        this.importingFromRemote = false;

      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  closeBatch() {
    if (!this.closingBatch) {
      this.closingBatch = true;
      this.ioService.post('/batch/closeBatch', {
        locationId: this.location._id
      }).then((result: any) => {
        this.closingBatch = false;

      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  subtractSmsCredits() {
    console.log('subtractSmsCredits: ', this.smsCreditAmount);
    this.ioService.post('/transaction/resource/subtract', {
      locationId: this.location._id,
      ledgerId: this.smsCreditLedgerId,
      amount: this.smsCreditAmount/100
    }).then((subtractResponse: any) => {
      console.log('subtractResponse: ', subtractResponse);
      this.loadLocationDetail();
    });
  }

  addSmsCredits() {
    console.log('addSmsCredits: ', this.smsCreditAmount);
    this.ioService.post('/transaction/resource/add', {
      locationId: this.location._id,
      ledgerId: this.smsCreditLedgerId,
      amount: this.smsCreditAmount/100
    }).then((addResponse: any) => {
      console.log('addResponse: ', addResponse);
      this.loadLocationDetail();
    });
  }

  subtractEmailCredits() {
    console.log('subtractEmailCredits: ', this.emailCreditAmount);
    this.ioService.post('/transaction/resource/subtract', {
      locationId: this.location._id,
      ledgerId: this.emailCreditLedgerId,
      amount: this.emailCreditAmount/100
    }).then((subtractResponse: any) => {
      console.log('subtractResponse: ', subtractResponse);
      this.loadLocationDetail();
    });
  }

  addEmailCredits() {
    console.log('addEmailCredits: ', this.emailCreditAmount);
    this.ioService.post('/transaction/resource/add', {
      locationId: this.location._id,
      ledgerId: this.emailCreditLedgerId,
      amount: this.emailCreditAmount/100
    }).then((addResponse: any) => {
      console.log('addResponse: ', addResponse);
      this.loadLocationDetail();
    });
  }


  importInventory() {
    let el: HTMLElement = this.inventoryEl.nativeElement;
    el.click();
  }

  onInventoryFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ioService.upload('inventory/import', {
        locationId: this.documentId
      }, file).subscribe(event => {
        console.log(event);

      });
    }
  }

  addAllItemsToLocation() {
    console.log('addAllItemsToLocation:');
    this.ioService.post('/inventory/addAllItemsToLocation', {
      locationId: this.location._id
    });
  }

  loadGroupDetail() {
    this.searchGroups( false, '' )
    .then( () => {
      if ( !this.groupDropdown.options || this.groupDropdown.options.length === 0 ) {
        return;
      }
      if ( !this.location || !this.location.defaultGroupId ) {
        return;
      }
      const selectedGroup = this.groupDropdown.options.find((g) => g._id === this.location.defaultGroupId);
      if ( selectedGroup ) {
        this.groupDropdown.selection = [selectedGroup];
      }
    })
    .finally( () => {
      this.groupDropdown.options = new Array<DropdownOption>(); // ugh...
    });
  }

  async searchGroups( omit = true, searchText: string) {
    if ( !this.location ) {
      return;
    }
    const groupSearchResults = await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      locationId: this.location._id,
      search: searchText,
      limit: 10,
      omit: omit ? [ this.groupDropdown.selection[0]?._id ] : undefined
    }) as Array<any>;
    this.groupDropdown.options = groupSearchResults.map( (r) => Object.assign( new DropdownOption( r._id, r.name ), r));
  }
}
