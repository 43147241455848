import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import * as moment from 'moment';
import { DatePickerConfig, DateRangeSettings } from './date-config-interface';

@Component({
  selector: 'app-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.scss']
})
export class FilterDateRangeComponent implements OnInit {
  @Output() rangeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() rangeConfig: DateRangeSettings;
  @Input() opens = 'right';
  @Input() skewed = false;

  constructor( ) { }

  ngOnInit() {
    if ( !this.rangeConfig ) {
      this.rangeConfig = new DateRangeSettings();
    }
    this.setDateConfig(this.rangeConfig);
  }

  datesUpdated(range) {
    if (range && (range.startDate || range.endDate)) {
      this.rangeChanged.emit( range );
    }
  }

  setDateConfig(dateConfigInput: DateRangeSettings) {
    if ( !this.rangeConfig ) {
      this.rangeConfig = new DateRangeSettings();
    }

    if (dateConfigInput) {
      if (dateConfigInput.dateConfig.singleDate) {
        this.rangeConfig.dateRange.endDate = this.rangeConfig.dateRange.startDate;
      }
      for (const configKey of Object.keys(this.rangeConfig.dateConfig)) {
        if (dateConfigInput.dateConfig[configKey] !== undefined) {
          this.rangeConfig.dateConfig[configKey] = dateConfigInput.dateConfig[configKey];
        }
      }
    }
  }
}
