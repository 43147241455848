<div class="detail" [class.visible]="permission">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Permission</h2>
  </header>
  <section>
    <div *ngIf="!permission" class="loading">Loading . . .</div>
    <div *ngIf="permission">

      <div class="input-set input-set-general">
        <div>
          <label>Permission Name</label>
          <input type="text" [(ngModel)]="permission.name" />
        </div>
        <div>
          <label>Description</label>
          <textarea class="note-input" [(ngModel)]="permission.description"></textarea>
        </div>
        <div>
          <label>Permission Key</label>
          <input type="text" class="percentage" [(ngModel)]="permission.key" />
        </div>
        <div>
          <label>Location Specific</label>
          <select [(ngModel)]="permission.locationSpecific">
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
          </select>
        </div>
      </div>
      <!-- <div>
        <label>Groups</label>
        <angular2-multiselect [data]="groups.available" [(ngModel)]="groups.selected"
          [settings]="groups.settings"
          (onAddFilterNewItem)="groups.add($event)"></angular2-multiselect>
      </div> -->
      <div class="buttons">
        <button class="button green" (click)="grantToAdmins()">Grant Access to Admins</button>
        <button class="button-full green" (click)="savePermission()">Save Permission</button>
      </div>

    </div>
  </section>
</div>
