import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-detail-dynamic',
  templateUrl: './detail-dynamic.component.html',
  styleUrls: ['./detail-dynamic.component.scss']
})
export class DetailDynamicComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    dynamic;

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private ioService: IoService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadDynamicDetail();
        }
      });
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    loadDynamicDetail() {
      console.log('loadDynamicDetail: ');

    }

}
