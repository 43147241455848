import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IoService } from 'src/app/services/io/io.service';
import { Discount } from '../../shared/interfaces/discounts/discount-account';

@Component({
  selector: 'app-detail-discount',
  templateUrl: './detail-discount.component.html',
  styleUrls: ['./detail-discount.component.scss']
})
export class DetailDiscountComponent implements OnInit {

  documentId$: Observable<any>;
  documentId: string;
  discountType: string = null;
  discount: Discount = null;

  constructor( private route: ActivatedRoute,
               private ioService: IoService ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.retrieveAccount();
      }
    });
  }

  setDiscount( newDiscount: Discount ) {
    this.discount = newDiscount;
    this.discountType = this.discount.subtype;
  }

  async retrieveAccount() {
    this.discount = null;
    this.discountType = null;
    const accountRecord = await this.ioService.post('/discount/getDiscountAccount', {
      accountId: this.documentId,
      detail: true
    });
    this.discount = Object.assign( new Discount(), accountRecord );
    this.discountType = this.discount.subtype;
  }
}
