import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';
import { ReportService } from '../../../services/report/report.service'
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/ui/dialog/dialog.component';
import { DialogButtonStyle, DialogConfig, DialogOption } from '../../shared/ui/dialog/dialog-options';
import { RfPipe } from 'src/app/pipes/rf/rf.pipe';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.scss']
})
export class DetailUserComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;
    showBackButton;
    resetRecentlySent = false;
    activeUserIsDeveloper;

    user;

    private permissionsLoaded: Subscription;
    private detailDocumentSet: Subscription;

    constructor(
      private locationR: Location,
      private route: ActivatedRoute,
      private router: Router,
      private historyService: HistoryService,
      private authorizationService: AuthorizationService,
      private locationService: LocationService,
      private ioService: IoService,
      private reportService: ReportService,
      private dialog: MatDialog,
      private rf: RfPipe,
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.init();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    back() {
      this.locationR.back();
    }

    init = async () => {
      console.log('detail-user init: ');

      this.user = null;

      // Get General User Data
      this.user = await this.ioService.post('/user/getUser', {
        userId: this.documentId
      });

      this.user.activeLocation = null;
      if ( this.user.passwordResetPending ) {
        const sentDate = new Date(this.user.passwordResetPending);
        this.resetRecentlySent = (new Date().getTime() - sentDate.getTime() < 60000);
      } else {
        this.resetRecentlySent = false;
      }
      // See if we can manage this user
      const authenticatedUser = this.authorizationService.getUser();
      this.user.managableLocations = [];

      for (const location of await this.locationService.getLocationsByType('retail')) {
        if (this.authorizationService.checkPermission('manageUsers', location._id)) {
          this.user.managableLocations.push(location);
          this.user.canManage = true;
        }
      }

      // Sort managable locations
      this.user.managableLocations.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.active > b.active) ? 1 : -1) : -1 );

      if (this.user.managableLocations.length > 0) {
        this.user.managableLocations.unshift({
          _id: (await this.locationService.getLocationsByType('retail')).map(location => {
            return location._id;
          }),
          name: "All Locations",
        });
      }

      this.user.managableLocations.unshift({
        _id: null,
        name: "General",
      });

      // Get other possible authorizations
      this.activeUserIsDeveloper = this.authorizationService.checkPermission('Developer', 'general');
      console.log('activeUserIsDeveloper: ', this.activeUserIsDeveloper);


      console.log(this.user);
    }

    async saveChanges() {
      if (!this.user.saving) {
        this.user.saving = true;
        let duplicateUserField: string;
        if ( this.user.active ) {
          duplicateUserField = await this.checkForExistingUser();
        }
        let promptPromise = new Promise<boolean>((resolve, reject) => resolve(true));

        if ( duplicateUserField || !this.user.active ) {
          promptPromise = new Promise<boolean>((resolve, reject) => {
            const title = this.user.active ? 'User Already Exists' : 'Disable User';
            const message = this.user.active ? `A user already exists with this ${duplicateUserField}.`
                                             : 'Are you sure you want to disable this user?';
            const options = this.user.active ? null : ['Yes', 'Cancel'];
            const dialogRef = AppComponent.showMessage( title, message, options, false );
            dialogRef.afterClosed().subscribe( (result: DialogOption) => {
              if ( result.value === 'Yes' ) {
                resolve( true );
              } else {
                if (!this.user.active) {
                  this.user.active = true;
                }
                reject( false );
              }
            });
          });
        }
        promptPromise.then( (result) => {
          this.ioService.post('/user/updateUser', {
            user: this.user,
            locationId: this.locationService.getActiveLocationId()
          }).then(async (saveResponse: any) =>  {
            if ( !this.user.active ) {
              this.router.navigate(['/reports/users']);
              this.reportService.loadReport(null);
              AppComponent.showSnack('User Disabled.', '', 2000);
            } else {
              await this.init();
              this.user.updated = saveResponse.msg;
              this.reportService.loadReport(null);
              AppComponent.showSnack('Save Complete.', '', 2000);
            }
          }).catch((error: any) => {
            this.user.error = error.error.msg;
          });
        })
        .catch( (canceled) => {
          console.log( 'User update canceled.');
        })
        .finally(() => { this.user.saving = false; });
      }
    }

    async checkForExistingUser(): Promise<string> {
      return new Promise<string>(async (resolve, reject) => {
        await this.ioService.post('/user/findMatchingUsers', {
          user: this.user,
        })
        .then((res: any) => {
          resolve(res.duplicateField);
        })
        .catch((err) => {
          console.error('Failed to find matching users', err);
          resolve(null);
        });
      });
    }

    disableUser() {
      this.user.active = false;
      this.saveChanges();
    }

    locationSelected = async () => {
      console.log('location selected!');

      // Get all Location based Permissions (not authorizations)
      const allPermissions: any = await this.ioService.post('/authorization/getPermissions');
      console.log('allPermissions: ', allPermissions);

      let permissionGroups: any = [];

      if (this.user.activeLocation._id) {
        permissionGroups = await this.ioService.post('/group/getPermissionGroups', {
          locationId: this.user.activeLocation._id
        });
      }

      console.log('permissionGroups: ', permissionGroups);
      this.user.permissionGroups = permissionGroups;

      const targetUserAuthorizations: any = await this.ioService.post('/authorization/getOtherUserAuthorizaitons', {
        userId: this.user._id,
        locationId: this.user.activeLocation._id
      });

      this.user.potentialPermissions = [];

      for (const permission of allPermissions) {
        if (this.authorizationService.checkPermission(permission.key, this.user.activeLocation._id, false)) {
          if (targetUserAuthorizations.indexOf(permission.key) !== -1) {
            permission.active = true;
          }
          this.user.potentialPermissions.push(permission);
        }
      }

      // Get all reports
      const reportCategories = this.reportService.getReportCategories();
      console.log('reportCategories: ', reportCategories);
      for (const category of reportCategories) {
        for (const categoryReport of category.options) {
          const permission = {
            name: 'Report: '+category.name+': '+categoryReport.name,
            key: 'report.'+categoryReport._id,
            active: null
          };

          if (this.authorizationService.checkPermission(permission.key, this.user.activeLocation._id, false)) {
            if (targetUserAuthorizations.indexOf(permission.key) != -1) {
              permission.active = true;
            }

            this.user.potentialPermissions.push(permission);
          }
        }
      }

      console.log('targetUserAuthorizations: ', targetUserAuthorizations);

      // Get all permissions the authenticated user has on this location
      // this.user.potentialPermissions = this.authorizationService.getLocationAuthorizations(this.user.activeLocation._id);

      // Get all permissions the target user on this location

      console.log(this.user);
    }

    sendPasswordReset = async () => {
      const passwordResetResponse = await this.ioService.post('/authentication/requestPasswordReset', {
        userKey: this.user.email
      });

      this.user.passwordResetResponse = true;
      this.user.passwordResetPending = new Date();
    }

    sendPin = async () => {
      const pinResponse = await this.ioService.post('/authentication/setPin', {
        userId: this.user._id
      });

      this.user.pinResponse = pinResponse;
    }

    togglePermission = async (permission) => {
      console.log('togglePermission: ', permission);
      permission.updating = true;
      let action = 'grant';
      if (permission.active) {
        action = 'revoke';
      }

      await this.ioService.post('/authorization/'+action, {
        userId: this.documentId,
        key: permission.key,
        locationId: this.user.activeLocation._id
      });

      permission.updating = false;
      permission.active = !permission.active;
    }

    grantPermissionGroup = async (permissionGroup) => {
      console.log('grantPermissionGroup: ', permissionGroup);
      if (!permissionGroup.updating) {
        permissionGroup.updating = true;

        const grantGroupResponse: any = await this.ioService.post('/authorization/grantPermissionGroup', {
          userId: this.documentId,
          groupId: permissionGroup._id,
          locationId: this.user.activeLocation._id
        });

        permissionGroup.updating = false;

        await this.locationSelected();
      }
    }

    setApiKey = async () => {
      const apiKeyResponse: any = await this.ioService.post('/authentication/setApiKey', {
        userId: this.documentId,
        name: this.user.firstName+' '+this.user.lastName
      });

      alert(apiKeyResponse.apiKey);
    }

}
