import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { IoService } from '../io/io.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private user;
  private authorizations;

  private authenticationStateChanged: Subscription;

  private permissionsLoaded = new Subject<any>();

  constructor(
    private authenticationService: AuthenticationService,
    private ioService: IoService
  ) {
    console.log('Authorization Service constructor()');
    this.authenticationStateChanged = authenticationService.authenticationStateChangedObservable().subscribe(authenticatedId => {
      if (authenticatedId) {
        this.loadPermissions();
      } else {
        this.authorizations = null;
        this.permissionsLoaded.next(false);
      }
    });

    if (authenticationService.isSignedIn()) {
      this.loadPermissions();
    }
  }


  loadPermissions() {
    this.ioService.post('/authorization/getActiveUserPermissions').then((permissionsResponse: any) => {
      this.user = permissionsResponse.user;

      let any = [];
      for (let locationId in permissionsResponse.authorizations) {
        if (locationId != 'general') {
          for (let permission of permissionsResponse.authorizations[locationId]) {
            if (any.indexOf(permission) == -1) {
              any.push(permission);
            }
          }
        }
      }
      permissionsResponse.authorizations.any = any;
      this.authorizations = permissionsResponse.authorizations;

      this.permissionsLoaded.next(true);
    });
  }

  permissionsAreLoaded() {
    if (this.authorizations) {
      return true;
    } else {
      return false;
    }
  }

  permissionsLoadedObservable(): Observable<any> {
    return this.permissionsLoaded.asObservable();
  }

  checkPermission(key, locationId:any = 'any', allowDeveloperOverride = true) {

    if (locationId === null) {
      locationId = 'general';
    }

    if (!this.authorizations) {
      return false;
    }

    if (!Array.isArray(locationId)) {
      locationId = [locationId];
    }

    for (let locId of locationId) {
      if (this.authorizations[locId] && this.authorizations[locId].indexOf(key) != -1) {
        return true;
      }
    }

    if (allowDeveloperOverride && this.authorizations.general.indexOf('Developer') != -1) {
      return true;
    }

    return false;
  }

  getLocationAuthorizations(locationId) {
    return this.authorizations[locationId];
  }

  getUser() {
    return this.user;
  }

}
