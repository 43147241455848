<div class="container">
    <div class="field-container">        
        <div>{{item?.name}}</div>
    </div>
    <div *ngIf="!basicView" class="field-container">
        <div class="text-center">{{item?.retailPrice | rf: 'currency'}}</div>
    </div>
    <div class="field-container">
        <div>
            <div>{{item.saleType === SaleType.SalePrice ? '$' : ' '}}</div>
            <input type="number" [value]="item?.saleValue" [(ngModel)]="item.saleValue" (ngModelChange)="calculateSalePrice()" />
            <div>{{item.saleType === SaleType.PercentOff ? '%' : ' '}}</div>
        </div>
    </div>
    <div *ngIf="!basicView" class="field-container">
        <div class="text-center">{{itemSalePrice | rf: 'currency'}}</div>
    </div>
    <div *ngIf="!basicView" class="field-container">
        <div class="text-center">{{item.cost && itemSalePrice ? (((itemSalePrice - (item.cost)) / itemSalePrice)*100).toFixed(2) + '%' : '-'}}</div>
    </div>
</div>