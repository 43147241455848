<div class="detail" [class.visible]="user">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <ng-container *ngIf="user">
      <h2 *ngIf="user.name">{{user.name}}</h2>
      <h2 *ngIf="!user.name">New Customer</h2>
    </ng-container>
  </header>
  <section>
    <div *ngIf="user" class="user">

      <div *ngIf="user.type == 'customer'" class="user-form">
        <div class="field">
          <label>First Name</label>
          <input type="text" [(ngModel)]="user.firstName" />
        </div>
        <div class="field">
          <label>Last Name</label>
          <input type="text" [(ngModel)]="user.lastName" />
        </div>
        <div class="field">
          <label>Email</label>
          <input type="email" id="email" [(ngModel)]="user.email" />
        </div>
        <div class="field">
          <label>Phone</label>
          <input type="tel" id="telephone" [(ngModel)]="user.telephone" mask="0 (000) 000-0000" />
        </div>
        <div class="line-field">
          <div class="label-large">Tax Exempt</div>
          <app-toggle (valueChanged)="toggleBoolean('taxExempt')" [checked]="user.taxExempt"></app-toggle>
        </div>
        <div class="error">{{user.error}}</div>
        <div class="buttons">
          <div *ngIf="!user.saving" class="button green" (click)="saveChanges()">Save</div>
        </div>
      </div>

      <div *ngIf="user.type == 'user'" class="user-summary">
        <div *ngIf="user.firstName">
          <label>First Name</label>
          <div class="value">{{user.firstName}}</div>
        </div>
        <div *ngIf="user.lastName">
          <label>Last Name</label>
          <div class="value">{{user.lastName}}</div>
        </div>
        <div *ngIf="user.email">
          <label>Email</label>
          <div class="value">{{user.email}}</div>
        </div>
        <div *ngIf="user.telephone">
          <label>Phone</label>
          <div class="value">{{user.telephone | rf:'phone'}}</div>
        </div>
      </div>

      <div>
        <div *ngFor="let purchase of user.purchases" class="purchase-link" (click)="loadDetail('purchase', purchase._id)">
          <div class="location">{{purchase.locationName}}</div>
          <div class="total-date">
            <div class="total">{{purchase.total | rf:'currency'}}</div>
            <div class="date">{{purchase.timeUpdated | rf:'dateTime'}}</div>
          </div>
        </div>
      </div>


      <!-- <pre style="padding-top: 200px;">{{user | json}}</pre> -->

    </div>
  </section>
</div>
