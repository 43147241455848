<div class="detail" [class.visible]="report" [class.shoved]="report && conversationActive">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Report</h2>
  </header>
  <section>
    <div *ngIf="!report" class="loading">Loading . . .</div>
    <div *ngIf="report">
      <div class="context">
        <div class="row">
          <div class="label">Report ID:</div>
          <div class="value">{{report._id}}</div>
        </div>
        <div class="row">
          <div class="label">Name:</div>
          <div class="value">{{report.name}}</div>
        </div>
        <div class="row">
          <div class="label">Category:</div>
          <div class="value">{{report.category}}</div>
        </div>
      </div>

      <div class="controls">
        <button class="button yellow" (click)="updateFromRemote()">Update from Remote</button>
        <button class="button green" (click)="grantToAdmins()">Grant Access to Admins</button>

        <input type="text" [(ngModel)]="importKey" placeholder="remote key" />
        <button class="button yellow" (click)="importFromRemote(importKey)">Import New Report</button>
      </div>

    </div>
  </section>
</div>
