import { DropdownOption } from '../option-interfaces';

export class DiscountFilter {
    key: string;
    operator: string;
    value: any;
    selectedValue: any;

    public static DefaultKeys(): Array<DropdownOption> {
        const ret: Array<DropdownOption> = [
            {
                _id: 'accountId',
                name: 'Inventory Item',
            },
            {
                _id: 'categoryId',
                name: 'Category',
            },
            {
                _id: 'groupId',
                name: 'Group',
            },
            {
                _id: 'userId',
                name: 'User',
            },
            {
                _id: 'customerId',
                name: 'Customer',
            }
        ];

        return ret;
    }

    public static DefaultOperators(): Array<DropdownOption> {
        const ret: Array<DropdownOption> = [
            {
                _id: 'in',
                name: 'Includes',
            },
            {
                _id: 'nIn',
                name: 'Does Not Include',
            }
        ];

        return ret;
    }
}
