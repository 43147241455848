import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { LocationService } from '../../../services/location/location.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { ColorPickerService } from 'ngx-color-picker';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';

@Component({
  selector: 'app-detail-group',
  templateUrl: './detail-group.component.html',
  styleUrls: ['./detail-group.component.scss']
})
export class DetailGroupComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  group;
  saving;
  errors = [];

  locationDropdown: DropdownOptionGroup =
  {
    key: 'locationId',
    name: 'Available at Locations',
    options: new Array<DropdownOption>(),
    selection: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.Multi,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private historyService: HistoryService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    private reportService: ReportService,
    private colorPickerService: ColorPickerService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadGroupDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadGroupDetail() {
    this.group = null;

    this.ioService.post('/group/getGroup', {
      groupId: this.documentId
    }).then((group: any) => {
      console.log('groupResponse: ', group);

      switch (group.type) {
        case 'inventory':
          group.label = 'Inventory Group';
          break;

        case 'tax':
          group.label = 'Tax';
          group.percentage = this.toPercentage(group.percentage);
          break;

        default:
          group.label = 'Group';
      }

      group.background = 'rgba(0, 0, 0, 0)';
      if (group.color) {
        group.background = 'rgba(' + group.color.r + ',' + group.color.g + ',' + group.color.b + ',0.5)';
      }

      if ( !group.locationId ) {
        group.locationId = new Array<any>();
      }

      this.group = group;
      this.locationDropdown.selection = new Array<DropdownOption>();
      this.searchLocations('');
    });
  }

  async searchLocations( searchText: string ) {
    const availableLocations = await this.locationService.getLocationsByType('retail');
    this.locationDropdown.options = new Array<DropdownOption>();
    for (const location of availableLocations) {
      if (location._id) {
        if ( !this.authorizationService.checkPermission('group.' + this.group.type + '.manage', location._id) ) {
          continue;
        }
        const newOption = new DropdownOption( location._id, location.name );
        if (this.group.locationId && this.group.locationId.indexOf( location._id ) !== -1 ) {
          this.locationDropdown.selection.push(newOption);
        } else if ( null == this.group.locationId && !this.locationDropdown.selection.some( l => l._id === location._id ) ) {
          this.locationDropdown.options.push( newOption );
        }
      }
    }
    this.group.locationId = null;
  }

  saveGroup() {
    if (!this.saving) {
      console.log('save group');
      this.saving = true;

      if (this.group.percentage) {
        this.group.percentage = this.toDecimal(this.group.percentage);
      }

      const updateGroup = JSON.parse(JSON.stringify(this.group));

      // Update Locations field
      updateGroup.locationId = [];
      if ( this.locationDropdown.selection && this.locationDropdown.selection.length > 0 ) {
        updateGroup.locationId = this.locationDropdown.selection.map( l => l._id );
      }

      this.ioService.post('/group/updateGroup', {
        group: updateGroup
      }).then((updateResponse: any) => {
        console.log('updateResponse: ', updateResponse);
        this.saving = false;
        this.reportService.loadReport(0);
        this.close();
      });
    }
  }

  toDecimal(inputValue) {
    return Number.parseFloat((Number.parseFloat(inputValue) / 100).toPrecision(4));
  }

  toPercentage(inputValue) {
    if (!inputValue) {
      inputValue = 0;
    }
    return (inputValue * 100).toFixed(4);
  }

  colorChanged( newColor: string, group: any ) {
    const hsva = this.colorPickerService.stringToHsva(newColor, true);
    group.color = this.colorPickerService.denormalizeRGBA(this.colorPickerService.hsvaToRgba( hsva ));
  }
}
