<nav [class.open]="open">
  <div class="nav-inner">

    <ul *ngIf="reportCategories" class="nav-ul">
      <ng-container *ngFor="let category of reportCategories">
        <li *ngIf="category.name != 'Hidden'" [class.active]="category.active" (click)="toggleCategory(category)">
          <a>{{category.name}}</a>
          <ul *ngIf="category.options">
            <li *ngFor="let report of category.options">
              <a [routerLink]="['/reports', report.key]" (click)="setActiveReport()">{{report.name}}</a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>

  </div>
</nav>
<div class="nav-toggle" [class.open]="open" (click)="toggleMenu()"></div>
