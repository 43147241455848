import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { ReportService } from '../../../services/report/report.service';
import { HistoryService } from '../../../services/history/history.service';
import { MessageService } from '../../../services/message/message.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-detail-report',
  templateUrl: './detail-report.component.html',
  styleUrls: ['./detail-report.component.scss']
})
export class DetailReportComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    showBackButton;

    permissionsLoaded: Subscription;

    conversationActive;
    conversationActiveUpdated: Subscription;

    report;

    importKey;

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private historyService: HistoryService,
      private ioService: IoService,
      private authorizationService: AuthorizationService,
      private reportService: ReportService,
      private messageService: MessageService,
      private locationService: LocationService,
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadReportDetail();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }

      this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
        this.conversationActive = conversationActive;
      });
      this.conversationActive = this.messageService.getConversationActive();
    }

    ngOnDestroy() {
      if (this.permissionsLoaded) {
        this.permissionsLoaded.unsubscribe();
      }

      if (this.conversationActiveUpdated) {
        this.conversationActiveUpdated.unsubscribe();
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    loadReportDetail = async () => {
      console.log('loadReportDetail: ');
      this.report = null;

      this.report = await this.ioService.post('/report/getReport', {
        reportId: this.documentId
      });
    }

    grantToAdmins = async () => {
      console.log('grantToAdmins: ');

      await this.ioService.post('/authorization/grantToAdmins', {
        key: 'report.'+this.report._id,
        locationId: this.locationService.getActiveLocationId()
      });
    }

    updateFromRemote = async () => {
      console.log('updateFromRemote: ');

      let updateResult = await this.ioService.post('/report/updateFromRemote', {
        reportId: this.report._id
      });

      console.log('updateResult: ', updateResult);
    }

    importFromRemote = async (reportKey) => {
      console.log('importFromRemote: ');

      let importResult = await this.ioService.post('/report/importFromRemote', {
        key: reportKey
      });
    }

}
