<div class="settings-body" *ngIf="location">
  <div class="close" (click)="close()"></div>
  <h2>Birthday Message Settings</h2>

  <!-- <div *ngIf="false" class="settings-panel">
    <div *ngIf="!location.marketing.birthday.active" class="toggle off" (click)="toggleAutoresponder(true);"></div>
    <div *ngIf="location.marketing.birthday.active" class="toggle on" (click)="toggleAutoresponder(false);"></div>
    <label>Birthday</label>
  </div>
  <div *ngIf="location.marketing.birthday.active">
    <label>Birthday Message</label>
    <textarea [(ngModel)]="location.marketing.birthday.content" (keyup)="contentChanged()"></textarea>
    <div class="sub">{{charLimit - location.marketing.birthday.content.length}} Characters Remaining</div>
  </div>
  <div>
    <label>Birthday Message Send Time</label>
    <select [(ngModel)]="location.marketing.birthday.sendTime">
      <option *ngFor="let timeOption of sendTimes" value="{{timeOption}}">{{timeOption}}</option>
    </select>
  </div>

  <div class="buttons">
    <div class="button green" [class.grey]="saving" (click)="save()">Save</div>
  </div> -->

  <h3>Birthday messages have been temporarily disabled.</h3>

</div>
