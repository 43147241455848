import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DropdownOption, DropdownOptionGroup, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';

@Component({
  selector: 'app-detail-terminal',
  templateUrl: './detail-terminal.component.html',
  styleUrls: ['./detail-terminal.component.scss']
})
export class DetailTerminalComponent implements OnInit {
  documentId$: Observable<any>;
  documentId;

  showBackButton;

  terminal;

  conversationActive;
  conversationActiveUpdated: Subscription;

  creditTerminals;

  drawerCount;

  batchTimeOptions;

  groupDropdown: DropdownOptionGroup = {
    key: 'defaultGroupId',
    name: 'Default Item Group',
    selection: new Array<DropdownOption>(),
    options: new Array<DropdownOption>(),
    opposingIdKey: null,
    selectMode: DropdownSelectionMode.One,
  };

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private reportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadTerminalDetail();
      }
    });
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.location.back();
  }


  loadTerminalDetail = async () => {
    console.log('loadTerminalDetail: ', this.documentId);
    this.terminal = null;
    this.terminal = await this.ioService.post('/terminal/getTerminal', {
      terminalId: this.documentId
    });

    if (this.terminal.type === 'ipad') {
      this.loadCreditTerminals();
      this.loadDrawerCount();
      this.loadGroupDetail();
    }

    if (this.terminal.type === 'credit') {
      this.batchTimeOptions = [];

      const targetTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0));
      // let targetTime = new Date('01-01-1970');

      for (let i = 0; i < 288; i++) {
        const newTargetTime = new Date(targetTime.getTime() + i * 5 * 60 * 1000);
        this.batchTimeOptions.push(newTargetTime);
      }

      const beginning = this.batchTimeOptions.splice(0, 216);
      this.batchTimeOptions = this.batchTimeOptions.concat(beginning);
      this.terminal.batchTime = new Date(this.terminal.batchTime);
    }
  }

  loadCreditTerminals = async () => {
    this.creditTerminals = null;
    this.creditTerminals = await this.ioService.post('/terminal/getCreditTerminals', {
      terminalId: this.documentId
    });
  }

  loadDrawerCount = async () => {
    this.drawerCount = null;
    this.drawerCount = await this.ioService.post('/transaction/cash/check', {
      locationId: this.terminal.locationId,
      terminalId: this.terminal._id
    });
  }

  saveTerminal = async () => {
    this.terminal.defaultGroupId = this.groupDropdown.selection[0]?._id;
    await this.ioService.post('/terminal/updateTerminal', {
      terminal: this.terminal
    });

    this.reportService.loadReport(0);
    this.close();
  }

  deleteTerminal = async () => {
    await this.ioService.post('/terminal/deleteTerminal', {
      terminalId: this.terminal._id
    });

    this.reportService.loadReport(null);
    this.close();
  }

  closeBatch = async () => {
    this.terminal.batchClosing = true;
    
    const batchResult = await this.ioService.post('/batch/closeBatch', {
      terminalId: this.documentId
    });

    this.terminal.batchResult = batchResult;
  }

  validateBatch = async () => {
    this.terminal.validating = true;
    this.terminal.problemTransactions = null;
    const validationResult: any = await this.ioService.post('/batch/validateBatch', {
      terminalId: this.documentId
    });

    this.terminal.problemTransactions = validationResult.problemTransactions;

    this.terminal.validating = false;
  }

  toggleBoolean(toggleTarget) {
    this.terminal[toggleTarget] = !this.terminal[toggleTarget];
  }

  loadDetail(type, id) {
    this.router.navigate(['../../' + type + '/' + id], { relativeTo: this.route });
  }

  loadGroupDetail() {
    this.searchGroups( false, '' )
    .then( () => {
      this.groupDropdown.selection = new Array<DropdownOption>();
      if ( !this.groupDropdown.options || this.groupDropdown.options.length === 0 ) {
        return;
      }
      if ( !this.terminal || !this.terminal.defaultGroupId ) {
        return;
      }
      const defaultGroup = this.groupDropdown.options.find( (g) => g._id === this.terminal.defaultGroupId );
      if ( defaultGroup ) {
        this.groupDropdown.selection.push(defaultGroup);
      }
    })
    .finally( () => {
      this.groupDropdown.options = null; // ugh...
    });
  }

  async searchGroups(omit = true, searchText: string) {
    if ( !this.terminal ) {
      return;
    }
    const groupSearchResults = await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      locationId: this.terminal.locationId,
      search: searchText,
      limit: omit ? 10 : undefined,
      omit: omit ? this.groupDropdown.selection.map((s) => s._id) : undefined
    }) as Array<any>;
    this.groupDropdown.options = groupSearchResults.map((r) => Object.assign( new DropdownOption(r._id, r.name), r));
  }
}
