import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { AppComponent } from '../../app.component';
import { IoService } from '../../services/io/io.service';
import { LoadingComponent } from '../shared/loading/loading.component';

@Injectable()
export class GlobalErrorInterceptor implements ErrorHandler {
    public static lastErrorTime: Date;
    public static lastError: string;

    // How often should errors be reported?
    public static readonly REPORT_INTERVAL = 5000;

    constructor( private zone: NgZone,
                 private ioService: IoService) { }

    handleError(thisError: any): void {
        try {
            const embeddedMessage = this.extractHttpErrorResponse(thisError.message);
            if ( thisError.message ) {
                thisError = thisError.message;
                if ( thisError.stack ) {
                    thisError += '\nStack: ' + thisError.stack;
                }
            } else {
                try {
                    thisError = JSON.stringify( thisError );
                } catch (err ) {
                    thisError = thisError as string;
                }
            }

            const errorIsDifferent = (GlobalErrorInterceptor.lastError !== thisError);

            // If error message is different than the last one, let it through -- otherwise, the REPORT_INTERVAL time period must pass.
            if ( GlobalErrorInterceptor.lastErrorTime ) {
                if (new Date().getTime() - GlobalErrorInterceptor.lastErrorTime.getTime() < GlobalErrorInterceptor.REPORT_INTERVAL
                    && !errorIsDifferent ) {
                        return;
                }
            }

            this.zone.run( () => {
                // Present the error to the user
                let msg = '';
                if ( embeddedMessage ) {
                    msg = embeddedMessage;
                } else {
                    msg = 'An error occurred processing this request.';
                }
                AppComponent.showSnack( msg, 'OK', embeddedMessage ? 20000 : 3000 );
                this.ioService.logError( thisError );
            });
        } catch ( err ) {
            console.error( 'Global Error Exception', err );
            this.ioService.logError( err );
        } finally {
            GlobalErrorInterceptor.lastErrorTime = new Date();
            GlobalErrorInterceptor.lastError = thisError;
            LoadingComponent.hideLoading();
        }
        console.error( 'Global Error: ', GlobalErrorInterceptor.lastError );
    }

    extractHttpErrorResponse( message: string ): string {
        const i = message.indexOf( 'HttpErrorResponse:', 0 );
        if ( i > 0 ) {
            let response: any = message.substring( i + 19 );
            try {
                response = JSON.parse( response );
                return response.error?.msg;
            } catch ( err ) {}
        }
    }
}
