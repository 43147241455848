import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {

  @ViewChild('messages') messagesElement: ElementRef;
  @ViewChild('message') messageElement: ElementRef;

  conversationSelected;
  conversations;
  conversation;
  messageBody;
  location;

  messagesUpdated: Subscription;

  constructor(
    public dialogRef: MatDialogRef<MessagingComponent>,
    private ioService: IoService,
    private locationService: LocationService,
    // private messageService: MessageService
  ) { }

  ngOnInit() {
    this.location = this.locationService.getActiveLocation();
    // this.messageService.monitorSummary(false);
    // this.messageService.loadConversations().then(conversations => {
    //   this.conversations = conversations;
    //   if (this.conversations.length > 0) {
    //     this.loadConversation(this.conversations[0].customer._id);
    //   }

    //   // Subscribe to new messages
    //   this.messagesUpdated = this.messageService.messagesUpdatedObservable().subscribe(() => {
    //     this.conversation = this.messageService.getConversation();
    //     this.conversations = this.messageService.getConversations();
    //     this.scrollToBottom();
    //   });

    //   this.messageService.monitorMessages(true);
    // });
  }

  ngOnDestroy() {
    this.messagesUpdated.unsubscribe();
  }

  close() {
    this.dialogRef.close();
    // this.messageService.monitorMessages(false);
    // this.messageService.monitorSummary(true);
  }

  selectConversation(customerId) {
    this.conversationSelected = customerId;
    this.loadConversation(customerId);
  }

  closeConversation() {
    this.conversationSelected = false;
  }

  loadConversation(customerId) {
    this.conversation = null;
    // this.messageService.loadConversation(customerId).then(conversation => {
    //   this.conversation = conversation;
    //   this.scrollToBottom();
    //   if (this.location._id) {
    //     this.focusOnMessage();
    //   }
    // });
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesElement.nativeElement.scrollTop = this.messagesElement.nativeElement.scrollHeight;
    }, 0);
  }

  focusOnMessage() {
    setTimeout(() => {
      this.messageElement.nativeElement.focus();
    }, 0);
  }

  messageKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log(event);
      this.send();
    }
  }

  send() {
    if (this.messageBody && this.messageBody != '') {
      // this.messageService.sendMessage({
      //   customerId: this.conversation.customer._id,
      //   locationId: this.locationService.getActiveLocation()._id,
      //   body: this.messageBody
      // });

      this.messageBody = null;
      this.focusOnMessage();
    }
  }

}
