import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IoService } from '../io/io.service';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  summaryMonitoring;
  summaryTimeout;
  summaryInterval = 30000;  // 30 seconds
  summaryUpdated = new Subject<any>();
  summary;

  messagesMonitoring;
  messagesTimeout;
  messagesInterval = 3000; // 3 seconds
  messagesUpdated = new Subject<any>();

  conversations;
  conversation;
  lastMessageId;

  conversationActiveUpdated = new Subject<any>();

  lastCheck = new Date().getTime();

  constructor(
    private ioService: IoService,
    private locationService: LocationService
  ) {
    // Commented because this service is not currently used by Quartz.
    // setInterval(this.sleepCheck, 1000);
    // this.conversations = [];
  }

  sleepCheck() {
    let now = new Date().getTime();
    let diff = now - this.lastCheck;
    if (diff > 4000) {
      // alert('sleepCheck: ' + diff/1000 + ' seconds');

      // If a 1 second interval took longer than 4 seconds, restart the checks that should be running

      if (this.summaryMonitoring) {
        console.log('restart summary monitoring');
        this.monitorSummary(true);
      }

      if (this.messagesMonitoring) {
        console.log('restart messages monotoring');
        this.monitorMessages(true);
      }

    } else {
      // console.log('sleepcheck: '+diff/1000);
      // this.sleepCheckTime = diff/1000;
    }
    this.lastCheck = now;
  }


  // Ping server at interval to get updated messaging summary
  monitorSummary(doMonitor) {
    this.summaryMonitoring = doMonitor;
    if (doMonitor) {
      if (this.summaryTimeout) {
        clearTimeout(this.summaryTimeout);
      }
      this.loadSummary();
    } else {
      if (this.summaryTimeout) {
        clearTimeout(this.summaryTimeout);
      }
    }
  }

  // Get total of messages needing attention
  loadSummary() {
    let activeLocation = this.locationService.getActiveLocation();
    if (activeLocation) {
      this.ioService.post('/communication/getSummary', {
        locationId: activeLocation._id
      }).then((summary: any) => {
        this.summary = summary;
        this.summaryUpdated.next(this.summary);
        this.summaryTimeout = setTimeout(() => {
          this.loadSummary();
        }, this.summaryInterval);
      });
    } else {

      if (this.summaryMonitoring) {
        this.summaryTimeout = setTimeout(() => {
          this.loadSummary();
        }, this.summaryInterval);
      }

    }
  }

  getSummary() {
    return this.summary;
  }

  summaryUpdatedObservable(): Observable<any> {
    return this.summaryUpdated.asObservable();
  }

  // Ping server at interval to get new messages
  monitorMessages(doMonitor) {
    this.messagesMonitoring = doMonitor;
    if (doMonitor) {
      if (this.messagesTimeout) {
        clearTimeout(this.messagesTimeout);
      }
      this.loadMessages();
    } else {
      if (this.messagesTimeout) {
        clearTimeout(this.messagesTimeout);
      }
    }
  }


  // Get all messages since the last get
  loadMessages() {
    let request = {
      lastMessageId: this.lastMessageId,
      locationId: this.locationService.getActiveLocation()._id,
      activeConversationId: null
    };

    if (this.conversation) {
      request.activeConversationId = this.conversation.customer._id
    }

    this.ioService.post('/communication/getMessages', request).then((newMessages: any) => {

      // If there are any new messages
      if (newMessages.messages.length > 0) {
        // Process each new message
        for (let message of newMessages.messages) {
          message.locationName = this.locationService.getLocation(message.locationId).name;

          // Update the conversation
          for (let conversation of this.conversations) {
            if (conversation._id == message.customerId) {
              conversation.lastBody = message.body;
              conversation.lastTime = message.time;
              conversation.lastId = message._id;
              conversation.lastRead = message.read;
              conversation.lastDirection = message.direction;
              break;
            }
          }

          // Update the active conversation with all messages
          if (this.conversation && message.customerId == this.conversation.customer._id) {
            this.conversation.messages.push(message);
          }
        }

        // Update the most recent message we have
        this.lastMessageId = newMessages.messages[newMessages.messages.length-1]._id;
console.log('got new messages');
        // Notify subscribers that messages have been updated
        if (this.conversation) {
          this.messagesUpdated.next(this.conversation.messages);
        } else {

          this.messagesUpdated.next([]);

        }
      }

      if (this.messagesMonitoring) {
        this.messagesTimeout = setTimeout(() => {
          this.loadMessages();
        }, this.messagesInterval);
      }
    });
  }

  messagesUpdatedObservable(): Observable<any> {
    return this.messagesUpdated.asObservable();
  }

  conversationActiveUpdatedObservable(): Observable<any> {
    return this.conversationActiveUpdated.asObservable();
  }

  // Get most recent message for each customer
  loadConversations() {
    return new Promise((resolve, reject) => {
      let request = {
        locationId: null
      };

      if (this.locationService.getActiveLocation()) {
        request.locationId = this.locationService.getActiveLocation()._id;
      }

      this.ioService.post('/communication/getConversations', request).then((conversationsResponse: any) => {
        for (let conversation of conversationsResponse.conversations) {
          conversation.lastLocationName = this.locationService.getLocation(conversation.lastLocationId).name;
        }
        this.conversations = conversationsResponse.conversations;
        this.lastMessageId = this.conversations[0].lastId;
        resolve(this.conversations);
      });
    });
  }

  getConversations() {
    return this.conversations;
  }

  // Get all messages from a given customer
  loadConversation(customerId) {
    this.closeConversation();

    return new Promise((resolve, reject) => {
      this.ioService.post('/communication/getConversation', {
        customerId,
        // locationId: this.locationService.getActiveLocation()._id
      }).then((conversation: any) => {
        for (let message of conversation.messages) {
          message.locationName = this.locationService.getLocation(message.locationId).name;
        }
        this.conversation = conversation;
        if (!this.lastMessageId && this.conversation.messages.length > 0) {
          this.lastMessageId = this.conversation.messages[this.conversation.messages.length-1]._id;
        }
        // this.lastMessageId = this.conversation.lastId;
        this.conversationActiveUpdated.next(this.conversation.customer._id);
        this.monitorMessages(true);
        resolve(this.conversation);
      });
    });
  }

  closeConversation() {
    this.monitorMessages(false);
    this.conversation = null;
    this.conversationActiveUpdated.next(false);
  }

  getConversationActive() {
    if (this.conversation && this.conversation.customer && this.conversation.customer._id) {
      return this.conversation.customer._id;
    } else {
      return false;
    }
    // return (this.conversation != null);
  }

  getConversation() {
    return this.conversation;
  }

  // Send a message
  sendMessage(message) {
    this.monitorMessages(false);
    return new Promise((resolve, reject) => {
      this.ioService.post('/communication/sendMessage', {
        locationId: message.locationId,
        customerId: message.customerId,
        body: message.body
      }).then((messageStatus: any) => {
        message.status = messageStatus;
        this.monitorMessages(true);
      });
    });
  }



}
