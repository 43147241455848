<div class="detail" [class.visible]="form">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Month End</h2>
  </header>
  <section>
    <div *ngIf="!form" class="loading">Loading . . .</div>
    <div *ngIf="form">


      <div class="static">
        <label>Studio:</label>
        <div>{{form.locationName}}</div>
      </div>
      <div class="static">
        <label>Month:</label>
        <div>{{form.name}}</div>
      </div>

      <div class="static">
        <label>Date/Time Start:</label>
        <div>{{form.timeFilterStart | rf: 'dateTime'}}</div>
      </div>

      <div class="static">
        <label>Date/Time End:</label>
        <div>{{form.timeFilterEnd | rf: 'dateTime'}}</div>
      </div>

      <div class="form-history">
        <label>Version History:</label>
        <div *ngFor="let version of form.history; let versionIndex = index;" class="version" [class.active]="activeVersionIndex == versionIndex" (click)="showVersion(versionIndex)">
          <div>{{version.timeUpdated | rf: 'dateTime'}}</div>
          <div>{{version.status | rf: 'monthEndStatus'}} by {{version.userName}}</div>
        </div>
        <div class="version" [class.active]="activeVersionIndex == versionIndex" (click)="showVersion()">
          <div>{{form.timeUpdated | rf: 'dateTime'}}</div>
          <div>{{form.status | rf: 'monthEndStatus'}} by {{form.userName}}</div>
        </div>
      </div>

      <div *ngIf="activeVersionIndex == null">
        <div class="grouped">
          <div class="grouped-header">
            <div>GROSS SALES</div>
            <div>TOTAL</div>
          </div>
          <div *ngIf="form.ise" class="grouped-record" [class.modified]="form.history.length > 0 && form.ise != form.history[0].ise">
            <div>ISE<br/>(Royalty Eligible Total)</div>
            <div class="calculated">{{form.ise | rf:'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.square != form.history[0].square">
            <div>Square</div>
            <div *ngIf="!editable" class="calculated">{{form.square | rf: 'currency'}}</div>
            <div *ngIf="editable">
              <input type="text" [(ngModel)]="form.inputs.square" (keyup)="updateCalc()" />
            </div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.paypal != form.history[0].paypal">
            <div>PayPal</div>
            <div *ngIf="!editable" class="calculated">{{form.paypal | rf: 'currency'}}</div>
            <div *ngIf="editable">
              <input type="text" [(ngModel)]="form.inputs.paypal" (keyup)="updateCalc()" />
            </div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.credit != form.history[0].credit">
            <div>Credit Cards</div>
            <div *ngIf="!editable" class="calculated">{{form.credit | rf: 'currency'}}</div>
            <div *ngIf="editable">
              <input type="text" [(ngModel)]="form.inputs.credit" mask="comma_separator" (keyup)="updateCalc()" />
            </div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.cash != form.history[0].cash">
            <div>Cash</div>
            <div *ngIf="!editable" class="calculated">{{form.cash | rf: 'currency'}}</div>
            <div *ngIf="editable">
              <input type="text" [(ngModel)]="form.inputs.cash" (keyup)="updateCalc()" />
            </div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.gift != form.history[0].gift">
            <div>Gift Card Sales</div>
            <div class="calculated">{{form.gift | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.onlineSignNoShip != form.history[0].onlineSignNoShip">
            <div>Signs Sold Online $</div>
            <div class="calculated">{{form.onlineSignNoShip | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total">
            <div>TOTAL:</div>
            <div>{{form.salesTotal | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grouped">
          <div class="grouped-header">
            <div>ONLINE SHOP SALES</div>
            <div>TOTAL</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.gift != form.history[0].gift">
            <div>Gift Card Sales</div>
            <div *ngIf="!editable" class="calculated">{{form.gift | rf: 'currency'}}</div>
            <div *ngIf="editable">
              <input type="text" [(ngModel)]="form.inputs.gift" (keyup)="updateCalc()" />
            </div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.online != form.history[0].online">
            <div># Signs Sold Online</div>
            <div *ngIf="!editable" class="calculated">{{form.onlineCount | rf: 'number'}}</div>
            <div *ngIf="editable">
              <input type="text" [(ngModel)]="form.inputs.onlineCount" (keyup)="updateCalc()" />
            </div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.online != form.history[0].online">
            <div>Signs Sold Online + Shipping</div>
            <div class="calculated">{{form.online | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total">
            <div>TOTAL:</div>
            <div>{{form.onlineTotal | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grouped">
          <div class="grouped-header">
            <div>ROYALTY SUMMARY</div>
            <div></div>
          </div>
          <div class="grouped-record">
            <div>Grand Total All Sales</div>
            <div class="calculated">{{form.salesTotal | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record">
            <div>Royalty ({{form.royaltyRate | rf: 'percent'}}% of Total Sales)</div>
            <div class="calculated">{{form.royalty | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.gift != form.history[0].gift">
            <div>Deduct Gift Cards Accepted</div>
            <div class="calculated">{{form.gift | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && form.online != form.history[0].online">
            <div>Deduct Online Sign Sales</div>
            <div class="calculated">{{form.online | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total yellow">
            <div>TOTAL ROYALTY</div>
            <div>{{form.royaltyTotal | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grouped">
          <div class="grouped-header">
            <div>BRAND FUND SUMMARY</div>
            <div></div>
          </div>
          <div class="grouped-record">
            <div>Grand Total All Sales</div>
            <div class="calculated">{{form.salesTotal | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record">
            <div>Brand Fund<br/>({{form.brandFundRate | rf: 'percent'}}% of Total Sales)</div>
            <div class="calculated">{{form.brandFund | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total yellow">
            <div>TOTAL BRAND FUND</div>
            <div>{{form.brandFund | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grand-total">
          <div>GRAND TOTAL DUE</div>
          <div>{{form.grandTotal | rf: 'currency'}}</div>
        </div>

        <div class="input-set input-set-general">
          <button *ngIf="(form.status == 'available' || form.status == 'rejected') && form.canSubmit" class="button green submit" (click)="submitMonthEnd()">Submit</button>
          <button *ngIf="(form.status == 'available' || form.status == 'rejected') && form.canSubmit" class="button green" (click)="saveMonthEnd()">Save</button>

          <button *ngIf="form.status == 'submitted' && form.canApprove" class="button green accept" (click)="acceptMonthEnd()">Accept</button>
          <button *ngIf="form.status == 'submitted' && form.canApprove" class="button red" (click)="rejectMonthEnd()">Reject with Changes</button>
        </div>

      </div>






      <div *ngIf="activeVersionIndex != null">
        <div class="grouped">
          <div class="grouped-header">
            <div>GROSS SALES</div>
            <div>TOTAL</div>
          </div>
          <div *ngIf="form.history[0].ise" class="grouped-record" [class.modified]="form.history.length > 0 && form.ise != form.history[0].ise">
            <div>ISE<br/>(Royalty Eligible Total)</div>
            <div class="calculated">{{form.ise | rf:'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.square != form.history[0].square">
            <div>Square</div>
            <div class="calculated">{{activeVersion.square | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.paypal != form.history[0].paypal">
            <div>PayPal</div>
            <div class="calculated">{{activeVersion.paypal | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.credit != form.history[0].credit">
            <div>Credit Cards</div>
            <div class="calculated">{{activeVersion.credit | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.cash != form.history[0].cash">
            <div>Cash</div>
            <div class="calculated">{{activeVersion.cash | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.gift != form.history[0].gift">
            <div>Gift Card Sales</div>
            <div class="calculated">{{activeVersion.gift | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.onlineSignNoShip != form.history[0].onlineSignNoShip">
            <div>Signs Sold Online $</div>
            <div class="calculated">{{activeVersion.onlineSignNoShip | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total">
            <div>TOTAL:</div>
            <div>{{activeVersion.salesTotal | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grouped">
          <div class="grouped-header">
            <div>ONLINE SHOP SALES</div>
            <div>TOTAL</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.gift != form.history[0].gift">
            <div>Gift Card Sales</div>
            <div class="calculated">{{activeVersion.gift | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.onlineCount != form.history[0].onlineCount">
            <div># Signs Sold Online</div>
            <div class="calculated">{{activeVersion.onlineCount | rf: 'number'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.online != form.history[0].online">
            <div>Signs Sold Online + Shipping</div>
            <div class="calculated">{{activeVersion.online | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total">
            <div>TOTAL:</div>
            <div>{{activeVersion.onlineTotal | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grouped">
          <div class="grouped-header">
            <div>ROYALTY SUMMARY</div>
            <div></div>
          </div>
          <div class="grouped-record">
            <div>Grand Total All Sales</div>
            <div class="calculated">{{activeVersion.salesTotal | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record">
            <div>Royalty (6% of Total Sales)</div>
            <div class="calculated">{{activeVersion.royalty | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.gift != form.history[0].gift">
            <div>Deduct Gift Cards Accepted</div>
            <div class="calculated">{{activeVersion.gift | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record" [class.modified]="form.history.length > 0 && activeVersion.online != form.history[0].online">
            <div>Deduct Online Sign Sales</div>
            <div class="calculated">{{activeVersion.online | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total yellow">
            <div>TOTAL ROYALTY</div>
            <div>{{activeVersion.royaltyTotal | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grouped">
          <div class="grouped-header">
            <div>BRAND FUND SUMMARY</div>
            <div></div>
          </div>
          <div class="grouped-record">
            <div>Grand Total All Sales</div>
            <div class="calculated">{{activeVersion.salesTotal | rf: 'currency'}}</div>
          </div>
          <div class="grouped-record">
            <div>Brand Fund<br/>(1% of Total Sales)</div>
            <div class="calculated">{{activeVersion.brandFund | rf: 'currency'}}</div>
          </div>
          <div class="grouped-total yellow">
            <div>TOTAL BRAND FUND</div>
            <div>{{activeVersion.brandFund | rf: 'currency'}}</div>
          </div>
        </div>

        <div class="grand-total">
          <div>GRAND TOTAL DUE</div>
          <div>{{activeVersion.grandTotal | rf: 'currency'}}</div>
        </div>

      </div>

      <div class="notes">
        <label>NOTES:</label>
        <div *ngIf="form.notes.length == 0" class="none">- no notes -</div>
        <div *ngFor="let note of form.notes" class="note">
          <div class="note-text">{{note.text}}</div>
          <div class="note-user-time">{{note.userName}} at {{note.time | rf: 'dateTime'}}</div>
        </div>
      </div>
      <div *ngIf="form.canSubmit || form.canApprove">
        <label>NEW NOTE:</label>
        <textarea class="note-input" [(ngModel)]="noteText"></textarea>
        <button *ngIf="!savingNote" class="button green save-note" (click)="saveNote()">Save Note</button>
      </div>






    </div>
  </section>
</div>
