import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {

  conversationActive;
  conversationActiveUpdated: Subscription;

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });

    this.conversationActive = this.messageService.getConversationActive();
  }

  ngOnDestroy() {
    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
  }

}
