<nav [class.open]="open">
  <div class="nav-inner">

    <ul *ngIf="location && location._id" class="nav-ul">
      <li *ngFor="let category of menu" [class.active]="category.active" (click)="toggleCategory(category)">
        <a>{{category.name}}</a>
        <ul *ngIf="category.options">
          <li *ngFor="let option of category.options">
            <a [routerLink]="[category.alias, option.alias]" (click)="setActiveOption()">{{option.name}}</a>
          </li>
        </ul>
      </li>
    </ul>

    <div *ngIf="!location || !location._id" class="warning">Please select a location to manage communication settings.</div>

  </div>
  <div class="nav-toggle" (click)="toggleMenu()"></div>
</nav>
